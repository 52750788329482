import { RootState } from '@/store/RootState'
import { Module } from 'vuex'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import { GetAdonBanner } from '@/api/displayAPI/DisplayAPI'

const getters: GetterTreeAdaptor<BannerGetters, BannerState, RootState> = {
  getFlipBanner(state: BannerState) {
    return state.adonBanner.flipBannerItem
  },
  getBigBanner(state: BannerState) {
    return state.adonBanner.bigBannerItem
  },
  getStripBanner(state: BannerState) {
    return state.adonBanner.stripBannerItem
  },
  getAdvertisingBanner(state: BannerState) {
    return state.adonBanner.advertisingBannerItem
  },
  getCategoryStripBanner(state: BannerState) {
    return state.adonBanner.categoryStripBannerItem
  },
  getMdpickAdBottomBanner(state: BannerState) {
    return state.adonBanner.mdpickAdBottomBannerItem
  },
  getSaleSectionAdTopBanner(state: BannerState) {
    return state.adonBanner.saleSectionAdTopBannerItem
  },
}

const actions: ActionTreeAdaptor<BannerAction, BannerState, RootState> = {
  async fetchAdonBanner({ commit }, payload) {
    if (payload.data) return commit('setAdonBanner', payload.data)
    if (payload.types && !payload.types.length)
      return commit('setAdonBanner', { flipBannerItem: bannerItem })

    try {
      let params = new URLSearchParams()
      for (let type of payload.types?.values() ?? []) {
        params.append('bannerType', String(type))
      }

      const { data } = await GetAdonBanner(params)
      commit('setAdonBanner', data)
    } catch (e) {
      commit('setAdonBanner', { flipBannerItem: bannerItem })
    }
  },
  async fetchOnBanner({ commit }, { onBanner }) {
    commit('setBanner', onBanner)
  },
}

const bannerItem: AdonBannerList = { rollingSecTime: 0, bannerList: [] }

export const BannerStore: Module<BannerState, RootState> = {
  namespaced: true,
  state: {
    adonBanner: {
      flipBannerItem: bannerItem,
      bigBannerItem: bannerItem,
      advertisingBannerItem: bannerItem,
      stripBannerItem: bannerItem,
      categoryStripBannerItem: bannerItem,
      mdpickAdBottomBannerItem: bannerItem,
      saleSectionAdTopBannerItem: bannerItem,
    },
    onBanner: true,
    flipBanner: true, // 해당 데이터 어디서 변경하지도 않는데, 사용은 하네? 추가적으로 확인 필요
    banner: {},
  },
  getters,
  mutations: {
    setBanner(state: BannerState, payload: boolean) {
      if (state.onBanner !== payload) {
        state.onBanner = payload
      }
    },
    setAdonBanner(state: BannerState, payload: AdonBannerGroup) {
      state.adonBanner = { ...payload }
    },
  },
  actions,
}

export interface BannerGetters {
  getBigBanner: AdonBannerList
  getStripBanner: AdonBannerList
  getAdvertisingBanner: AdonBannerList
  getFlipBanner: AdonBannerList
  getCategoryStripBanner: AdonBannerList
  getMdpickAdBottomBanner: AdonBannerList
  getSaleSectionAdTopBanner: AdonBannerList
}

export interface BannerState {
  onBanner: boolean
  banner: any
  flipBanner: boolean
  adonBanner: AdonBannerGroup
}

export type BannerAction = {
  fetchOnBanner: (payload: { onBanner: boolean }) => Promise<void>
  fetchAdonBanner(payload: {
    data?: AdonBannerGroup
    types?: Array<number>
  }): Promise<void>
}
