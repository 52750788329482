
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import FindAccount from '../login/FindAccount.vue'
import { setAccessToken } from '@/plugins/axios'
import _ from 'lodash'
import { tokenValidationPage } from '@/helper/index'

const TIME_OUT = 150 // 30s

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'LoginDialog',
  components: { FindAccount },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // login 중복 클릭 방지 flag
      flag: false,
      socialLoginPlattform: null,
      timer: undefined,
      count: 0,
    }
  },
  computed: {
    ...mapState('DeviceStore', ['deviceInfo']),
    ...mapState('UserStore', ['user']),
    backgroundImage() {
      const imgArray: string[] | undefined =
        this.$store.state.ProfileStore.profile?.urls?.loginBackgroundImageList

      if (!imgArray || !imgArray.length) {
        return '../../../assets/images/loginBackgroundImage.svg'
      }

      const randomImgIndex = Math.floor(Math.random() * imgArray.length)
      return imgArray[randomImgIndex]
    },
  },
  async created() {
    // history 추가 후 뒤로가기 evnet control
    history.pushState(null, 'null', location.href)

    window.addEventListener('popstate', this.handlePopstate, false)

    if (this.$device.isIOS && this.deviceInfo !== null) {
      this.socialLoginPlattform = localStorage.getItem('socialLoginPlattform')
    } else {
      this.socialLoginPlattform = null
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.documentElement.classList.add('overflow-y-hidden')
    })
    // AOS 에게 모달 띠움 알림
    if (this.$device.isAndroid && this.deviceInfo !== null) {
      window.CellookBridge.closeModal(true)
    }
  },
  updated() {
    this.$nextTick(() => {
      document.documentElement.classList.add('overflow-y-hidden')
    })
  },
  beforeDestroy() {
    // 종료되기 전에 AOS 에게 모달 닫힌 상태를 알림
    if (this.$device.isAndroid && this.deviceInfo !== null) {
      window.CellookBridge.closeModal(false)
    }
    window.removeEventListener('popstate', this.handlePopstate, false)
  },
  methods: {
    ...mapActions('HomeStore', ['fetchInit']),
    ...mapActions('UserStore', ['fetchUserProfile']),
    ...mapActions('MyPageStore', ['asyncGetMyPage']),
    ...mapActions('DibsStore', ['asyncGetDibsSubscribe']),
    ...mapActions('DialogStore', ['fetchTokenAlert']),
    onClickNaverLoginBtn() {
      if (this.flag) return
      try {
        this.flag = true

        // this.$naver.click()
        const url = `${process.env.VUE_APP_CELLOOK_URL}/loginActionPage`
        if (this.deviceInfo) {
          const payload = { url, isGoneBottomView: true, title: '로그인' }
          this.$openSubWebView(payload)
        } else {
          localStorage.setItem('formalRoot', 'true')
          window.open(url, 'naver login', 'width=600, height=800')
        }
        this.loginCheckTimer()
      } finally {
        this.flag = false
      }
    },
    loginCheckTimer() {
      if (this.timer) this.clearTimer()

      this.timer = setInterval(() => {
        this.count++
        this.tokenSetting()
        if (this.count > TIME_OUT) {
          return this.clearTimer()
        }
      }, 200)
    },
    clearTimer() {
      clearInterval(this.timer)
      this.timer = undefined
    },
    async tokenSetting() {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        setAccessToken(String(accessToken))
        this.$setTokenSubWebView(accessToken)
        this.fetchUserProfile()
        this.fetchInit()
        this.clearTimer()
        this.closeLoginDialog(true)
      }
    },
    async onClickAppleLoginBtn() {
      if (this.flag) return
      this.flag = true
      try {
        await this.$apple.init()
        await this.$apple.signIn()
        this.loginCheckTimer()
      } catch (e) {
        alert(e)
      } finally {
        this.flag = false
      }
    },
    handlePopstate() {
      this.closeLoginDialog()
    },
    closeLoginDialog(loginYn?: boolean) {
      // 로그인 진행중에 dialog 닫기 방지
      if (this.flag) return
      const redirectPath = localStorage.getItem('redirectPath')
      const isRegister = localStorage.getItem('isRegister')

      this.redirectSubWebView()
      this.removeHistory()
      this.$emit('closeDialog')

      if (loginYn) {
        this.fetchTokenAlert(false)
        if (isRegister) {
          return this.$router.push('/marketing-agree')
        }
        if (redirectPath) return this.$router.push(redirectPath)
        if (this.user.userType === 1003) return this.$router.go(-2)
        return this.$router.back()
      }

      // 아래 코드 개선 방안 고려
      if (!loginYn) {
        tokenValidationPage(this.$route.path)
      }
    },
    redirectSubWebView() {
      // 하이브리드앱으로부터 전달받은 값이 있다면, 서브 웹뷰 오픈
      const receivedValue = JSON.parse(
        localStorage.getItem('receivedValue') ?? 'null'
      )
      if (receivedValue == null) {
        return
      }

      // removeItem
      localStorage.removeItem('receivedValue')
      const payload = {
        url: receivedValue.src,
        title: receivedValue.title,
        shopId: receivedValue.shopId,
        productNo: receivedValue.productNo,
      }
      this.$openSubWebView(payload)
    },
    removeHistory() {
      localStorage.removeItem('isRegister')
      localStorage.removeItem('redirectPath')
    },
  },
})

interface Data {
  flag: boolean
  socialLoginPlattform: string | null
  timer: number | undefined
  count: number
}
interface Methods {
  //Store
  fetchInit(): Promise<void>
  fetchUserProfile(): Promise<void>
  asyncGetMyPage(): Promise<void>
  asyncGetDibsSubscribe(): Promise<void>
  fetchTokenAlert(payload: boolean): Promise<void>
  //Component
  onClickNaverLoginBtn(): void
  onClickAppleLoginBtn(): void
  closeLoginDialog(yn?: boolean): void
  redirectSubWebView(): void
  loginCheckTimer(): void
  clearTimer(): void
  tokenSetting(): void
  removeHistory(): void
  handlePopstate(): void
}
interface Computed {
  deviceInfo: DeviceInfo
  backgroundImage: string
  user: {
    birthYear: string
    email: string
    name: string
    nickName: string
    phone: string
    sex: string
    signUpDt: string
    userType: number | null // 1003 Naver
  }
}
interface Props {
  dialog: boolean
}
