<template>
  <svg
    width="74"
    height="32"
    viewBox="0 0 74 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_19554_32391" fill="white">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 2C3.79086 2 2 3.79086 2 6V26C2 28.2091 3.79086 30 6 30H68C70.2091 30 72 28.2091 72 26V20.9409C69.2061 20.9401 66.9414 18.7282 66.9414 15.9998C66.9414 13.2714 69.2061 11.0595 72 11.0586V6C72 3.79086 70.2091 2 68 2H6Z"
      />
    </mask>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 2C3.79086 2 2 3.79086 2 6V26C2 28.2091 3.79086 30 6 30H68C70.2091 30 72 28.2091 72 26V20.9409C69.2061 20.9401 66.9414 18.7282 66.9414 15.9998C66.9414 13.2714 69.2061 11.0595 72 11.0586V6C72 3.79086 70.2091 2 68 2H6Z"
      fill="#5BC752"
    />
    <path
      d="M72 20.9409H74C74 19.8366 73.1049 18.9413 72.0006 18.9409L72 20.9409ZM72 11.0586L72.0006 13.0586C73.1049 13.0582 74 12.1629 74 11.0586H72ZM4 6C4 4.89543 4.89543 4 6 4V0C2.68629 0 0 2.68629 0 6H4ZM4 26V6H0V26H4ZM6 28C4.89543 28 4 27.1046 4 26H0C0 29.3137 2.68629 32 6 32V28ZM68 28H6V32H68V28ZM70 26C70 27.1046 69.1046 28 68 28V32C71.3137 32 74 29.3137 74 26H70ZM70 20.9409V26H74V20.9409H70ZM72.0006 18.9409C70.2658 18.9404 68.9414 17.5792 68.9414 15.9998H64.9414C64.9414 19.8771 68.1463 22.9397 71.9994 22.9409L72.0006 18.9409ZM68.9414 15.9998C68.9414 14.4203 70.2658 13.0591 72.0006 13.0586L71.9994 9.05859C68.1463 9.05979 64.9414 12.1224 64.9414 15.9998H68.9414ZM70 6V11.0586H74V6H70ZM68 4C69.1046 4 70 4.89543 70 6H74C74 2.68629 71.3137 0 68 0V4ZM6 4H68V0H6V4Z"
      fill="#5BC752"
      mask="url(#path-1-inside-1_19554_32391)"
    />
    <path
      d="M12.03 17.55C10.92 17.55 10.15 16.73 10.15 15.6C10.15 14.47 10.92 13.65 12.03 13.65C12.93 13.65 13.53 14.17 13.71 15.02H15.45C15.23 13.26 13.89 12.07 12.04 12.07C9.99 12.07 8.44 13.55 8.44 15.6C8.44 17.65 9.99 19.13 12.04 19.13C13.89 19.13 15.23 17.94 15.45 16.18H13.71C13.53 17.03 12.93 17.55 12.03 17.55ZM19.9306 12.07C17.8806 12.07 16.3306 13.62 16.3306 15.6C16.3306 17.58 17.8806 19.13 19.9306 19.13C21.9806 19.13 23.5306 17.58 23.5306 15.6C23.5306 13.62 21.9806 12.07 19.9306 12.07ZM19.9306 17.55C18.8306 17.55 18.0406 16.67 18.0406 15.6C18.0406 14.53 18.8306 13.65 19.9306 13.65C21.0306 13.65 21.8206 14.53 21.8206 15.6C21.8206 16.67 21.0306 17.55 19.9306 17.55ZM30.7284 12.2H29.0284V15.86C29.0284 16.9 28.4484 17.55 27.5584 17.55C26.6684 17.55 26.0984 16.9 26.0984 15.86V12.2H24.3984V15.83C24.3984 17.94 25.6184 19.13 27.5584 19.13C29.5084 19.13 30.7284 17.94 30.7284 15.83V12.2ZM34.5724 12.2H31.8324V19H33.5224V17.27H34.4424C36.2924 17.27 37.4524 16.28 37.4524 14.69C37.4524 13.16 36.3524 12.2 34.5724 12.2ZM34.5424 15.79H33.5224V13.68H34.5424C35.2824 13.68 35.7724 14.08 35.7724 14.69C35.7724 15.36 35.2824 15.79 34.5424 15.79ZM41.6787 12.07C39.6287 12.07 38.0787 13.62 38.0787 15.6C38.0787 17.58 39.6287 19.13 41.6787 19.13C43.7287 19.13 45.2787 17.58 45.2787 15.6C45.2787 13.62 43.7287 12.07 41.6787 12.07ZM41.6787 17.55C40.5787 17.55 39.7887 16.67 39.7887 15.6C39.7887 14.53 40.5787 13.65 41.6787 13.65C42.7787 13.65 43.5687 14.53 43.5687 15.6C43.5687 16.67 42.7787 17.55 41.6787 17.55ZM50.8848 12.2V16.03L47.6048 12.2H46.3148V19H48.0048V15.27L51.2648 19H52.5648V12.2H50.8848Z"
      fill="white"
    />
    <path d="M60 2V30" stroke="white" stroke-width="2" stroke-dasharray="3 3" />
  </svg>
</template>

<script>
export default {}
</script>
