import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { UserUrl, DibsUrl } from '../../plugins/enum'
import { displayApi } from '../../plugins/axios'

// 확인 불가하여, 실제 개발자가 오는 리스폰스를 확인 후 인터페이스 작성 해야함
export const GetDibsSubscribeInfo = async (
  headers?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DibsSubscribeInfo>> =>
  await displayApi.get<DibsSubscribeInfo>(UserUrl.SUBSCRIBE, {
    ...options,
    headers,
  })
export const GetDibsSubscribeMallItemInfo = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SubscribeShopItemGroup>> =>
  await displayApi.get<SubscribeShopItemGroup>(UserUrl.SUBSCRIBE, {
    ...options,
    params,
  })

export const PostDibsProductCategoryInfo = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DibsDefaultFolder>> =>
  await displayApi.get<DibsDefaultFolder>(DibsUrl.DIBS_DEFAULT_FOLDER, {
    ...options,
    params,
  })

export const GetDibsProductCategoryInfo = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DibsTabCategory>> =>
  await displayApi.get<DibsTabCategory>(DibsUrl.DIBS_TAB_CATEGORY, {
    ...options,
    params,
  })

export const GetDibsProductInfo = async (
  params?: {
    filterValue: number
    isSale: number
    isAvailable: number
    page: number
    limit: number
  },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DibsLikeProductItem>> =>
  displayApi.get<DibsLikeProductItem>(DibsUrl.DIBS_PRODUCT, {
    ...options,
    params,
  })

export default {
  GetDibsSubscribeInfo,
  GetDibsSubscribeMallItemInfo,
  GetDibsProductCategoryInfo,
  GetDibsProductInfo,
}
