export const NoticeViewOneData: NoticeView = {
  noticeViewList: [
    {
      id: 0,
      topYn: '',
      subject: '',
      regDt: '',
      updDt: '',
    },
  ],
}

export const NoticeViewOnePagingData: NoticeViewPaging = {
  paging: {
    total: 0,
    size: 0,
    page: 0,
    pageSize: 0,
    hasNext: true,
  },
}

export const NoticeViewOneDetailData: NoticeViewDetail = {
  noticeDetail: {
    id: 0,
    subject: '',
    contents: '',
    publishedYN: '',
    topYN: '',
    loginId: '',
    regDt: '',
    updDt: '',
  },
}

export const NoticeData: NoticeGroup = {
  noticeViewList: NoticeViewOneData,
  paging: NoticeViewOnePagingData,
  noticeDetail: NoticeViewOneDetailData,
}
