import { RootState } from '@/store/RootState'
import { mapActions, mapGetters, mapState, Module } from 'vuex'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import { GetCategoryBestSeller } from '../api/displayAPI/CategoryAPI'
import { SearchProduct } from '@/api/displayAPI/ProductAPI'
import lodash from 'lodash'
import store from './index'

const STORE_NAME = 'RecentlyStore'

const getters: GetterTreeAdaptor<RecentlyGetters, RecentlyState, RootState> = {
  getRecentlyProduct(state: RecentlyState) {
    return state.recentlyProduct
  },
  getRecentlyStoreScrollLocation(state: RecentlyState) {
    return state.scroll
  },
}

// TODO 1
const actions: ActionTreeAdaptor<RecentlyActions, RecentlyState, RootState> = {
  async fetchRecentlyProduct({ commit }, offLoading = false) {
    try {
      if (!offLoading) store.dispatch('LoadingStore/fetchLoading', { yn: true })

      const items: string[] = await JSON.parse(
        localStorage.getItem('recentProducts') ?? '[]'
      )

      const { data } = await SearchProduct({
        productNo: lodash.uniq(items).join(),
      })

      commit(
        'setRecentlyProduct',
        data.filter((item) => item.isAvailable === true).slice(0, 30)
      )
    } catch (e) {
      console.log('fetchRecentlyProduct err', e)
    } finally {
      if (!offLoading) {
        store.dispatch('LoadingStore/fetchLoading', { yn: false })
      }
    }
  },
  async fetchBestSeller({ commit }) {
    const params: any = {
      weeklyOrMontly: 0,
      categoryCode: '7',
      page: 0,
      limit: 51,
    }
    const { data } = await GetCategoryBestSeller(params)
    commit('setBestSeller', data.searchProductList ?? [])
  },
  async fetchClearRecentlyProduct({ commit }) {
    commit('setRecentlyProduct', [])
  },
  async fetchRecentlyStoreScrollLocation({ commit }, scroll) {
    commit('setRecentlyStoreScrollLocation', scroll)
  },
}

export const RecentlyStore: Module<RecentlyState, RootState> = {
  namespaced: true,
  state: {
    recentlyProduct: [],
    bestSeller: [],
    loginedUserProduct: [],
    scroll: 0,
  },
  getters,
  mutations: {
    setRecentlyProduct(
      state: RecentlyState,
      productItems: Array<ProductItem> = []
    ) {
      state.recentlyProduct = productItems
    },
    setBestSeller(state: RecentlyState, payload: Array<ProductItem>) {
      state.bestSeller = payload
    },
    setLoginedUserProduct(state: RecentlyState, payload: Array<ProductItem>) {
      state.loginedUserProduct = payload
    },
    setRecentlyStoreScrollLocation(state: RecentlyState, payload: number) {
      state.scroll = payload
    },
  },
  actions,
}

export const mappedCountState = mapState(STORE_NAME, ['recentlyProduct'])

export interface RecentlyState {
  recentlyProduct: Array<ProductItem>
  bestSeller: ProductItem[]
  loginedUserProduct: ProductItem[]
  scroll: number
}

export const mappedCountGetters = mapGetters(STORE_NAME, ['getRecentlyProduct'])

export interface RecentlyGetters {
  getRecentlyProduct: Array<ProductItem>
  getRecentlyStoreScrollLocation: number
}

export const mappedCountActions = mapActions(STORE_NAME, [
  'fetchRecentlyProduct',
])

export type RecentlyActions = {
  fetchRecentlyProduct: (offLoading: boolean) => Promise<void>
  fetchBestSeller: () => Promise<void>
  fetchClearRecentlyProduct: () => Promise<void>
  fetchRecentlyStoreScrollLocation: (scroll: number) => Promise<void>
}
