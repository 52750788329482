import { RootState } from '@/store/RootState'
import { Module } from 'vuex'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import {
  GetCategoryPlanexDetail,
  GetCategoryPlanexList,
} from '@/api/displayAPI/CategoryAPI'

const getters: GetterTreeAdaptor<CelltiqueGetters, CelltiqueState, RootState> =
  {
    getCelltiques(state: CelltiqueState) {
      return state.celltiques
    },
    getCelltiqueDetail(state: CelltiqueState) {
      return state.celltiqueDetail
    },
  }

const actions: ActionTreeAdaptor<CelltiqueAction, CelltiqueState, RootState> = {
  async fetchCategoryPlanex({ commit }) {
    const { data } = await GetCategoryPlanexList()
    commit('setCategoryPlanex', data)
  },
  async fetchCategoryPlanexDetail({ commit }, promotionNo) {
    const { data } = await GetCategoryPlanexDetail({ promotionNo })
    commit('setCategoryPlanexDetailList', data)
  },
}

export const CelltiqueStore: Module<CelltiqueState, RootState> = {
  namespaced: true,
  state: {
    celltiques: [
      {
        description: '',
        icon: '',
        promotionNo: 0,
        promotionType: '',
        subIcon: '',
        title: '',
      },
    ],
    celltiqueDetail: {
      planexCategoryDetailList: [],
      planexCategoryTitle: {
        description: '',
        icon: '',
        promotionNo: 0,
        promotionType: '',
        subIcon: '',
        title: '',
      },
    },
  },
  getters,
  mutations: {
    setCategoryPlanex(state: CelltiqueState, payload: CategoryPlanex[]) {
      state.celltiques = payload
    },
    setCategoryPlanexDetailList(
      state: CelltiqueState,
      payload: celltiqueDetail
    ) {
      state.celltiqueDetail = payload
    },
  },
  actions,
}

export interface CelltiqueState {
  celltiques: CategoryPlanex[]
  celltiqueDetail: celltiqueDetail
}

export interface CelltiqueGetters {
  getCelltiques: CategoryPlanex[]
  getCelltiqueDetail: celltiqueDetail
}

export type CelltiqueAction = {
  fetchCategoryPlanex(): Promise<void>
  fetchCategoryPlanexDetail(payload: number): Promise<void>
}

type celltiqueDetail = {
  planexCategoryDetailList: Array<{
    title: string
    searchProductList: ProductItem[]
  }>
  planexCategoryTitle: CategoryPlanex
}
