import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ProductUrl } from '../../plugins/enum'
import { displayApi } from '../../plugins/axios'
import store from '@/store'

export const SearchProduct = async (
  data: { productNo: string },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ProductItem[]>> =>
  await displayApi.get<ProductItem[]>(
    ProductUrl.SEARCH_PRODUCT + `?productNo=${data.productNo}`,
    {
      ...options,
    }
  )
export const SearchProductUseCache = async (
  data: { productNo: string; useCache: boolean },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ProductItem[]>> =>
  await displayApi.get<ProductItem[]>(
    ProductUrl.SEARCH_PRODUCT + `?productNo=${data.productNo}&useCache=true`,
    {
      ...options,
    }
  )

export const SearchGoodsNo = async (
  data: { goodsNo: string },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ProductItem[]>> =>
  await displayApi.get<ProductItem[]>(
    ProductUrl.SEARCH_PRODUCT + `?goodsNo=${data.goodsNo}`,
    {
      ...options,
    }
  )

export const PutViewCount = async (
  product: ProductItem,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  const data = {
    productNo: product.productNo,
    userEventData: userEventData(product),
  }
  return displayApi.put<any>(ProductUrl.VIEW_COUNT, data, options)
}

function userEventData(product: ProductItem): UserEventData {
  return {
    strData1: product.shopName ?? product.mallName,
    price: product.discountPrice,
    strData2: product.category1,
    strData3: product.category2,
    productNo: product.productNo,
    uuid: store?.state?.DeviceStore?.deviceInfo?.uid,
    appVersion: store?.state?.DeviceStore?.deviceInfo?.appVer,
    platform: store.state.DeviceStore.deviceInfo?.os,
  }
}

export const GetProducts = async (
  productNoArr: string[],
  options?: AxiosRequestConfig
) => {
  const url = ProductUrl.SEARCH_PRODUCT_ARRAY
  let query = ''
  productNoArr.forEach((s) => {
    query += `productNoList=${s}&`
  })

  return displayApi.get<ProductItem[]>(`${url}?${query}`, options)
}

export default {
  PutViewCount,
  SearchProduct,
  SearchGoodsNo,
  SearchProductUseCache,
}
