import Vue from 'vue'
import VueRouter, {
  RawLocation,
  Route,
  RouteConfig,
  NavigationGuardNext,
} from 'vue-router'
import Store from '../store'
import { kakaoPixel } from '@/plugins/util'

Vue.use(VueRouter)

const tokenValidationGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
) => {
  const accessToken = localStorage.getItem('accessToken')
  if (!accessToken) return next('/')
  next()
}

const loginValidationGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
) => {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) return next('/')
  if (from.path !== '/') return next('/')
  if (!to.hash) return next('/')
  next()
}

const routes: Array<RouteConfig> = [
  {
    path: '*',
    name: 'notFound',
    component: () => import('../components/pages/404.vue'),
  },
  {
    path: '/500',
    name: '500',
    component: () => import('../components/pages/500.vue'),
  },
  {
    path: '/',
    name: 'Main',
    component: () => import('../components/pages/main/Main.vue'),
    meta: { isCheckInOpen: true },
  },
  {
    path: '/categoryMain',
    name: 'CategoryMain',
    beforeEnter: (to, from, next) => {
      Store.dispatch('CategoryStore/fetchCategoryCode', '')
      next()
    },
    component: () => import('../components/pages/category/CategoryMain.vue'),
    meta: { isCheckInOpen: true },
  },
  {
    path: '/categoryProductList',
    name: 'CategoryProductList',
    component: () =>
      import('../components/pages/category/CategoryProductList.vue'),
  },
  {
    path: '/categoryBestList',
    name: 'CategoryBestList',
    component: () =>
      import('../components/pages/category/CategoryBestList.vue'),
  },
  {
    path: '/categoryNewList',
    name: 'CategoryNewList',
    component: () => import('../components/pages/category/CategoryNewList.vue'),
  },
  {
    path: '/categoryTopShopList',
    name: 'CategoryTopShopList',
    component: () =>
      import('../components/pages/category/CategoryTopShopList.vue'),
  },
  {
    path: '/categoryExhibitionMain',
    name: 'CategoryExhibitionMain',
    component: () =>
      import('../components/pages/category/CategoryExhibitionMain.vue'),
  },
  {
    path: '/categoryExhibitionList',
    name: 'CategoryExhibitionList',
    component: () =>
      import('../components/pages/category/CategoryExhibitionList.vue'),
  },
  {
    path: '/categorySeasonList',
    name: 'CategorySeasonList',
    component: () =>
      import('../components/pages/category/CategorySeasonList.vue'),
  },
  {
    path: '/dibs',
    name: 'Dibs',
    component: () => import('../components/pages/dibs/Dibs.vue'),
    beforeEnter: tokenValidationGuard,
    meta: { isCheckInOpen: true },
  },
  {
    path: '/mypage',
    name: 'MyPage',
    component: () => import('../components/pages/myPage/MyPage.vue'),
    meta: { isCheckInOpen: true },
  },
  {
    path: '/mypageBoard',
    name: 'MyPageBoardBoard',
    component: () => import('../components/pages/myPage/MyPageBoard.vue'),
  },
  {
    path: '/mypage/info',
    name: 'Info',
    component: () => import('../components/pages/myPage/Info.vue'),
    beforeEnter: tokenValidationGuard,
  },
  {
    path: '/mypage/withdrawal',
    name: 'MyPageWithdrawal',
    component: () => import('../components/pages/myPage/Withdrawal.vue'),
    beforeEnter: tokenValidationGuard,
  },
  {
    path: '/mypage/withdrawalWrite',
    name: 'MyPageWithdrawalWrite',
    component: () => import('../components/pages/myPage/WithdrawalWrite.vue'),
    beforeEnter: tokenValidationGuard,
  },
  {
    path: '/mypage/setting',
    name: 'MypageSetting',
    component: () => import('../components/pages/myPage/Setting.vue'),
  },
  {
    path: '/mypage/push-notice',
    name: 'PushNotice',
    component: () => import('../components/pages/myPage/PushNotice.vue'),
  },
  {
    path: '/recentlyProduct',
    name: 'RecentlyProduct',
    component: () =>
      import('../components/pages/recentlyProduct/RecentlyProduct.vue'),
    meta: { name: 'RecentlyStore', isCheckInOpen: true },
  },
  {
    path: '/storeHome',
    name: 'StoreHome',
    component: () => import('../components/pages/storeHome/StoreHome.vue'),
  },
  {
    path: '/footerTerms',
    name: 'FooterTerms',
    component: () => import('../components/pages/footer/FooterTerms.vue'),
  },
  {
    path: '/footerPrivacy',
    name: 'FooterPrivacy',
    component: () => import('../components/pages/footer/FooterPrivacy.vue'),
  },
  {
    path: '/footerRecruit',
    name: 'FooterRecruit',
    component: () => import('../components/pages/footer/FooterRecruit.vue'),
  },
  {
    path: '/loginLandingPage',
    name: 'LoginLandingPage',
    component: () => import('../components/pages/login/LoginLandingPage.vue'),
    beforeEnter: loginValidationGuard,
  },
  {
    path: '/loginActionPage',
    name: 'LoginActionPage',
    component: () => import('../components/pages/login/LoginActionPage.vue'),
  },
  {
    path: '/loginLandingPageApple',
    name: 'LoginLandingPageApple',
    component: () =>
      import('../components/pages/login/LoginLandingPageApple.vue'),
  },
  {
    path: '/signInComplete',
    name: 'SignInComplete',
    component: () => import('../components/pages/login/SignInComplete.vue'),
  },
  {
    path: '/marketing-agree',
    name: 'MarketingAgree',
    component: () => import('../components/pages/login/MarketingAgree.vue'),
    beforeEnter: tokenValidationGuard,
  },
  {
    path: '/appleLoginMoreInformation',
    name: 'AppleLoginMoreInformation',
    component: () =>
      import('../components/pages/login/AppleLoginMoreInformation.vue'),
  },
  {
    path: '/urgentNotice',
    name: 'UrgentNotice',
    component: () =>
      import('../components/pages/urgentNotice/UrgentNotice.vue'),
  },
  {
    path: '/exhibitionEvent',
    name: 'ExhibitionEvent',
    component: () => import('../components/pages/event/ExhibitionEvent.vue'),
  },
  {
    path: '/promotionList',
    name: 'PromotionList',
    component: () => import('../components/pages/event/PromotionList.vue'),
  },
  {
    path: '/promotionSignUp',
    name: 'PromotionSignUp',
    meta: {},
    component: () => import('../components/pages/event/PromotionSignUp.vue'),
  },
  {
    path: '/adminMultiMallPromotion',
    name: 'AdminMultiMallPromotion',
    component: () => import('../components/pages/event/MultiMallPromotion.vue'),
  },
  {
    path: '/multiMallPromotion',
    name: 'MultiMallPromotion',
    component: () => import('../components/pages/event/MultiMallPromotion.vue'),
    beforeEnter: (to, from, next) => {
      if (from.name === 'PromotionList') {
        Store.dispatch('ExhibitionStore/fetchMultiMallExhibitionData', {})
      }
      next()
    },
  },
  {
    path: '/promotionMultiMall',
    name: 'PromotionMultiMall',
    component: () => import('../components/pages/event/PromotionMultiMall.vue'),
  },
  // 다니엘 컨펌 후에 추후 제거
  {
    path: '/promotionSingleMall',
    name: 'PromotionSingleMall',
    component: () =>
      import('../components/pages/event/PromotionSingleMall.vue'),
  },
  {
    path: '/singleMallPromotion',
    name: 'SingleMallPromotion',
    component: () =>
      import('../components/pages/event/SingleMallPromotion.vue'),
    beforeEnter: (to, from, next) => {
      if (from.name === 'PromotionList') {
        sessionStorage.removeItem('singleExhibitionStory')
      }
      next()
    },
  },
  {
    path: '/adminSingleMallPromotion',
    name: 'AdminSingleMallPromotion',
    component: () =>
      import('../components/pages/event/SingleMallPromotion.vue'),
  },
  // 다니엘 컨펌 후에 추후 제거
  {
    path: '/promotionSignUpSeptember',
    name: 'PromotionSignUpSeptember',
    component: () =>
      import('../components/pages/event/PromotionSignUpSeptember.vue'),
  },
  // 다니엘 컨펌 후에 추후 제거
  {
    path: '/promotionSignUp2',
    name: 'PromotionSignUp2',
    meta: {},
    component: () => import('../components/pages/event/PromotionSignUp2.vue'),
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../components/pages/search/Search.vue'),
    meta: { isCheckInOpen: true },
  },
  {
    path: '/event',
    name: 'Event',
    component: () => import('@/components/pages/event/Event.vue'),
  },
  {
    path: '/checkIn',
    name: 'CheckIn',
    component: () => import('@/components/pages/event/CheckIn.vue'),
    beforeEnter: (to, from, next) => {
      if (!from.name) {
        Store.dispatch('CheckInStore/fetchFromNoWhere', { status: true })
      } else {
        Store.dispatch('CheckInStore/fetchFromNoWhere', { status: false })
      }
      next()
    },
  },
  {
    path: '/event-list',
    name: 'EventList',
    component: () => import('@/components/pages/event/EventList.vue'),
  },
  {
    path: '/event/admin-w-app',
    name: 'Event',
    component: () => import('@/components/pages/event/EventRenewal.vue'),
  },
  {
    path: '/event/w-app',
    name: 'Event',
    component: () => import('@/components/pages/event/EventRenewal.vue'),
  },
  {
    path: '/tracking/test',
    name: 'TrackingTest',
    component: () => import('@/components/pages/tracking/Tracking.vue'),
    beforeEnter: (to, from, next) => {
      if (window.prompt('password') === 'cellook!@') {
        next()
      } else {
        next('/')
      }
    },
  },
  {
    path: '/shopping-live',
    redirect: '/',
  },
  {
    path: '/naver-delivery',
    name: 'NaverDelivery',
    component: () => import('@/components/pages/nDelivery/NaverDelivery.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    Vue.nextTick(() => {
      const app = document.getElementById('app') as HTMLElement
      if (!app) return
      try {
        app.style.overflowY = 'hidden'
        // 점차 페이지들 마다 조건을 걸긴 어려울 수 있음 부피가 커질 때, 코드 개선
        if (to.path === '/') {
          const { scroll, activeTab } = Store.state.HomeStore.redirect
          app.scrollTo({ top: scroll[activeTab] })
          return
        }

        if (to.path === '/search') {
          const { scroll } = Store.state.SearchStore
          app.scrollTo({ top: scroll })
          return
        }

        // 카테고리도 Refresh 고도화 진행 시킨 후엔 아래 예외처리 제외가능
        const categoryException = ['/categoryMain'].some(
          (item) => item === to.path
        )

        if (!categoryException && to.path.startsWith('/category')) {
          const scroll = Store.getters['CategoryStore/getScrollLocation']
          app.scrollTo({ top: scroll })
          return
        }

        if (
          to.path === '/promotionList' &&
          (from.path === '/multiMallPromotion' ||
            from.path === '/singleMallPromotion')
        ) {
          const scroll =
            Store.getters['ExhibitionStore/getPromotionListScrollLocation']
          app.scrollTo({ top: scroll })
          return
        }

        // meta.name을 이용하여 적용하고픈 페이지에 스크롤을 동적으로 추가
        if (to.meta?.name) {
          const scroll =
            Store.getters[`${to.meta.name}/get${to.meta.name}ScrollLocation`]
          app.scrollTo({ top: scroll })
          // 초기화
          Store.dispatch(
            `${to.meta.name}/fetch${to.meta.name}ScrollLocation`,
            0
          )
          return
        }

        // https://lunasoft.atlassian.net/jira/software/c/projects/CELLOOK/issues/CELLOOK-3571
        // 해당 이슈로 주석 처리 하였음.

        //각각의 스토에 스크롤 탑을 추가하는 대신 전역적으로 사용하기위해 RouteHistoryStore 를 사용하여 스크롤 제어
        // const history = Store.getters['RouteHistoryStore/getScrollTopMap']
        // if (history[to.path]) {
        //   setTimeout(() => {
        //     app.scrollTo({ top: history[to.path] })
        //   })
        //   return
        // }

        app.scrollTo({ top: 0 })
      } finally {
        app.style.overflowY = 'scroll'
      }
    })
  },
})

const superPush = VueRouter.prototype.push
VueRouter.prototype.push = async function push(
  loc: RawLocation
): Promise<Route> {
  try {
    return await superPush.bind(this)(loc)
  } catch (e: any) {
    if (e?.name === 'NavigationDuplicated') {
      console.warn(e)
      return e
    } else {
      throw e
    }
  }
}

router.beforeEach((to, from, next) => {
  const history = Store.getters['RouteHistoryStore/getPreviousPage']
  //router.back() 으로 돌아가지 않고 다른페이지 이동할때 스크롤 위치 초기화
  if (to.path !== history.path) {
    Store.dispatch('RouteHistoryStore/fetchPreviousPageScroll', {
      name: history.path,
      scrollTop: 0,
    })
  }
  const app = document.getElementById('app') as HTMLElement

  // 이전 페이지를 수동으로 저장
  const { name, fullPath, path } = from
  Store.dispatch('RouteHistoryStore/fetchPreviousPage', {
    name,
    fullPath,
    path,
  })

  //스크롤 위치 path 별 저장
  Store.dispatch('RouteHistoryStore/fetchPreviousPageScroll', {
    name: from.path,
    scrollTop: app.scrollTop,
  })

  if (to.meta?.isCheckInOpen) {
    try {
      Store.dispatch('CheckInStore/fetchCheckInNo')
    } catch (e) {
      console.log(e)
    }
  }

  window.AF('banners', 'showBanner', {
    bannerZIndex: 1000,
    additionalParams: { p1: 'v1', p2: 'v2' },
  })
  // kakao ga kakao pixel
  try {
    window.fbq('track', 'PageView')
  } catch (e) {
    console.log('fbq page view event error')
  }

  try {
    kakaoPixel('pageView')
  } catch (e) {
    console.log('kakao pixel page view event error')
  }

  // 트로버 문구 출력 여부
  if (!sessionStorage.getItem('appInit')) {
    sessionStorage.setItem('appInit', 'Y')
  } else {
    sessionStorage.setItem('appInit', 'N')
  }

  if (to.name !== 'StoreHome' && localStorage.getItem('storeHomeHistory')) {
    localStorage.removeItem('storeHomeHistory')
  }

  const categoryPageHistory = Store.getters['CategoryStore/getPageHistory']
  if (
    to.name === 'StoreHome' &&
    (from.name?.includes('CategoryProductList') ||
      from.name?.includes('CategorySeasonList') ||
      from.name?.includes('CategoryBestList') ||
      from.name?.includes('CategoryNewList') ||
      from.name?.includes('CategoryTopShopList') ||
      from.name?.includes('CategoryExhibitionList'))
  ) {
    localStorage.setItem('categoryHistory', JSON.stringify(categoryPageHistory))
  }
  if (
    from.name === 'StoreHome' &&
    !to.name?.includes('CategoryProductList') &&
    !to.name?.includes('CategorySeasonList') &&
    !to.name?.includes('CategoryBestList') &&
    !to.name?.includes('CategoryNewList') &&
    !to.name?.includes('CategoryTopShopList') &&
    !to.name?.includes('CategoryExhibitionList')
  ) {
    localStorage.removeItem('categoryHistory')
  }
  next()
})

export default router
