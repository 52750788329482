import Vue from 'vue'

const replaceItem = (data: ProductItem[], no: number) => {
  const res = data.map((item) => {
    return { ...item, groupKey: no }
  })
  return res
}

export const replaceList = (
  list: ProductItem[],
  addList: ProductItem[],
  page: number,
  limit: number,
  type: string
) => {
  if (type === 'next') {
    const res = [...list, ...replaceItem(addList, page)]
    return res.length > limit * 3 ? res.slice(limit, limit * 4) : res
  }
  if (type === 'prev') {
    return [...replaceItem(addList, page), ...list].slice(0, limit * 3)
  }

  if (type === 'reset') {
    return [...replaceItem(addList, page)]
  }
}

export const infiniteRefreshList = (
  data: Array<ProductItem[]>,
  initFirstPage: number
) => {
  const res: ProductItem[] = []
  data.map((list: ProductItem[], idx: number) => {
    res.push(
      ...list.map((item: ProductItem) => ({
        ...item,
        groupKey: initFirstPage + idx,
      }))
    )
  })

  return res
}

Vue.prototype.$replaceList = replaceList
Vue.prototype.$infiniteRefreshList = infiniteRefreshList
