<template>
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="19" cy="19" r="19" fill="#F5F7FA" />
    <g clip-path="url(#clip0_11334_28914)">
      <circle cx="19" cy="19" r="8.30625" stroke="#222222" stroke-width="1.2" />
      <path
        d="M18.9993 18.1902C20.3408 18.1902 21.4283 17.1027 21.4283 15.7613C21.4283 14.4198 20.3408 13.3323 18.9993 13.3323C17.6578 13.3323 16.5703 14.4198 16.5703 15.7613C16.5703 17.1027 17.6578 18.1902 18.9993 18.1902Z"
        stroke="#222222"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.332 23.858V24.3978C13.332 23.3957 13.7301 22.4346 14.4387 21.7261C15.1473 21.0175 16.1083 20.6194 17.1104 20.6194H20.8888C21.8909 20.6194 22.852 21.0175 23.5606 21.7261C24.2692 22.4346 24.6673 23.3957 24.6673 24.3978V23.858"
        stroke="#222222"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11334_28914">
        <rect
          width="25.9091"
          height="25.9091"
          fill="white"
          transform="translate(6.04492 6.04541)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({})
</script>
