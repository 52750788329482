import Store from '@/store/index'

export const needAnimHeaderNames = [
  'Main',
  'CategoryMain',
  'RecentlyProduct',
  'Dibs',
  'CategoryExhibitionList',
  'PromotionSingleMall',
  'PromotionMultiMall',
  'SingleMallPromotion',
  'MultiMallPromotion',
  'AdminMultiMallPromotion',
  'AdminSingleMallPromotion',
  'PromotionList',
  'CheckIn',
  'EventList',
]
export const showFlipBannerNames = [
  'Main',
  'CategoryMain',
  'RecentlyProduct',
  'Dibs',
  'CategoryExhibitionList',
  'PromotionSingleMall',
  'PromotionMultiMall',
  'SingleMallPromotion',
  'MultiMallPromotion',
  'AdminMultiMallPromotion',
  'AdminSingleMallPromotion',
  'PromotionList',
  'CheckIn',
  'EventList',
]

interface AdonBannerTypes {
  [key: string]: Array<number>
}

/**
 * Adon API를 호출하기 위한 type ( 페이지별 사용하는 type을 배열형태로 Request)
 *
 * 0: FLIP
 * 1: BIG
 * 2: ADVERTISING
 * 3: STRIP
 * 4: CATEGORY_STRIP
 * 5: MDPICK_BOTTOM
 * 6: SALE_TOP
 */

const adonBannerTypes: AdonBannerTypes = {
  Main: [0, 1, 2, 3, 5, 6],
  CategoryMain: [0, 4],
  RecentlyProduct: [0],
  Dibs: [0],
  StoreHome: [0],
  CategoryExhibitionList: [0],
  PromotionSingleMall: [0],
  PromotionMultiMall: [0],
  SingleMallPromotion: [0],
  MultiMallPromotion: [0],
  AdminMultiMallPromotion: [0],
  AdminSingleMallPromotion: [0],
  PromotionList: [0],
  CheckIn: [0],
  EventList: [0],
}

export const onRequestAdonBanner = (name: string) => {
  const find: string = Object.keys(adonBannerTypes).filter(
    (item) => item === name
  )[0]
  if (!find) return
  if (!adonBannerTypes[find]) return

  Store.dispatch('BannerStore/fetchAdonBanner', {
    types: adonBannerTypes[find],
  })
}
