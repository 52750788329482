import Vue from 'vue'
import moment from 'moment'
import Store from '../store'
import Router from '../router'

import { PutViewCount, SearchProduct } from '@/api/displayAPI/ProductAPI'
import { PostRecentlyView } from '@/api/displayAPI/UserAPI'
import { GetCategoryPlanexDetail } from '@/api/displayAPI/CategoryAPI'
import lodash from 'lodash'
import { AxiosResponse } from 'axios'

export let appHtml: HTMLElement | null
let flipBannerHeight = 40
Vue.nextTick(() => {
  appHtml = document.getElementById('app') as HTMLElement
  const appWidth = Math.min(appHtml.offsetWidth, 600)
  flipBannerHeight = Math.floor(appWidth * 0.16)
})

const packageJson = require('../../package.json')
export const webVersion = packageJson.version

export const mobileOS = () => {
  const varUA = navigator.userAgent.toLowerCase() //userAgent 값 얻기

  if (varUA.indexOf('android') > -1) {
    //안드로이드
    return 'android'
  } else if (
    varUA.indexOf('iphone') > -1 ||
    varUA.indexOf('ipad') > -1 ||
    varUA.indexOf('ipod') > -1
  ) {
    //IOS
    return 'ios'
  } else {
    //아이폰, 안드로이드 외
    return 'other'
  }
}

export const criteo = (event: any) => {
  try {
    const userEmail = Store.getters['UserStore/userEmail'].trim().toLowerCase()
    window.criteo(userEmail, event)
  } catch (e) {
    console.log('criteo util error')
  }
}

export const kakaoPixel = (key: string, data?: any) => {
  try {
    window.kakaoPixel[key]({ ...data })
  } catch (e) {
    console.log('kakao pixcel error')
  }
}

/**
 * 웹트래픽 전환 이벤트
 */
export const trackingConversion = () => {
  window.gtag('event', 'conversion', {
    send_to: 'AW-10879173272/FRAdCNC78rADEJiFzMMo', //Google Ads 에서 설정된 전환 이벤트 ID
  })
}

/**
 * GA, AppsFlyer 트래킹
 * @param action 이름 (ex. home_bottom_Items_more_01)
 */
export const tracking = (action: string) => {
  try {
    const deviceInfo = Store.state.DeviceStore.deviceInfo
    if (!deviceInfo) {
      window.gtag('event', action)
      window.AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: action,
      })
      return
    }

    switch (mobileOS()) {
      case 'android':
        window.CellookBridge?.sendGAEvent(action)
        break
      case 'ios':
        window.webkit?.messageHandlers?.sendGAEvent?.postMessage(action)
        break
      default:
        window.gtag('event', action)
        break
    }
    window.AF('pba', 'event', {
      eventType: 'EVENT',
      eventName: action,
    })
  } catch (e) {
    console.log('tracking util error', e)
  }
}

export const trackingPromotion = (data: any) => {
  try {
    window.gtag('event', 'page_view', {
      page_title: data.title,
      page_location: data.location,
      page_path: data.path,
      send_to: 'G-1HVYPZG0HC',
    })
  } catch (e) {
    console.log('tracking promotion error', e)
  }
}

export const numbering = (number: number) => {
  if (number < 10) return '0' + number
  return number
}

export const urgentNoticeTime =
  1000 * 60 * (process.env.VUE_APP_URGEN_NOTICE_TIME ?? 5)

export const handleRate = (price: number, rate: number): number =>
  price - price / rate

export const handleTransform = (price = 0): string | number => {
  const parts = price.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const replaceHtml = (data: string): string => {
  return data.replace(/(?:\r\n|\r|\n|\\n)/g, '<br />')
}

export const replaceHtml2 = (data: string): string => {
  return data.replace(/(?:\r\n|\r|\n|\\n)/g, '&nbsp;')
}

export const replaceGap = (data: string): string => {
  // return data.replace(/ /g, '')
  return data.trim()
}

export const getDate = (format: string): string => {
  return moment().format(format)
}

export const isMobile = () => {
  const UserAgent = navigator.userAgent
  if (
    UserAgent.match(
      /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
    ) != null ||
    UserAgent.match(/LG|SAMSUNG|Samsung/) != null
  ) {
    return true
  } else {
    return false
  }
}

// 최근 본 상품
export const saveRecentProduct = async (
  productItem: ProductItem
): Promise<void> => {
  const recentProduct: Array<string> = JSON.parse(
    localStorage.getItem('recentProducts') ?? '[]'
  )
  const index = recentProduct.findIndex(
    (item) => item === productItem.productNo
  )
  // let value: any
  //아이템 있으면 최신순서로 변경
  if (index !== -1) {
    const splicedItem = recentProduct.splice(index, 1)
    recentProduct.unshift(...splicedItem)
  } else {
    recentProduct.unshift(productItem.productNo)
  }
  // 길이 30 체크
  const parsedValue: string = JSON.stringify(
    lodash.uniq(recentProduct.slice(0, 30))
  )

  localStorage.setItem('recentProducts', parsedValue)
  sessionStorage.setItem('appInit', 'N')
  await Store.dispatch('RecentlyStore/fetchRecentlyProduct', true)
}

export const toSrcPrefix = (src: string) => {
  if (!src) {
    return ''
  }
  if (src.startsWith('http://shop1.phinf.naver.net')) {
    return src.replace(
      'http://shop1.phinf.naver.',
      'https://shop-phinf.pstatic.'
    )
  }
  return src
}

// 스마트 스토어 이미지 사이즈
export const toSrcPostfix = (src: string) => {
  if (!src) {
    return ''
  }
  return `${src}?type=m510`
}

export const handleTimeFormat = (date: string): string => {
  if (date === '') {
    return ''
  }
  return ''
}

export const rendingProduct = async (itemNo: string) => {
  try {
    const { data } = await SearchProduct({ productNo: itemNo })

    if (data.length > 0) {
      const { productNo } = data[0]
      await PutViewCount(data[0])

      if (Store.getters['UserStore/isLogined']) {
        try {
          await PostRecentlyView({ productNo })
        } catch (e) {
          console.log(e)
        }
      }
      await saveRecentProduct(data[0])
      await Store.dispatch('BridgeStore/fetchRendingProduct', data[0])
    } else {
      Store.dispatch('DisplayStore/asyncGetDisplay')
    }
  } catch (e) {
    console.log('rending product search error')
  }
}

export const rendExhibition = async (promotionNo: string) => {
  try {
    const params = {
      promotionNo: Number(promotionNo),
    }
    const { data } = await GetCategoryPlanexDetail(params)
    // http://localhost:8080/categoryExhibitionList?promotionNo=280&categoryName=%ED%94%BC%ED%84%B0%20%EA%B8%B0%ED%9A%8D%EC%A0%84_1&pageHistory=categoryExhibitionMain
    Router.push(
      `/categoryExhibitionList?promotionNo=${data.planexCategoryTitle.promotionNo}&categoryName=${data.planexCategoryTitle.title}&previousPage=/`
    )
  } catch (error) {
    Store.dispatch('DisplayStore/asyncGetDisplay')
    console.log(error)
  }
}

export const vueCarouselCycle = (list: Array<any>, index: number) => {
  // vue  carousel 아이템이 2개인 경우 한 반향으로 흘러가지 않는 것을 보완
  if (list.length <= 2 && index !== list.length - 1) return true
  return false
}

export const getAdOnBannerKey = (url: string) => url.split('key=')[1]

export const adOnTracking = (
  url: string,
  promise: (key: string) => Promise<AxiosResponse>
) => {
  if (isAdonViewAPIWhenShowDialog()) {
    return
  }

  promise(getAdOnBannerKey(url)).then(() => {
    console.log('ADON 트래킹')
  })

  function isAdonViewAPIWhenShowDialog(): boolean {
    return (
      url.includes('view') && Store?.state?.DialogStore?.loginDialog === true
    )
  }
}
export const truncate = (input: string, index = 6): string => {
  return input.length > index ? `${input.substring(0, index)}...` : input
}

export const numberFormatCompact = (count: number): string =>
  new Intl.NumberFormat('ko-kr', {
    style: 'decimal',
    notation: 'compact',
  }).format(count)

export const dateFormatForMonthDay = (dateStr: string) => {
  const date = new Date(Date.parse(dateStr))
  return `${date.getUTCMonth() + 1}월 ${date.getDate()}일`
}

export const dateFormatForTime = (dateStr: string) => {
  const date = new Date(Date.parse(dateStr))
  return `${date.getHours()}:${numbering(date.getMinutes())}`
}

export const sleep = (timeout: number) =>
  new Promise((resolve) => setTimeout(resolve, timeout))

Vue.filter('handleRate', handleRate)
Vue.filter('handleTransform', handleTransform)
Vue.filter('replaceHtml', replaceHtml)
Vue.filter('toSrcPrefix', toSrcPrefix)
Vue.filter('toSrcPostfix', toSrcPostfix)
Vue.filter('getDate', getDate)
Vue.filter('isMobile', isMobile)
Vue.filter('numbering', numbering)
Vue.filter('truncate', truncate)
Vue.prototype.$handleRate = handleRate
Vue.prototype.$handleTransform = handleTransform
Vue.prototype.$saveRecentProduct = saveRecentProduct
Vue.prototype.$replaceHtml = replaceHtml
Vue.prototype.$replaceHtml2 = replaceHtml2
Vue.prototype.$getDate = getDate
Vue.prototype.$isMobile = isMobile
Vue.prototype.$urgentNoticeTime = urgentNoticeTime
Vue.prototype.$rendingProduct = rendingProduct
Vue.prototype.$rendExhibition = rendExhibition
Vue.prototype.$tracking = tracking
Vue.prototype.$trackingConversion = trackingConversion
Vue.prototype.$numbering = numbering
Vue.nextTick(() => {
  Vue.prototype.$getAppHtml = appHtml
  Vue.prototype.$flipBannerHeight = flipBannerHeight
})
Vue.prototype.$vueCarouselCycle = vueCarouselCycle
Vue.prototype.$criteo = criteo
Vue.prototype.$webVersion = webVersion
Vue.prototype.$getAdOnBannerKey = getAdOnBannerKey
Vue.prototype.$trackingPromotion = trackingPromotion
Vue.prototype.$truncate = truncate
Vue.prototype.$kakaoPixel = kakaoPixel
Vue.prototype.$sleep = sleep
