export const UserProfileData: UserProfileGroup = {
  name: '',
  nickName: '',
  email: '',
  sex: '',
  age: 0,
  userType: 0,
  phone: '',
  signUpDt: '',
}
export const LikeItemData: LikeItemGroup = {
  count: 0,
  searchProductList: [
    {
      no: 0,
      docId: '',
      docState: '',
      goodsNo: '',
      productNo: '',
      nameKr: '',
      nameEn: '',
      mallName: '',
      categoryCode: '',
      category1: '',
      categoryCode1: '',
      category2: '',
      categoryCode2: '',
      category3: '',
      categoryCode3: '',
      category4: '',
      categoryCode4: '',
      categoryNameFromMall: '',
      sex: '',
      age: '',
      price: 0,
      discountPrice: 0,
      discountRate: 0,
      mallScore: 0,
      mallIcon: '',
      mallId: '',
      productScore: 0,
      weekScore: 0,
      monthScore: 0,
      newarrivalScore: 0,
      latestScore: 0,
      mdOrder: 0,
      viewCnt: 0,
      saleCnt: 0,
      likeCnt: 0,
      terms: '',
      tags: '',
      showaTag1: '',
      showaTag2: '',
      colors: '',
      isLike: true,
      isBest: true,
      isNew: true,
      fullTerms: '',
      urlPc: '',
      urlMobile: '',
      imageUrl1: '',
      imageUrl2: '',
      isAvailable: true,
      imageInfo: {
        height: 0,
        width: 0,
        url: '',
      },
      updateTime: '',
      createTime: '',
    },
  ],
}

export const ShopItemData: ShopItemGroup = {
  count: 0,
  newShopItemList: [
    {
      shopId: '',
      shopName: '',
      icon: '',
      link: '',
      isNew: true,
    },
  ],
  recommendShopItemList: [
    {
      shopId: '',
      shopName: '',
      icon: '',
      link: '',
      isNew: true,
      isSubs: false,
    },
  ],
}

export const UserData: UserGroup = {
  userProfile: UserProfileData,
  likeItemGroup: LikeItemData,
  shopItemGroup: ShopItemData,
  lastNoticeDt: '',
}
