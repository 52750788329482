import { RootState } from '@/store/RootState'
import { Module } from 'vuex'
import { ActionTreeAdaptor } from '@/util'

const actions: ActionTreeAdaptor<
  PullToRefreshAction,
  PullToRefreshState,
  RootState
> = {
  async fetchTouchless({ commit }, payload) {
    commit('setTouchless', payload)
  },
  async fetchRefreshNoti({ commit }) {
    commit('setRefreshNoti')
  },
}

export const PullToRefreshStore: Module<PullToRefreshState, RootState> = {
  namespaced: true,
  state: {
    touchless: false,
    refreshNoti: false,
  },
  getters: {},
  mutations: {
    setTouchless(state: PullToRefreshState, payload: boolean) {
      state.touchless = payload
    },
    setRefreshNoti(state: PullToRefreshState) {
      state.refreshNoti = !state.refreshNoti
    },
  },
  actions,
}

export interface PullToRefreshState {
  touchless: boolean
  refreshNoti: boolean
}

export interface PullToRefreshGetters {}

export type PullToRefreshAction = {
  fetchTouchless: (payload: boolean) => Promise<void>
  fetchRefreshNoti: () => Promise<void>
}
