export enum CommonUrl {
  STORE = '/api/common/option/store',
  STORE_ALL = '/api/common/option/store/all',
  STORE_TOPSHOP = '/api/common/option/store/topshop',
  STORE_NEWIN_NEW = '/api/common/option/store/newin/new',
  STORE_NEWIN_TOTAL = '/api/common/option/store/newin/total',
  IMAGE = '/api/common/image/',
}

export enum CategoryUrl {
  CATEGORY_MAIN = '/api/v2.1/display/home/category', // Category 전체목록
  CATEGORY_BESTSELLER = '/api/v2/display/best-seller/category', // Category BEST SELLER
  CATEFORY_NEWARRIVALS = '/api/v2/display/new-arrivals/category', // Category NEW ARRIVALS
  CATEGORY_TOPSHTOP = '/api/v2/display/top-shop/category', // Category TOP SHOP 현제 메인 topShop 호출
  CATEGORY_PLANEX_LIST = '/api/v2/display/planex/category/title', // 기획전 타이틀 (타이틀)
  CATEGORY_PLANEX_DETAIL = '/api/v2/display/planex/category/detail', // 기획전 타이틀별 상세
  CATEGORY_PRODUCT = '/api/v2/display/home/category/detail', // Category 탭 상품리스트
  SEASON_PRODUCTS = '/api/v2/display/season/category', // Category 시즌 카테고리 상품목록
}

export enum DisplayUrl {
  MAIN_DISPLAY = '/api/v2/display/home', // 메인화면 디스플레이 전체 구성
  MAIN_DISPLAY_TAB_MENU = '/api/v2/display/home/tab-menu', // 메인화면 탭 메뉴
  MAIN_DISPLAY_BEST_SELLER = '/api/v2/display/home/best-seller', // 메인화면 best seller
  MAIN_DISPLAY_MY_COLLECTION = '/api/v2/display/home/my-collection', // 메인화면 my collection
  MAIN_DISPLAY_NEW_ARRIVALS = '/api/v2/display/home/new-arrivals', // 메인화면 new arrivals
  MAIN_DISPLAY_TOP_SHOP = '/api/v2/display/home/top-shop', // 메인화면 top shop
  MAIN_DISPLAY_TREND = '/api/v2/display/home/trend', // 메인화면 trend
  MAIN_DISPLAY_PLANEX = '/api/v2/display/home/planex', // 메인화면 기획전
  MAIN_DISPLAY_ALL_ITEMS = '/api/v2/display/home/all-items', // 메인화면 all items
  SALE_DISPLAY = '/api/v2/display/sale/tab', // 세일 디스플레이
  ADON_BANNER = '/api/v3/display/adon/banner',
  STORE_DISPLAY = '/api/v3/display/shop/tab', // 메인 세일 탭 mall list
  STORE_TOTAL_DISPLAY = '/api/v3/display/shop/total', // 메인 세일 탭 total list
  MD_PICK_DISPLAY = '/api/v2/display/md-pick/tab', // Md Pick 디스플레이,
  All_ITEMS_DISPLAY = '/api/v2/display/all-items/tab', // all items 디스플레이
  BESTSELLER_PRODUCT = '/api/v2/display/best-seller/product', // Best Seller 버튼별 상품목록
  NEWARRIVALS_PRODUCT = '/api/v2/display/new-arrivals/product', // New Arrivals 버튼별 상품목록
  TREND = '/api/v2/display/trend', // Trend 버튼 목록
  TREND_HOTRANK_PRODUCT = '/api/v2/display/trend/hot-rank/product', // Trend/hotrank 랭크기반 지금핫한 버튼별 상품목록
  TREND_THESE_DAY_RISE_PRODUCT = '/api/v2/display/trend/theseday-rise/product', // Trend/thesedayrise 요즘뜨는 버튼별 상품목록
  ADON_BANNER_VIEW = '/api/v2/event/adon/banner/view', // 메인 adon banner
  ADON_BANNER_CLICK = '/api/v2/event/adon/banner/click', // 메인 adon banner
  MULTI_PROMOTION_PLANX = '/api/v2/display/planex/multi', //멀티몰 기획전 v2
  MULTI_PROMOTION_PLANEX = '/api/v3/display/planex/multi-shop', //멀티몰 기획전 v3
  SINGLE_PROMOTION_PLANEX = '/api/v2/display/planex/single', //단일몰 기획전 v2
  SINGLE_PROMOTION_PLANEX2 = '/api/v3/display/planex/single-shop', //단일몰 기획전 v3
  SHOPPING_LIVE = '/api/v3/display/shopping-live', //쇼핑 라이브 API 호출 (API 키 미노출 처리)
  SHOPPING_LIVE_SHOP_INFO = '/api/v3/display/shop/shopping-live', //쇼핑 라이브 스토어 정보
}

export enum EventUrl {
  IS_LIKE = '/api/Event/event/islike', // Authtoken , 상품번호들(attrangs1_75307, buyshu1_34415)
  IS_SUBS = '/api/Event/event/isSubs', // 구독여부를 알려준다 인자값 : Authtoken , 몰번호들(1, 4, 5)
  ADON = '/api/Event/event/adon', //
}

export enum EventPageUrl {
  DISPLAY_EVENT = '/api/v2/display/event',
  PROMOTION_LIST = '/api/v3/display/planex/list',
  CHECKIN_LIST = '/api/v3/display/event/check-in', // 체크인 이벤트
  CHECK_IN_NO = '/api/v3/display/event/check-in/no', // 마이페이지 체크인 이벤트 번호 전달
  CHECK_PREV_LIST = '/api/v3/display/event/check-in/previous', // 체크인 이벤트 이전 회차 정보 전달
  EVENT_LIST = '/api/v3/display/event/list',
  EVENT_W_APP = '/api/v3/display/event/w-app',
}

export enum HealthUrl {
  CHECK = '/api/Health/check', // 기본적인 헬스 체크 Endpoint - 모니터링 툴에서 사용
  CHECK_INFRA = '/api/Health/check-infra', // 확장 헬스 체크 Endpoint - 의존 인프라 간략하게 접근 Test : 개발초기 수동으로만 사용
  EVENT_USER_EVENT = '/api/Health/event/userEvent', //
}

export enum MyPageUrl {
  MY_PAGE = '/api/v2/user/mypage', //마이페이지 정보 (사용자프로필, 구독몰, 찜상품 목록)
  MY_PAGE_LAST_NOTICE = '/api/v3/notice/last',
}

export enum NoticeUrl {
  NOTICE_VIEW = '/api/v2/notice/view', //공지사항 목록
  NOTICE_VIEW_DETAIL = '/api/v2/notice/view/detail', //공지사항 상세
  NOTICE_LAST_DATE = 'api/v2/notice/last-datetime', //공지사항 마지막 일자
}

export enum PurchaseListUrl {
  ORDER_NO = '/api/PurchaseList/orderno', //
  ORDER = '/api/PurchaseList/order', //
}

export enum TestDisplayUrl {
  INDENTIFICATION_GROUP = '/api/TestDisplay/identificationgroup', //
  INDENTIFICATION_GROUP_MALL = '/api/TestDisplay/identificationgroup/mall', //
  RECOMMENDATION_MALL = '/api/TestDisplay/recommendationmall', //
  SALE_BEST = '/api/TestDisplay/saleBest', //
}

export enum AccountUrl {
  APP_PROFILE = '/api/v2/app/profile',
  APP_PROFILE_ACCESS = '/api/AppProfile/app/access',
  APP_PROFILE_PUSH = '/api/AppProfile/app/push',
  APP_PROFILE_AD = '/api/AppProfile/app/ad',
  APP_PROFILE_AD_ID = '/api/AppProfile/app/adid',
  ONE_TIME_AUTH_SEND_ONE_TIME_PASSWORD = '/api/v2/otp/sms',
  ONE_TIME_AUTH_ACCESS_TOKEN_FOR_REGISTER_BY_PHONE = '/api/v2/otp/sms/confirm',
  ONE_TIME_AUTH_SEND_EMAIL_LINK_FOR_CHA = '/api/OneTimeAuth/accessTokenForRegisterByPhoned',
  ONE_TIME_AUTH_SEND_EMAIL_LINK_FOR_CHANGE = '/api/OneTimeAuth/sendEmailLinkForChaange',
  SOCIAL_AUTHORIZATION = '/api/Social/authorization',
  SOCIAL_REGISTER = '/api/Social/register',
  SOCIAL_DISCONNECT = '/api/Social/disConnect',
  SOCIAL_LOGIN_APPLE = '/api/Social/login/apple',
  SOCIAL_LOGIN_APPLES = '/api/Social/login/apples',
  USERS_AUTHENTICATE = '/api/Users/authenticate',
  USERS_REFRESH_AUTHENTICATE = '/api/v2/token/jwt/refresh',
  USERS_REGISTER = '/api/Users/register',
  USERS_PROFILE = '/api/v2/user/profile',
  USERS_PROFILE_OPTIONAL = '/api/Users/profileOptional',
  USERS_SOCIAL_CONNECTION_STATE = '/api/Users/socialConnectionState',
  USERS_PHONE_NUMBER = '/api/Users/phoneNumber',
  USERS_EMAIL = '/api/v2/user/email',
  USERS_PASSWORD = '/api/Users/password',
  USERS_PASSWORD_CHANGE = '/api/Users/password/change',
  USERS_WITHDRAWAL = '/api/v2/social/withdrawal',
  SOCIAL_SIGNIN_AND_UP = '/api/v2/social/signin-and-up',
  APP_SETTING = '/api/v2/app/settings',
  USER_LOGOUT = '/api/v2/token/jwt',
  APP_AD = '/api/v2/app/ad', // 앱 푸쉬 권한 동의
  APPLE_USER_INFORMATION = '/api/v2/social/apple/user/info',
  APP_AD_CONSENT = '/api/v2/user/ad-consent',
  USER_SETTING = '/api/v2/user/settings',
}

export enum ImageServiceUrl {
  IMAGE = '/api/image/',
  IMAGE_KEYS = '/api/image/keys',
}

export enum SearchUrl {
  EVENT_USER_EVENT = '/api/Event/userEvent',
  INDEX_LOAD_PRODUCT = '/api/Index/loadProduct',
  INDEX_LOAD_EVENT = '/api/Index/loadEvent',
  INDEX_LOAD_EVENT_Graph = '/api/Index/loadEventGraph',
  INDEX_API_CHECK = '/api/Index/apicheck',
  INDEX_REMOVE_PRODUCT = '/api/Index/removeProduct',
  PRODUCT = '/api/Product',
  PRODUCT_ALL_PRODUCT = '/api/Product/AllProduct',
  RECOMMEND_KEYWORD = '/api/v1/Recommend/keyword',
  RECOMMEND_IDENTI_GROUP = '/api/v1/Recommend/identiGroup',
  RECOMMEND_MAIN_VIEW = '/api/v1/Recommend/mainView',
  SEARCH = '/api/Search/search',
  SEARCH_RECOMMENDATION = '/api/Search/search-recommendation',
  SEARCH_RECOMMENDATION_MALL = '/api/Search/search-recommendation-mall',
  SEARCH_INTERNAL = '/api/Search/search-internal',
  SEARCH_AUTO_KEYWORD = '/api/Search/autoKeyword',
  SEARCH_RANK = '/api/Search/rank',
  SEARCH_DSL = '/api/Search/search-dsl',
}

// enum MainManagementUrl {}
;``
// enum MallUrl {}

// enum SearchUrl {}ㄴㄷ
// enum TabCategory {}

// enum TabManagementUrl {}

// enum AdminUrl {}

// enum AdminUrl {}

export enum ShopUrl {
  SHOP_INFO = '/api/v2/display/shop/info',
  SHOP_TARGET = '/api/v2/display/shop/target',
  SHOP_INFO_V3 = '/api/v3/display/shop/info',
  SHOP_INFO_RENEWAL_PRODUCT = '/api/v3/display/shop/product',
}

export enum UserUrl {
  SUBSCRIBE = '/api/v2/user/subscribe',
  SUBSCRIBE_CHECK = '/api/v2/user/subscribe/check',
  SUBSCRIBE_FOR_USER_EVENT = '/api/v2.1/user/subscribe', // 구독 유저 이벤트 데이터 전송
  LIKE_ITEM = '/api/v2/user/like', // 찜상품 등록
  LIKE_ITEM_CHECK = '/api/v2/user/like/check', // 찜 상품 유무 확인
  LIKE_ITEM_FOR_USER_EVENT = '/api/v2.1/user/like', // 찜 상품 등록 유저 이벤트 데이터 전송
  SUBSCRIBE_PIN = '/api/v2/user/subscribe/pin',
  RECENTLY_VIEW = '/api/v2/user/recently-viewed-product',
}

export enum DibsUrl {
  DIBS_DEFAULT_FOLDER = '/api/v2/user/default-folder',
  DIBS_TAB_CATEGORY = '/api/v2/user/default-folder/category',
  DIBS_PRODUCT = '/api/v2/user/like',
}

export enum ProductUrl {
  SEARCH_PRODUCT = '/api/v2/display/product',
  VIEW_COUNT = '/api/v2.1/display/product/view/count', // 상품 뷰 카운트 증가 , 유저 이벤트 데이터 전송
  SEARCH_PRODUCT_ARRAY = '/api/v2.1/display/product', // 상품 조회, 상품 no array[string] payload 로 받음
}

export enum NaverUrl {
  LIST_ALL = '/v2/broadcasts/external-service-broadcasts', // 모든 방송 조회(업체별 방송목록 조회)
  LIST_ONE = '/v1/broadcasts/single', // 모든 방송 조회
  PLAY_COUNT = '/v1/broadcasts/play-count/multi', // 방송 조회수 다건 조회
  PLAY_INFO_REPLAY = '/v1/play-info/replay/multi', // 다시보기 방송 재생 url 다건 조회
  PLAY_INFO_ONAIR = '/v1/play-info/onair/multi', // onair 방송 재생 url 다건 조회
}
// https://beta-external-api.io.naver.com/v2/broadcasts/external-service-broadcasts
