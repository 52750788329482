import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { accountApi } from '../../plugins/axios'
import { AccountUrl } from '../../plugins/enum'

export const PostAuthenticate = async (
  data: {
    loginValue?: string
    password?: string
  },
  params?: AppVersionParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.post<any>(AccountUrl.USERS_AUTHENTICATE, data, {
    ...options,
    params,
  })

export const PutRefreshAuthenticate = async (
  params?: AppAccessParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.put<any>(AccountUrl.USERS_REFRESH_AUTHENTICATE, null, {
    ...options,
    params,
  })

export const PostRegister = async (
  data: UserRegister,
  params?: AppVersionParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.post<any>(AccountUrl.USERS_REGISTER, data, {
    ...options,
    params,
  })

export const GetUserProfile = async (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserViewInfo>> =>
  await accountApi.get<UserViewInfo>(AccountUrl.USERS_PROFILE, options)

export const PutProfileOptional = async (
  data: {
    sex?: string
    age?: number
  },
  params?: AppAccessParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.put<any>(AccountUrl.USERS_PROFILE_OPTIONAL, data, {
    ...options,
    params,
  })

export const GetSocialConnectionState = async (
  params?: AppVersionParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.post<any>(AccountUrl.USERS_SOCIAL_CONNECTION_STATE, {
    ...options,
    params,
  })

export const GetPhoneNumber = async (
  params?: AppVersionParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.post<any>(AccountUrl.USERS_PHONE_NUMBER, {
    ...options,
    params,
  })

export const PutPhoneNumber = async (
  data: {
    phoneNumber?: string
  },
  params?: AppAccessParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.put<any>(AccountUrl.USERS_PHONE_NUMBER, data, {
    ...options,
    params,
  })

export const GetEmail = async (
  params?: { phoneNumber: string },
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.get<any>(AccountUrl.USERS_EMAIL, {
    ...options,
    params,
  })

export const PutEmail = async (
  data: {
    email?: string
    userType?: number
  },
  params?: AppAccessParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.put<any>(AccountUrl.USERS_EMAIL, data, {
    ...options,
    params,
  })

export const PutPassword = async (
  data: {
    passwordOld?: string
    passwordNew?: string
  },
  params?: AppAccessParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.put<any>(AccountUrl.USERS_PASSWORD, data, {
    ...options,
    params,
  })

export const PutPasswordChange = async (
  data: {
    passwordNew?: string
    phoneNumber?: string
  },
  params?: AppAccessParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.put<any>(AccountUrl.USERS_PASSWORD_CHANGE, data, {
    ...options,
    params,
  })

export const PutWithdrawal = async (
  data: { userType?: number; reason?: string },
  params?: AppAccessParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserWithdrawal>> =>
  await accountApi.put<UserWithdrawal>(AccountUrl.USERS_WITHDRAWAL, data, {
    ...options,
    params,
  })

export const PutAppleUserInformation = async (
  data: { gender?: string; birthDay?: string; birthYear?: string },
  params?: AppleUserInformation,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AppleUserInformation>> =>
  await accountApi.put<AppleUserInformation>(
    AccountUrl.APPLE_USER_INFORMATION,
    data,
    {
      ...options,
      params,
    }
  )
export const GetSettings = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserAppSettingInfo>> =>
  await accountApi.get<UserAppSettingInfo>(AccountUrl.APP_SETTING, {
    ...options,
    params,
  })

export const SetUserLogout = async (
  headers?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<any>> =>
  await accountApi.delete<any>(AccountUrl.USER_LOGOUT, {
    ...options,
    headers,
  })

export const PutAd = async (
  data: { adYN: 'Y' | 'N' },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserAppSettingInfo>> =>
  await accountApi.put<UserAppSettingInfo>(AccountUrl.APP_AD, data, options)

export const PutAdConsent = async (
  data: {
    smsYN?: 'Y' | 'N'
    emailYN?: 'Y' | 'N'
  },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> =>
  await accountApi.put<void>(AccountUrl.APP_AD_CONSENT, data, options)

export const GetUserSettings = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserSettingInfo>> =>
  await accountApi.get<UserSettingInfo>(AccountUrl.USER_SETTING, {
    ...options,
    params,
  })

export default {
  PostAuthenticate,
  PutRefreshAuthenticate,
  PostRegister,
  PutProfileOptional,
  GetSocialConnectionState,
  GetPhoneNumber,
  PutPhoneNumber,
  GetEmail,
  PutEmail,
  PutPassword,
  PutPasswordChange,
  PutWithdrawal,
  GetSettings,
  PutAppleUserInformation,
}
