import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { SearchUrl } from '@/plugins/enum'
import { searchApi } from '@/plugins/axios'
import store from '@/store'
import { GetShopProductList } from '@/api/displayAPI/ShopAPI'

export const GetSearch = async (
  params?: SearchParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SearchResultResponse>> =>
  searchApi.get<SearchResultResponse>(
    `${SearchUrl.SEARCH}?tackingUser.fingerPrint=${localStorage.getItem(
      'uid'
    )}`,
    {
      ...options,
      params,
    }
  )

function userEventData(keyword: string): UserEventData {
  return {
    uuid: store?.state?.DeviceStore?.deviceInfo?.uid,
    appVersion: store?.state?.DeviceStore?.deviceInfo?.appVer,
    platform: store.state.DeviceStore.deviceInfo?.os,
    strData1: keyword,
  }
}

export const GetSearchEncode = async (
  params?: SearchParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SearchResultResponse>> => {
  const encodedKeyword = encodeURIComponent(params?.keyword ?? '')

  const query = new URLSearchParams()

  for (const [key, value] of Object.entries(params ?? {})) {
    query.set(key, value)
  }

  query.append('tackingUser.fingerPrint', localStorage.getItem('uid') ?? '')
  query.append('keyword', encodedKeyword)
  query.append('userEventData', JSON.stringify(userEventData(encodedKeyword)))

  return searchApi.get<SearchResultResponse>(SearchUrl.SEARCH, {
    params: query,
    ...options,
  })
}

export const GetSearchRecommendation = async (
  params?: { keyword: string },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SearchRecommend>> =>
  searchApi.get<SearchRecommend>(
    `${
      SearchUrl.SEARCH_RECOMMENDATION
    }?tackingUser.fingerPrint=${localStorage.getItem('uid')}`,
    {
      ...options,
      params,
    }
  )

export const GetSearchRecommendationEncode = async (
  params: {
    keyword: string
    ['paging.limit']?: number
    ['sort.option']?: string
  },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SearchRecommend>> =>
  searchApi.get<SearchRecommend>(
    `${SearchUrl.SEARCH_RECOMMENDATION}?paging.limit=${
      params?.['paging.limit']
    }&sort.option=${params?.['sort.option']}&keyword=${encodeURIComponent(
      params?.keyword
    )}`,
    options
  )

export const GetSearchRecommendationMall = async (
  params?: { mallNames: string; page?: number; limit?: number },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SearchRecommend>> =>
  searchApi.get<SearchRecommend>(
    `${
      SearchUrl.SEARCH_RECOMMENDATION_MALL
    }?tackingUser.fingerPrint=${localStorage.getItem('uid')}`,
    {
      ...options,
      params,
    }
  )

export const PostSearchInternal = async (
  data: SearchInternalBody,
  params?: {
    token: string
  },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SearchRecommend>> =>
  searchApi.post<SearchRecommend>(
    `${
      SearchUrl.SEARCH_INTERNAL
    }?tackingUser.fingerPrint=${localStorage.getItem('uid')}`,
    data,
    {
      ...options,
      params,
    }
  )

export const GetSearchAutoKeywordEncode = async (
  params: { keyword: string; limit: number },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SearchAutoKeywordResponse>> =>
  searchApi.get<SearchAutoKeywordResponse>(
    `${SearchUrl.SEARCH_AUTO_KEYWORD}?keyword=${params.keyword}&limit=${
      params.limit
    }&tackingUser.fingerPrint=${localStorage.getItem('uid')}`,
    options
  )

export const GetSearchRank = async (
  params?: { fromDateTime: string; toDateTime: string; size: number },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SearchRankResponse>> =>
  searchApi.get<SearchRankResponse>(
    `${SearchUrl.SEARCH_RANK}?tackingUser.fingerPrint=${localStorage.getItem(
      'uid'
    )}`,
    {
      ...options,
      params,
    }
  )

export default {
  GetSearch,
  GetSearchRecommendation,
  GetSearchRecommendationMall,
  PostSearchInternal,
  GetSearchRank,
}
