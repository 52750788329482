
import Vue from 'vue'
import Button from '../buttons/Button.vue'
export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Confirm',
  components: { Button },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    subContent: {
      type: String,
      default: '',
    },
    canceText: {
      type: String,
      default: '취소',
    },
    confirmText: {
      type: String,
      default: '확인',
    },
  },
  data() {
    return {}
  },
  methods: {
    close(yn: boolean) {
      if (yn) {
        this.$emit('onConfirm')
      } else {
        this.$emit('onClose')
      }
    },
  },
})

interface Data {}
interface Methods {
  close(yn: boolean): void
}
interface Computed {}
interface Props {
  value: boolean
  title: string
  content: string
  subContent: string
  canceText: string
  confirmText: string
}
