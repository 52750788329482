
import Vue from 'vue'
import { AnimationItem } from 'lottie-web'

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Lottie',
  props: {
    src: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    options: {
      type: Object,
      default: function () {
        return {
          autoplay: true,
          loop: true,
          speed: 0,
        }
      },
    },
    height: {
      type: String,
      default: '100%',
    },
    width: {
      type: String,
      default: '100%',
    },
    isLike: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      anim: null, //beforeDestroy 에서 명시적으로 destroy() 호출 필요
      style: {
        width: this.width ? `${this.width}` : '100%',
        height: this.height ? `${this.height}` : '100%',
        overflow: 'hidden',
        margin: '0 auto',
      },
    }
  },
  async mounted() {
    this.lottie = await import('lottie-web').then((module) => module.default)
    this.mountLottie()
    this.$emit('animCreated', this.anim) // 부모 컴포넌트에서 애니메이션을 조작하고 싶을 경우를 위해 전달
    if (this.isLike === true) {
      this.anim?.setSpeed(1)
      this.anim?.goToAndStop(this.anim?.totalFrames, true)
    }
  },
  /**
   * http://airbnb.io/lottie/#/web?id=usage
   */
  methods: {
    mountLottie() {
      this.anim = this.lottie.loadAnimation({
        container: this.$refs.lavContainer as HTMLElement, // 로티 애니메이션이 실행될 컨테이너
        renderer: 'svg', // 로티 애니메이션 렌더 타입
        autoplay: this.options.autoplay, // 최초 실행시 자동으로 애니메이션이 재생될지 확인
        loop: this.options.loop, // 한 번 재생후 반복해서 재생 여부
        animationData: JSON.parse(JSON.stringify(this.src)), // lottie 용 json 애니메이션 데이터 (filePath 아님)
        rendererSettings: {
          progressiveLoad: false,
        },
      })
    },
  },
})
interface Data {
  anim?: AnimationItem | null
  style: object
  lottie?: any
}
interface Methods {
  mountLottie(): void
}
interface Computed {}
interface Props {
  src: {}
  options: { autoplay: boolean; loop: boolean; speed: number }
  width: string
  height: string
  isLike: boolean
}
