// import Vue from 'vue'
import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
} from 'axios'
import store from '../store'
import { PutRefreshAuthenticate } from '../api/accountAPI/UsersAPI'
import { logout } from './util/logout'
import { setTokenSubWebView } from './util/appInterface'

const MAX_RETRY_COUNT = 2
interface CommonHeaderProperties extends HeadersDefaults {
  Authorization: string
  socialToken: string
  phone: string
  uid: string
}
interface RefreshAuthenticate extends HeadersDefaults {
  accessToken: string
  refreshToken: string
}

export const displayApi = axios.create({
  headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
  baseURL: process.env.VUE_APP_DISPLAY_API,
  // baseURL: 'http://display.alpha.showa.kr',
})
export const searchApi = axios.create({
  headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
  baseURL: process.env.VUE_APP_SEARCH_API,
  // baseURL: 'http://display.alpha.showa.kr',
})
export const accountApi = axios.create({
  headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
  baseURL: process.env.VUE_APP_ACCOUNT_API,
  // baseURL: 'https://account-api-alpha2.showa.kr/',
})
export const imageApi = axios.create({
  headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
  baseURL: process.env.VUE_APP_IMAGE_API,
  // baseURL: 'http://display.alpha.showa.kr',
})

// rqeust interceptor response handler
const handleRequest = (
  res: AxiosRequestConfig<any>
): AxiosRequestConfig<any> => {
  // api 요청 시, network 상태 값 체크
  if (!store.state.DialogStore.networkDialog && !window.navigator.onLine) {
    store.dispatch('DialogStore/fetchNetworkDialog', true)
  }
  return res
}

// rqeust interceptor error handler
const handleRequestError = (err: any) => {
  return err
}

const handleResponse = (res: AxiosResponse<any>) => {
  return res
}

const handleResponseError = async (error: any) => {
  const { data, config } = error.response
  config.retryCount = config.retryCount ?? 1

  if (data && data.error_code === 110) {
    const accessToken = String(localStorage.getItem('accessToken'))
    const refreshToken = String(localStorage.getItem('refreshToken'))
    clearAccessToken()
    SetAccessTokenRefreshToken(accessToken, refreshToken)
    try {
      const result = await PutRefreshAuthenticate()
      if (result.data) {
        localStorage.setItem('accessToken', result.data.accessToken)
        localStorage.setItem('refreshToken', result.data.refreshToken)
        setAccessToken(result.data.accessToken)
        setTokenSubWebView(result.data.accessToken)
        if (config.retryCount < MAX_RETRY_COUNT) {
          config.headers = {
            ...config.headers,
            Authorization: result.data.accessToken,
          }
          config.retryCount += 1
          return axios.request(config)
        }
      }
    } catch (error: any) {
      if (error.response.data.error_code === 131) {
        logout()
        return store.dispatch('DialogStore/fetchTokenAlert', true)
      }
    } finally {
      ClearAccessTokenRefreshToken()
    }
  }

  if (data && data.error_code === 131) {
    logout()
    return store.dispatch('DialogStore/fetchTokenAlert', true)
  }

  if (error && error.response && error.response.data)
    return Promise.reject(error.response.data)

  return Promise.reject(error)
}

// request interceptors
displayApi.interceptors.request.use(handleRequest, handleRequestError)
searchApi.interceptors.request.use(handleRequest, handleRequestError)
accountApi.interceptors.request.use(handleRequest, handleRequestError)
imageApi.interceptors.request.use(handleRequest, handleRequestError)
// response interceptors
displayApi.interceptors.response.use(handleResponse, handleResponseError)
searchApi.interceptors.response.use(handleResponse, handleResponseError)
accountApi.interceptors.response.use(handleResponse, handleResponseError)
imageApi.interceptors.response.use(handleResponse, handleResponseError)

export const setSocialAccessToken = (accessToken: string) => {
  accountApi.defaults.headers = {
    ...accountApi.defaults.headers,
    socialToken: accessToken,
  } as CommonHeaderProperties
}
export const setOneTimeAuthPhoneNumber = (phone: string) => {
  accountApi.defaults.headers = {
    ...accountApi.defaults.headers,
    phone: phone,
  } as CommonHeaderProperties
}

export const clearSocialAccessToken = () => {
  accountApi.defaults.headers = {
    ...accountApi.defaults.headers,
    socialToken: '',
  } as CommonHeaderProperties
}

export const setAccessToken = (accessToekn: string) => {
  displayApi.defaults.headers = {
    ...displayApi.defaults.headers,
    Authorization: accessToekn,
  } as CommonHeaderProperties
  searchApi.defaults.headers = {
    ...searchApi.defaults.headers,
    Authorization: accessToekn,
  } as CommonHeaderProperties
  accountApi.defaults.headers = {
    ...accountApi.defaults.headers,
    Authorization: accessToekn,
  } as CommonHeaderProperties
  imageApi.defaults.headers = {
    ...imageApi.defaults.headers,
    Authorization: accessToekn,
  } as CommonHeaderProperties
}

export const clearAccessToken = () => {
  displayApi.defaults.headers = {
    ...displayApi.defaults.headers,
    Authorization: '',
  } as CommonHeaderProperties
  searchApi.defaults.headers = {
    ...searchApi.defaults.headers,
    Authorization: '',
  } as CommonHeaderProperties
  accountApi.defaults.headers = {
    ...accountApi.defaults.headers,
    Authorization: '',
  } as CommonHeaderProperties
  imageApi.defaults.headers = {
    ...imageApi.defaults.headers,
    Authorization: '',
  } as CommonHeaderProperties
}

export const setUid = (uid: string) => {
  displayApi.defaults.headers = {
    ...displayApi.defaults.headers,
    uid: uid,
  } as CommonHeaderProperties
  searchApi.defaults.headers = {
    ...searchApi.defaults.headers,
    uid: uid,
  } as CommonHeaderProperties
  accountApi.defaults.headers = {
    ...accountApi.defaults.headers,
    uid: uid,
  } as CommonHeaderProperties
  imageApi.defaults.headers = {
    ...imageApi.defaults.headers,
    uid: uid,
  } as CommonHeaderProperties
}

const SetAccessTokenRefreshToken = (
  accessToken: string,
  refreshToken: string
) => {
  displayApi.defaults.headers = {
    ...displayApi.defaults.headers,
    accessToken: accessToken,
    refreshToken: refreshToken,
  } as RefreshAuthenticate
  searchApi.defaults.headers = {
    ...searchApi.defaults.headers,
    accessToken: accessToken,
    refreshToken: refreshToken,
  } as RefreshAuthenticate
  accountApi.defaults.headers = {
    ...accountApi.defaults.headers,
    accessToken: accessToken,
    refreshToken: refreshToken,
  } as RefreshAuthenticate
  imageApi.defaults.headers = {
    ...imageApi.defaults.headers,
    accessToken: accessToken,
    refreshToken: refreshToken,
  } as RefreshAuthenticate
}

const ClearAccessTokenRefreshToken = () => {
  displayApi.defaults.headers = {
    ...displayApi.defaults.headers,
    accessToken: '',
    refreshToken: '',
  } as RefreshAuthenticate
  searchApi.defaults.headers = {
    ...searchApi.defaults.headers,
    accessToken: '',
    refreshToken: '',
  } as RefreshAuthenticate
  accountApi.defaults.headers = {
    ...accountApi.defaults.headers,
    accessToken: '',
    refreshToken: '',
  } as RefreshAuthenticate
  imageApi.defaults.headers = {
    ...imageApi.defaults.headers,
    accessToken: '',
    refreshToken: '',
  } as RefreshAuthenticate
}
