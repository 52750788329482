import { RootState } from '@/store/RootState'
import { Module } from 'vuex'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'

const actions: ActionTreeAdaptor<DialogAction, DialogState, RootState> = {
  async fetchLoginDialog({ commit }, { yn = false }) {
    commit('setLoginDialog', yn)
  },
  async fetchNetworkDialog({ commit }, payload) {
    commit('setNetworkDialog', payload)
  },
  async fetchTokenAlert({ commit }, payload) {
    commit('setAlert', payload)
  },
}

const getters: GetterTreeAdaptor<Getters, DialogState, RootState> = {
  getLoginDialog(state: DialogState) {
    return state.loginDialog
  },
}

export const DialogStore: Module<DialogState, RootState> = {
  namespaced: true,
  state: {
    loginDialog: false,
    networkDialog: false,
    showTokenAlert: false,
  },
  mutations: {
    setLoginDialog(state: DialogState, yn: boolean) {
      state.loginDialog = yn
    },
    setNetworkDialog(state: DialogState, yn: boolean) {
      state.networkDialog = yn
    },
    setAlert(state: DialogState, yn: boolean) {
      state.showTokenAlert = yn
    },
  },
  actions,
  getters,
}
export interface DialogState {
  loginDialog: boolean
  networkDialog: boolean
  showTokenAlert: boolean
}

export type DialogAction = {
  fetchLoginDialog: (payload: { yn: boolean }) => Promise<void>
  fetchNetworkDialog: (payload: boolean) => Promise<void>
  fetchTokenAlert: (payload: boolean) => Promise<void>
}

export interface Getters {
  getLoginDialog: boolean
}
