import { RootState } from '@/store/RootState'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import { Module } from 'vuex'
import {
  GetBroadCasts,
  GetBroadCastsPlayCount,
  GetOnairBroadCastsVideoUrl,
  GetReplayBroadCastsVideoUrl,
  GetShopInfoByMallName,
} from '@/api/naverAPI/LiveAPI'
import { AxiosResponse } from 'axios'
import lodash from 'lodash'

const getters: GetterTreeAdaptor<NLiveGetter, NLiveState, RootState> = {
  recommend(state: NLiveState) {
    //정렬 기준에 따라서 쇼핑라이브 추출
    const firstSort = lodash
      .chain(state.broadcasts)
      .sort((a, b) => {
        /**
         * 방송 상태  ①라이브 ②라이브 예정 ③다시보기 (방송 상태가 같을 경우, 라이브 예정일시가 빠른 순으로 출력)
         */
        const aDate = new Date(a.expectedStartDate)
        const bDate = new Date(b.expectedStartDate)
        return a.order - b.order || bDate.getTime() - aDate.getTime()
      })
      .slice(0, 10) //최대개수 10개 제한
      .map((value) => {
        const obj = { ...value }
        obj.cardStyle = 'BIG'
        return obj
      })
      .value()

    //다시보기만 셔플
    const endShuffle: LiveBroadcastItem[] = lodash
      .chain(lodash.clone(firstSort))
      .filter((item) => item.status === 'END')
      .shuffle()
      .value()

    //라이브 + 라이브 예정 + 셔플된 다시보기
    return firstSort
      .filter((item: LiveBroadcastItem) => item.status !== 'END')
      .concat(endShuffle)
  },
  reply(state: NLiveState) {
    return state.broadcasts
      .filter((item) => item.status === 'END')
      .map((value) => {
        const obj = { ...value }
        obj.cardStyle = 'NORMAL'
        return obj
      })
      .sort((a, b) => {
        const aDate = new Date(a.expectedStartDate)
        const bDate = new Date(b.expectedStartDate)
        return bDate.getTime() - aDate.getTime()
      })
  },
}
const state: NLiveState = {
  broadcastsPaging: {} as BroadcastsPaging,
  broadcasts: [],
  swipeBlock: false,
}

const mutations = {
  setBroadcastsPaging(state: NLiveState, paging: BroadcastsPaging) {
    state.broadcastsPaging = paging
  },
  setBroadcasts(state: NLiveState, broadcastItems: LiveBroadcastItem[]) {
    state.broadcasts = broadcastItems
  },
  setSwipe(state: NLiveState, payload: { yn: boolean }) {
    state.swipeBlock = payload.yn
  },
}

const actions: ActionTreeAdaptor<NLiveAction, NLiveState, RootState> = {
  async fetchBroadCasts(
    { commit },
    payload?: { pageNum: number; pageSize: number }
  ) {
    GetBroadCasts(payload)
      .then(async (res: AxiosResponse<LiveBroadcasts>) => {
        //페이징 데이터만 따로 조달해서 페이징을 유연하게 구현할 수 있게함
        const pagingPayload: BroadcastsPaging = {
          pageNum: res.data.pageNum,
          pageSize: res.data.pageSize,
          totalCount: res.data.totalCount,
          totalPage: res.data.totalPage,
        }

        //페이징을 고려하여 내려받은 pageNum 이 클때만 이전 리스트에 추가
        const needAddBroadcasts =
          (state.broadcastsPaging?.pageNum ?? 1) < res.data.pageNum

        let result: LiveBroadcastItem[]
        try {
          result = needAddBroadcasts
            ? state.broadcasts.concat(
                await createShoppingLiveData(res.data.items)
              )
            : await createShoppingLiveData(res.data.items)
        } catch (e) {
          console.error('쇼핑라이브 데이터 매핑 에러', e)
          result = []
        }

        commit('setBroadcastsPaging', pagingPayload)
        commit('setBroadcasts', result)
      })
      .catch((reason: any) => {
        console.error('fetchBroadCasts', reason)
        commit('setBroadcasts', [])
      })
  },
  async fetchSwipe({ commit }, payload) {
    commit('setSwipe', payload)
  },
}

const createShoppingLiveData = async (items: LiveBroadcastItem[]) => {
  const mallNameMap = new Map<string, LiveBroadcastsShopInfo>()

  const ids: number[] = []
  const replayIds: number[] = []
  const onAirIds: number[] = []
  const mallNames = new Set<string>()
  for (const item of items) {
    ids.push(item.id)

    //다시보기 비디오 조회용 아이디
    if (item.status === 'END') {
      replayIds.push(item.id)
    }

    //onair 비디오 조회용 아이디
    if (item.status === 'ONAIR') {
      onAirIds.push(item.id)
    }

    mallNames.add(item.shoppingProducts[0].mallName)
  }

  //조회된 방송 id 로 방송 재생 수 조회
  try {
    const { data } = await GetBroadCastsPlayCount(ids)
    for (const [index, item] of items.entries()) {
      item.playCount = data[index].totalPlayCount
    }
  } catch (e) {
    console.error('쇼핑라이브 방송 조회수 에러', e)
  }

  //조회된 방송 id 로 onair Video Url 조회
  try {
    const onairVideoUrl =
      onAirIds.length > 0 ? await GetOnairBroadCastsVideoUrl(onAirIds) : null
    for (const [_, item] of items.entries()) {
      item.videoUrl = createVideoUrl(
        onairVideoUrl?.data?.find((value) => value.broadcastId === item.id)
          ?.playBackVideos
      )
    }
  } catch (e) {
    console.error('쇼핑라이브 방송 onAir URL 에러', e)
  }

  //조회된 방송 id 로 replay Video Url 조회
  try {
    const replayVideoUrl =
      replayIds.length > 0 ? await GetReplayBroadCastsVideoUrl(replayIds) : null
    for (const [_, item] of items.entries()) {
      item.videoUrl = createVideoUrl(
        replayVideoUrl?.data?.find((value) => value.broadcastId === item.id)
          ?.trailerVideos
      )
    }
  } catch (e) {
    console.error('쇼핑라이브 방송 replay URL 에러', e)
  }

  //조회된 몰 이름으로 우리서버에서 몰 아이콘 url 조회
  try {
    const mallInfo = await GetShopInfoByMallName(Array.from(mallNames))

    //몰 이름으로 아이콘 찾을 수 있도록 맵에 데이터 세팅
    for (const item of mallInfo.data) {
      mallNameMap.set(item.shopName, item)
    }
  } catch (e) {
    console.error('쇼핑라이브 몰 이름 매핑 error', e)
  }

  //메타데이터 맵핑
  for (const [_, item] of items.entries()) {
    //몰정보 매핑
    item.mallInfo =
      mallNameMap.get(item.shoppingProducts[0].mallName) ??
      ({} as LiveBroadcastsShopInfo)

    //정렬 맵핑
    switch (item.status) {
      case 'ONAIR':
        item.order = 0
        break
      case 'READY':
        item.order = 1
        break
      case 'END':
        item.order = 2
        break
    }
  }

  function createVideoUrl(data?: Video[]): string | null {
    if (!data) {
      return null
    }
    //가장 큰 화질 순으로 내림차순
    data.sort((a, b) => {
      return b.width - a.width
    })

    //메모리, 화질 이슈로 270 해상도 사용
    for (const item of data) {
      if (item.width === 270) {
        return item.playUrl
      }
    }
    //270 화질을 못찾으면 생성된 영상 중 최고 화질 선택
    return data[0]?.playUrl ?? null
  }

  return items
}

export const NLiveStore: Module<NLiveState, RootState> = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
}
export interface NLiveGetter {
  recommend: LiveBroadcastItem[]
  reply: LiveBroadcastItem[]
}
export interface NLiveState {
  broadcasts: LiveBroadcastItem[]
  broadcastsPaging?: BroadcastsPaging
  swipeBlock: boolean
}

export type NLiveAction = {
  fetchBroadCasts: (payload: {
    pageNum: number
    pageSize: number
  }) => Promise<void>
  fetchSwipe: (payload: { yn: boolean }) => Promise<void>
}

export type VideoPlayStatus = {
  index: number
  value: boolean
}

export type BroadcastsPaging = {
  pageNum: number
  pageSize: number
  totalCount: number
  totalPage: number
}
