import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { DisplayUrl } from '../../plugins/enum'
import { displayApi } from '../../plugins/axios'

export const GetMainDisplay = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DisplayGroup>> =>
  await displayApi.get<DisplayGroup>(DisplayUrl.MAIN_DISPLAY, {
    ...options,
    params,
  })

export const GetMainDisplayTabMenu = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<TabMenuItemGroup>> =>
  await displayApi.get<TabMenuItemGroup>(DisplayUrl.MAIN_DISPLAY_TAB_MENU, {
    ...options,
    params,
  })

export const GetMainDisplayBestSeller = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<BestSellerGroup>> =>
  await displayApi.get<BestSellerGroup>(DisplayUrl.MAIN_DISPLAY_BEST_SELLER, {
    ...options,
    params,
  })

export const GetMainDisplayMyCollection = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<MyCollectionItemGroup>> =>
  await displayApi.get<MyCollectionItemGroup>(
    DisplayUrl.MAIN_DISPLAY_MY_COLLECTION,
    {
      ...options,
      params,
    }
  )
export const GetMainDisplayNewArrivals = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<NewArrivalsItemGroup>> =>
  await displayApi.get<NewArrivalsItemGroup>(
    DisplayUrl.MAIN_DISPLAY_NEW_ARRIVALS,
    {
      ...options,
      params,
    }
  )

export const GetMainDisplayTopShop = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<TopShopItemGroup>> =>
  await displayApi.get<TopShopItemGroup>(DisplayUrl.MAIN_DISPLAY_TOP_SHOP, {
    ...options,
    params,
  })

export const GetMultiPromotionPlanx = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<MultiPromotionGroup>> =>
  await displayApi.get<MultiPromotionGroup>(DisplayUrl.MULTI_PROMOTION_PLANX, {
    ...options,
    params,
  })

export const GetMultiPromotionPlanex = async (
  promotionNo: number,
  fullPath: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<MultiPromotionGroup>> => {
  const url = `${DisplayUrl.MULTI_PROMOTION_PLANEX}?promotionNo=${promotionNo}`
  return await displayApi.get<MultiPromotionGroup>(url, {
    headers: { 'X-Cellook-Path': fullPath },
    ...options,
  })
}

export const GetSinglePromotionPlaneX = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SinglePromotionGroup>> =>
  await displayApi.get<SinglePromotionGroup>(
    DisplayUrl.SINGLE_PROMOTION_PLANEX,
    {
      ...options,
      params,
    }
  )

export const GetMainDisplayTrend = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<TrendItemGroup>> =>
  await displayApi.get<TrendItemGroup>(DisplayUrl.MAIN_DISPLAY_TREND, {
    ...options,
    params,
  })

export const GetMainDisplayPlanex = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<PlanexItemGroup>> =>
  await displayApi.get<PlanexItemGroup>(DisplayUrl.MAIN_DISPLAY_PLANEX, {
    ...options,
    params,
  })

export const GetSinglePromotionPlaneX2 = async (
  promotionNo: number,
  path: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<RenewalSinglePromotionGroup>> =>
  await displayApi.get<RenewalSinglePromotionGroup>(
    DisplayUrl.SINGLE_PROMOTION_PLANEX2,
    {
      ...options,
      params: { promotionNo },
      headers: { 'X-Cellook-Path': path },
    }
  )

export const GetMainDisplayAllItems = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AllItemsGroup>> =>
  await displayApi.get<AllItemsGroup>(DisplayUrl.MAIN_DISPLAY_ALL_ITEMS, {
    ...options,
    params,
  })
export const GetAdonBanner = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AdonBannerGroup>> =>
  await displayApi.get<AdonBannerGroup>(DisplayUrl.ADON_BANNER, {
    ...options,
    params,
  })

export const GetSaleDisplay = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SaleDisplayGroup>> =>
  await displayApi.get<SaleDisplayGroup>(DisplayUrl.SALE_DISPLAY, {
    params,
    ...options,
  })
// 확인 필요
export const GetStoreDisplay = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<StoreGroup>> =>
  await displayApi.get<StoreGroup>(DisplayUrl.STORE_DISPLAY, {
    params,
    ...options,
  })

// TYPE 정의
export const GetStoreTotalDisplay = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<StoreTotalGroup>> =>
  await displayApi.get<StoreTotalGroup>(DisplayUrl.STORE_TOTAL_DISPLAY, {
    ...options,
    params,
  })

export const GetMdPickDisplay = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<MdPickDisplayGroup>> =>
  await displayApi.get<MdPickDisplayGroup>(DisplayUrl.MD_PICK_DISPLAY, {
    ...options,
    params,
  })

export const GetAllItemsDisplay = async (
  params: { page: number },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AllItemsDisplayGroup>> =>
  await displayApi.get<AllItemsDisplayGroup>(DisplayUrl.All_ITEMS_DISPLAY, {
    ...options,
    params,
  })

export const GetBestSellerProduct = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Array<ProductItem>>> =>
  await displayApi.get<Array<ProductItem>>(DisplayUrl.BESTSELLER_PRODUCT, {
    ...options,
    params,
  })

export const GetNewArrivalsProduct = async (
  params?: CategoryRequest,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Array<ProductItem>>> =>
  await displayApi.get<Array<ProductItem>>(DisplayUrl.NEWARRIVALS_PRODUCT, {
    ...options,
    params,
  })

export const GetTrend = async (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DisplayTrend>> =>
  await displayApi.get<DisplayTrend>(DisplayUrl.TREND, options)

export const GetTrendHotRank = async (
  params: { hotRankKeyword: string },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Array<ProductItem>>> =>
  await displayApi.get<Array<ProductItem>>(DisplayUrl.TREND_HOTRANK_PRODUCT, {
    ...options,
    params,
  })

export const GetTrendTheseDayRise = async (
  params: { thesedayRiseId: number },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Array<ProductItem>>> =>
  await displayApi.get<Array<ProductItem>>(
    DisplayUrl.TREND_THESE_DAY_RISE_PRODUCT,
    {
      ...options,
      params,
    }
  )

//ADON 배너뷰
export const PostAdOnView = async (
  data: { key: string },
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  displayApi.post<any>(DisplayUrl.ADON_BANNER_VIEW, data, options)

//ADON 배너클릭
export const PostAdOnClick = async (
  data: { key: string },
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  displayApi.post<any>(DisplayUrl.ADON_BANNER_CLICK, data, options)

export default {
  GetNewArrivalsProduct,
  GetBestSellerProduct,
  GetMainDisplay,
  // GetTrend,
  GetTrendHotRank,
  GetTrendTheseDayRise,
  GetAllItemsDisplay,
  GetStoreTotalDisplay,
  GetStoreDisplay,
}
