import Vue from 'vue'
import Store from '@/store/index'
import { mobileOS } from '@/plugins/util'

// 상품상세 sub Web View 오픈
export const openSubWebView = (payload: any) => {
  const deviceInfo = Store.state.DeviceStore.deviceInfo
  if (!deviceInfo) return

  if (mobileOS() === 'android') {
    window.CellookBridge?.setSubWebViewMoveUrl(JSON.stringify(payload))
    window.CellookBridge.closeModal(true)
  }

  if (mobileOS() === 'ios') {
    if (payload.isGoneBottomView === false) {
      delete payload.isGoneBottomView
    }

    window.webkit?.messageHandlers?.setSubWebViewMoveUrl?.postMessage(payload)
  }
}

// 토큰 동기화
export const setTokenSubWebView = (token: string) => {
  const deviceInfo = Store.state.DeviceStore.deviceInfo
  if (!deviceInfo) return
  if (mobileOS() === 'android') {
    window.CellookBridge.setToken(token)
  }
  if (mobileOS() === 'ios') {
    window.webkit.messageHandlers.setToken.postMessage(token)
  }
}

// 캐시 초기화
export const clearCache = () => {
  const deviceInfo = Store.state.DeviceStore.deviceInfo
  if (!deviceInfo) return

  if (mobileOS() === 'android') {
    window.CellookBridge.clearCache()
  }

  if (mobileOS() === 'ios') {
    window.webkit.messageHandlers.clearCache.postMessage('')
  }
}

// 메인 웹뷰 리로드
export const reloadWebView = () => {
  const deviceInfo = Store.state.DeviceStore.deviceInfo
  if (!deviceInfo) return

  if (mobileOS() === 'android') {
    window.CellookBridge.reloadWebView()
  }

  if (mobileOS() === 'ios') {
    window.webkit.messageHandlers.reloadWebView.postMessage('')
  }
}

export const checkAppUpdate = (
  androidMinVersion: string,
  iosMinVersion: string
) => {
  try {
    console.log(
      `checkAppUpdate : androidMinVersion:${androidMinVersion} / iosMinVersion:${iosMinVersion}`
    )
    window.CellookBridge?.checkAppUpdate(androidMinVersion)
    window.webkit?.messageHandlers?.checkAppUpdate.postMessage(iosMinVersion)
  } catch (e) {
    console.error('checkAppUpdate', e)
  } finally {
    console.log('checkAppUpdate complete')
  }
}

/*
 * 이벤트를 실행시키는 시점이 다름
 * iOS : MainWebView 에서 subWebView를 close
 * aOS : subWebView 에서 close
 */
export const closeSubWebView = () => {
  const deviceInfo = Store.state.DeviceStore.deviceInfo
  if (!deviceInfo) return

  if (mobileOS() === 'android') {
    window.CellookBridge.closeSubWebView()
  }

  if (mobileOS() === 'ios') {
    window.webkit.messageHandlers.closeSubWebView.postMessage('')
  }
}

export const getDevicePush = async () => {
  let result = ''
  if (mobileOS() === 'android') {
    result = await window.CellookBridge?.getDevicePush()
  }

  if (mobileOS() === 'ios') {
    await window.webkit?.messageHandlers?.getDevicePush?.postMessage('')
    await Store.dispatch(
      'DeviceStore/fetchSetPushYn',
      window.iosPushYNFromDevice
    )
    return
  }
  await Store.dispatch('DeviceStore/fetchSetPushYn', result)
}

Vue.prototype.$openSubWebView = openSubWebView
Vue.prototype.$setTokenSubWebView = setTokenSubWebView
Vue.prototype.$clearCache = clearCache
Vue.prototype.$reloadWebView = reloadWebView
Vue.prototype.$checkAppUpdate = checkAppUpdate
Vue.prototype.$closeSubWebView = closeSubWebView
