
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TitleSubscribeShareHeader from '@/components/common/layout/TitleSubscribeShareHeader.vue'
import Lottie from '@/components/common/lottie/LottieView.vue'
import { GetShopInfo } from '@/api/displayAPI/ShopAPI'
import { DeleteSubscribe, PostSubscribe } from '@/api/displayAPI/UserAPI'
import { AnimationItem } from 'lottie-web'
import { HomeStoreState } from '@/store/HomeStore'
import { destroyLottieAnim } from '@/plugins/util/lottie'
import { StoreHomeState } from '@/store/StoreHomeStore'
import { DibsState } from '@/store/DibsStore'

export default Vue.extend<Data, Method, Computed, Props>({
  name: 'BridgeDialog',
  components: {
    Lottie,
    TitleSubscribeShareHeader,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      shopInfo: {} as Shop,
      shopName: '',
      isSubscribe: false,
      debounce: 0,
      anim: null,
      storeImage: '',
    }
  },
  computed: {
    ...mapState('BridgeStore', ['showBridge', 'shopId']),
    ...mapState('DeviceStore', ['deviceInfo']),
    ...mapGetters('HomeStore', ['getHomeState']),
    ...mapGetters('CategoryStore', ['getCategoryHistory']),
    ...mapGetters('ExhibitionStore', [
      'getEventExhibitionScrollLocation',
      'getPromotionSingleMallPaging',
      'getPromotionMultiMallPaging',
      'getPromotionMultiMallCarouselInitData',
    ]),
    ...mapGetters('StoreHomeStore', ['getStoreHomeHistory']),
    ...mapGetters('DibsStore', ['getDibsState']),
  },
  async created() {
    // bridge store 에 history add / del
    window.addEventListener('popstate', this.cancelMove)
    // bridge가 띄어진 와중에 scroll event 방지 ( 기존 scroll 유지하기 위함 )
    window.addEventListener('touchmove', this.preventDefault, {
      passive: false,
    })

    this.fetchShopInfo()

    const { isIOS, isAndroid, browser_name } = this.$device
    const isApp = this.deviceInfo !== null

    // IOS APP 접속 시
    if (isIOS && isApp) {
      return this.iosSubWebView()
    }
    // AOS APP 접속 시
    if (isAndroid && isApp) {
      this.aosSubWebView()
      // 이게 어떤 interface 인지 제이에게 확인 요청중
      window.CellookBridge.closeModal(true)
      return
    }

    // Mobile Web 접속 & Safari 접속 시
    if (
      (!isApp && this.$isMobile()) ||
      browser_name === 'Safari' || // PC
      browser_name.startsWith('Chrome Mobile')
    ) {
      // 로그인 2번 reload 이슈로 인하여 따로 2번째 생성
      history.pushState(null, 'null', location.href)

      // 페이지가 넘어가기 전에 현재 scroll 기억
      this.setHistoryScroll()
      // 상품 상세로 넘어갈 시, 기존 데이터를 저장하기 위한 함수
      this.setHistorys()
      if (
        browser_name === 'Instagram App' &&
        isAndroid &&
        history.length <= 2
      ) {
        //AOS instagram inapp의 경우만...
        history.pushState(null, 'null', location.href)
      }
      this.hrefSubWebView()
    } else {
      // PC Web 접속, Safari 제외
      this.openWindowSubWebView()
    }
  },
  beforeDestroy() {
    if (this.$device.isAndroid && this.deviceInfo !== null) {
      window.CellookBridge.closeModal(false)
    }
    window.removeEventListener('popstate', this.cancelMove)
    window.removeEventListener('touchmove', this.preventDefault)

    destroyLottieAnim(this.anim)
  },
  methods: {
    ...mapActions('HomeStore', ['fetchScroll']),
    ...mapMutations('BridgeStore', ['setShowBridge']),
    ...mapActions('BridgeStore', [
      'iosSubWebView',
      'aosSubWebView',
      'hrefSubWebView',
      'openWindowSubWebView',
      'clearDebounce',
    ]),
    ...mapActions('DibsStore', ['fetchDibsScroll']),
    async fetchShopInfo() {
      if (!this.shopId) return

      try {
        const { data } = await GetShopInfo({ shopId: this.shopId })

        this.shopInfo = data
        this.storeImage = data.mainImgS3Path
        this.shopName = data.shopName
        this.isSubscribe = data.isSubscribed
      } catch (e) {
        console.log(e)
      }
    },
    async subscribe() {
      try {
        await PostSubscribe(this.shopId, this.shopName)
      } catch (e) {
        console.log(e)
      }
    },
    async unSubscribe() {
      try {
        if (!this.shopId) return
        await DeleteSubscribe(this.shopId, this.shopName)
      } catch (e) {
        console.log(e)
      }
    },
    async toggleSubscribe() {
      try {
        this.isSubscribe ? await this.unSubscribe() : await this.subscribe()
        await this.fetchShopInfo()
      } catch (e) {
        console.log(e)
      }
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    async cancelMove() {
      // 각 페이지에에서 넘어갈 떄, 저장을 취소하기 위한 함수
      this.removeHistorys()
      // clearTimeout(this.debounce)
      await this.clearDebounce()
      this.setShowBridge(false)
    },
    receiveMessage(e: any) {
      if (typeof e.data !== 'string') return
      const parsedValue: ReciverValue = JSON.parse(e?.data ?? 'null')
      if (parsedValue === null) return
      // 열린 모달 닫기 ( 브릿지 / 로그인)
      if (parsedValue.type !== 'close') return
      // BridgeDialog 열린 상태면 닫기
      if (!this.showBridge) return
      this.clearDebounce()
      this.setShowBridge(false)
    },
    handleAnim(anim: AnimationItem | null) {
      this.anim = anim
    },
    preventDefault(e) {
      e.preventDefault()
    },
    setHistorys() {
      if (this.deviceInfo !== null) return
      localStorage.setItem('preHomeHistory', JSON.stringify(this.getHomeState))
      localStorage.setItem(
        'categoryHistory',
        JSON.stringify(this.getCategoryHistory)
      )

      // 기존 하드코딩 단일몰 기획전 사용을 위해 코드 남겨둠. 추후 삭제예정 1
      localStorage.setItem(
        'exhibitionEventScrollLocation',
        JSON.stringify(this.getEventExhibitionScrollLocation)
      )
      // 기존 하드코딩 단일몰 기획전 사용을 위해 코드 남겨둠. 추후 삭제예정 2
      if (Object.keys(this.getPromotionSingleMallPaging).length !== 0) {
        localStorage.setItem(
          'promotionSingleMallPaging',
          JSON.stringify(this.getPromotionSingleMallPaging)
        )
      }

      localStorage.setItem(
        'storeHomeHistory',
        JSON.stringify(this.getStoreHomeHistory)
      )
      localStorage.setItem(
        'dibsStateHistory',
        JSON.stringify(this.getDibsState)
      )
    },
    removeHistorys() {
      if (this.deviceInfo !== null) return
      localStorage.removeItem('preHomeHistory')
      localStorage.removeItem('categoryHistory')
      localStorage.removeItem('storeHomeHistory')
      localStorage.removeItem('dibsStateHistory')
      if (this.$route.path === '/singleMallPromotion') {
        sessionStorage.removeItem('singleExhibitionStory')
      }
      if (sessionStorage.getItem('CelltiqueInfo')) {
        sessionStorage.removeItem('CelltiqueInfo')
      }

      // 기존 하드코딩 단일몰 기획전 사용을 위해 코드 남겨둠. 추후 삭제예정 3
      localStorage.removeItem('promotionSingleMallPaging')
      // 기존 하드코딩 단일몰 기획전 사용을 위해 코드 남겨둠. 추후 삭제예정 4
      localStorage.removeItem('exhibitionEventScrollLocation')
    },
    setHistoryScroll() {
      const { scrollTop } = this.$getAppHtml
      const { path } = this.$route

      if (path === '/') return this.fetchScroll({ scroll: scrollTop })
      if (path === '/dibs') return this.fetchDibsScroll({ scroll: scrollTop })
    },
  },
})

interface ReciverValue {
  type: string // 이벤트 종류
  url?: string // 다시 띠울 서브웹뷰 전달 SRc
  shopId?: string //
  title?: string // ShopName
  productNo?: string
}
interface Data {
  shopInfo: Shop
  shopName: string
  isSubscribe: boolean
  debounce: number
  anim: AnimationItem | null
  storeImage: string
}
interface Method {
  fetchShopInfo(): void
  fetchScroll(payload?: { scroll: number }): void
  iosSubWebView(): void
  aosSubWebView(): void
  hrefSubWebView(): void
  openWindowSubWebView(): void
  clearDebounce(): void
  handleAnim(anim: AnimationItem | null): void
  setShowBridge(isShow: boolean): void
  unSubscribe(): void
  subscribe(): void
  closeDialog(): void
  toggleSubscribe(): void
  cancelMove(): void
  receiveMessage(e: any): void
  preventDefault(e: TouchEvent): void
  setHistorys(): void
  removeHistorys(): void
  setHistoryScroll(): void
  fetchDibsScroll(payload: { scroll: number; pre?: number }): Promise<void>
}
interface Computed {
  showBridge: boolean
  shopId: string
  deviceInfo: DeviceInfo
  getHomeState: HomeStoreState
  getCategoryHistory: CategoryHistory
  getEventExhibitionScrollLocation: object
  getStoreHomeHistory: StoreHomeState
  getPromotionSingleMallPaging: any
  getDibsState: DibsState
  getPromotionMultiMallPaging: any
  getPromotionMultiMallCarouselInitData: any
}
interface Props {
  dialog: boolean
}
