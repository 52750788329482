
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { PostAdOnClick, PostAdOnView } from '@/api/displayAPI/DisplayAPI'
import { adOnTracking } from '@/plugins/util'
import { AppProfile } from 'types/App'

export default Vue.extend<Data, Methods, Computed, Props>({
  data() {
    return {
      isLoaded: false,
      bannerIndex: 0,
    }
  },
  computed: {
    ...mapGetters('BannerStore', ['getFlipBanner']),
    ...mapState('BannerStore', ['onBanner']),
    ...mapGetters('ProfileStore', ['getProfile']),

    currentBanner() {
      return this.getFlipBanner.bannerList[this.bannerIndex]
    },
    flipBannerHeight() {
      return `height: ${this.$flipBannerHeight}px`
    },
  },
  watch: {
    onBanner() {
      if (!this.isLoaded) return
      if (!this.onBanner) return

      const { bannerViewEventUrl } = this.currentBanner

      if (!bannerViewEventUrl) return

      console.log('플립배너 ADON View 트래킹')
      adOnTracking(bannerViewEventUrl, (key) => PostAdOnView({ key: key }))
    },
  },
  methods: {
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    async goPageOnlink(link: string) {
      const url = this.currentBanner.bannerClickUrl
      if (url) {
        await adOnTracking(url, (key) => PostAdOnClick({ key: key }))
      }
      location.href = link
    },
    goPage(link: string, index: number) {
      this.$tracking(`banner_main_flip_${this.$numbering(index + 1)}`)

      const bannerClickUrl = this.currentBanner.bannerClickUrl
      if (bannerClickUrl) {
        console.log('플립배너 ADON View 트래킹')
        adOnTracking(bannerClickUrl, (key) => PostAdOnClick({ key: key }))
      }

      if (link === 'loginModal') {
        if (this.isLogined) {
          this.$router.push('/mypage')
        } else {
          this.fetchLoginDialog({ yn: true })
        }
        return
      }

      const payload = {
        url: link,
        title: 'CELLOOK',
        isGoneBottomView: false,
      }
      this.$rendingOutsidePageOrInternal(this.$router, this.$device, payload)
    },
    loadCheck(index: number) {
      // default flip banner 일 경우
      if (this.getFlipBanner.bannerList[0].bannerViewEventUrl === '') return

      this.isLoaded = true

      if (index === 0) {
        console.log('플립배너 ADON View 트래킹')
        adOnTracking(
          this.getFlipBanner.bannerList[0].bannerViewEventUrl,
          (key) => PostAdOnView({ key: key })
        )
      }
    },
    clickBannerHannder(link: string) {
      // default flip banner 일 경우
      if (this.getFlipBanner.bannerList[0].bannerViewEventUrl === '') {
        this.$router.push(this.getFlipBanner.bannerList[0].link)
        return
      }

      if (link.search('onelink') !== -1) {
        this.goPageOnlink(link)
      } else {
        this.goPage(link, 0)
      }
    },
  },
})

interface Data {
  isLoaded: boolean
  bannerIndex: number
}
interface Methods {
  goPage(link: string, index: number): void
  fetchLoginDialog(payload: { yn: boolean }): void
  loadCheck(index: number): void
  goPageOnlink(link: string): void
  clickBannerHannder(link: string): void
}
interface Computed {
  getProfile: AppProfile
  getFlipBanner: AdonBannerList
  currentBanner: AdonBanner
  flipBannerHeight: string
}
interface Props {
  onBanner: boolean
}
