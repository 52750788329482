import { RootState } from '@/store/RootState'
import { mapActions, mapGetters, mapState, Module } from 'vuex'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'

const STORE_NAME = 'CountStore'
const INCREMENT = 'increment'
const DECREMENT = 'decrement'

const getters: GetterTreeAdaptor<CountGetters, CountState, RootState> = {
  isEven(state: CountState) {
    return !(state.count % 2)
  },
}

const actions: ActionTreeAdaptor<CountActions, CountState, RootState> = {
  async performAsyncIncrement(
    { commit /*state, rootState*/ },
    { increment, delayMs }
  ) {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        commit(INCREMENT, increment)
        resolve()
      }, delayMs)
    })
  },
  async performAsyncDecrement(
    { commit /*state, rootState*/ },
    { decrement, delayMs }
  ) {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        commit(DECREMENT, decrement)
        resolve()
      }, delayMs)
    })
  },
}

export const CountStore: Module<CountState, RootState> = {
  namespaced: true,
  state: {
    count: 0,
  },
  getters,
  mutations: {
    [INCREMENT](state: CountState, increment = 1) {
      state.count += increment
    },
    [DECREMENT](state: CountState, decrement = 1) {
      state.count -= decrement
    },
  },
  actions,
}

export const mappedCountState = mapState(STORE_NAME, ['count'])

export interface CountState {
  count: number
}

export const mappedCountGetters = mapGetters(STORE_NAME, ['isEven'])

export interface CountGetters {
  isEven: boolean
}

export const mappedCountActions = mapActions(STORE_NAME, [
  'performAsyncIncrement',
  'performAsyncDecrement',
])

export type CountActions = {
  performAsyncIncrement: (payload: {
    increment: number
    delayMs: number
  }) => Promise<void>
  performAsyncDecrement: (payload: {
    decrement: number
    delayMs: number
  }) => Promise<void>
}

// 아래는 믹스인 활용법

// export const CountComponentBase = Vue.extend<
//   void,
//   CountActions,
//   CountState & CountGetters,
//   void
// >({
//   computed: {
//     ...mappedCountState,
//     ...mappedCountGetters,
//   },
//   methods: {
//     ...mappedCountActions,
//   },
// })

// export class CountComponentBaseClass
//   extends Vue
//   implements CountState, CountGetters, CountActions
// {
//   count!: CountState['count']
//   isEven!: CountGetters['isEven']
//   performAsyncIncrement!: (payload: {
//     increment: number
//     delayMs: number
//   }) => Promise<void>
//   performAsyncDecrement!: (payload: {
//     decrement: number
//     delayMs: number
//   }) => Promise<void>
// }
