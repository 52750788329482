<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill="#222222" />
    <path
      d="M6.83734 3.55859H9.152L8.76623 9.24571H7.22311L6.83734 3.55859ZM7.99467 12.3693C7.65452 12.3693 7.3683 12.2614 7.136 12.0457C6.912 11.8217 6.8 11.5521 6.8 11.2368C6.8 10.9216 6.912 10.6602 7.136 10.4528C7.36 10.2371 7.64623 10.1293 7.99467 10.1293C8.34311 10.1293 8.62934 10.2371 8.85334 10.4528C9.07734 10.6602 9.18934 10.9216 9.18934 11.2368C9.18934 11.5521 9.07319 11.8217 8.84089 12.0457C8.61689 12.2614 8.33482 12.3693 7.99467 12.3693Z"
      fill="white"
    />
  </svg>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({})
</script>
