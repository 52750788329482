<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    transition="scroll-x-reverse-transition"
  >
    <template #activator="{ on, attrs }">
      <div class="mt-20 ma-10" v-bind="attrs" v-on="on">
        <span>
          <v-icon color="default_t">$vuetify.icons.userIcon</v-icon>
        </span>
        <span class="font-g15-16 rixgo-regular ml-4"> 아이디 찾기 </span>
        <v-icon class="mt-3" color="default_t" x-small style="float: right"
          >$vuetify.icons.nextIcon</v-icon
        >
      </div>
    </template>
    <v-card>
      <v-toolbar light color="white" outlined flat>
        <v-btn icon light color="black" @click="closeMobileAuthentication">
          <v-icon small color="default_t">$vuetify.icons.prevIcon</v-icon>
        </v-btn>
        <v-toolbar-title></v-toolbar-title>
        <v-spacer align="center" class="font-g15-16 rixgo-bold ml-4"
          >휴대전화 인증</v-spacer
        >
        <v-toolbar-items>
          <v-btn dark text @click="dialog = false"> Save </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="pt-25 pl-10 pr-10">
        <span class="d-flex rixgo-regular pb-5">
          <input
            id="mobileNumber"
            v-model="mobileNumber"
            class="font-g13-20 gray100 border-radius-10 px-6 flex-grow-1 fill-width"
            pattern="[0-9]{11}"
            maxlength="11"
            placeholder="휴대폰번호"
            type="number"
            oninput="this.value = this.value.replace(/[^0-9]/g, '')"
            @paste="onPaste"
            @input="onInputMobileNumber"
          />
          <v-btn
            :disabled="sendAuthNumberBtn"
            class="disabled-btn-color-white font-g13-20 rixgo-bold ml-4 gray700 white--text flex-shrink-0"
            height="50"
            width="27%"
            @click="sendAuthCode"
          >
            {{ authNumberField === false ? '재전송' : '인증번호 전송' }}
          </v-btn>
        </span>
        <span
          class="d-flex justify-end file-width rixgo-regular"
          style="position: relative"
        >
          <v-text-field
            v-model="authNumber"
            class="font-g13-20"
            :disabled="authNumberField"
            type="number"
            placeholder="인증번호를 입력해주세요."
            background-color="gray100"
            color="white"
            solo
            flat
            height="50"
            hide-details=""
            maxlength="4"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          />
          <span
            style="position: absolute; opacity: 0.8; padding-right: 35%"
            class="font-g12-16 campton-medium gray500--text pt-9"
          >
            {{ resTimeData }}
          </span>
          <v-btn
            :disabled="checkAuthNumberBtn"
            class="disabled-btn-color-white font-g13-20 rixgo-bold gray700 white--text ml-4"
            height="50"
            width="27%"
            @click="confirmAuthNumberBtn"
          >
            확인
          </v-btn>
        </span>
        <div
          :class="`${
            completeFindAccountBtn === true ? 'red--text' : 'primary--text'
          } font-g12-13 rixgo-regular pt-6 pl-5`"
        >
          {{ validationText }}
        </div>
      </div>
      <div class="findAccountFooter">
        <v-btn
          :disabled="completeFindAccountBtn"
          class="disabled-btn-color-white font-g14-20 rixgo-bold blue white--text"
          height="52"
          width="90%"
          @click="completeFindAccount"
        >
          완료
        </v-btn>
      </div>
    </v-card>
    <CompleteFindAccount
      v-if="completeFindAccountDialog"
      :userDataArray="userDataArray"
      :completeFindAccountDialog="completeFindAccountDialog"
      @executeLoginDialog="executeLoginDialog"
      @closeCompleteFindAccountDialog="closeCompleteFindAccountDialog"
    />
    <DialogAlertView
      :dialogAlert="dialogAlert"
      alertText="가입되지 않은 휴대폰 번호입니다."
      @alertConfirmClick="alertConfirmClick"
    />
  </v-dialog>
</template>

<script>
import {
  PostAccessTokenForRegisterByPhone,
  PostSendOneTimePassword,
} from '@/api/accountAPI/OenTimeAuth'
import { GetEmail } from '@/api/accountAPI/UsersAPI'
import CompleteFindAccount from './completeFindAccount.vue'
import DialogAlertView from './DialogAlert.vue'

export default {
  name: 'MobileAuthentication',
  components: { CompleteFindAccount, DialogAlertView },
  data() {
    return {
      // rules: {
      //   mobile: [(v) => v.length <= 11],
      //   authNumber: [(v) => v.length <= 4],
      // },
      mobileNumber: '',
      authNumber: '',
      sendAuthNumberBtn: true,
      checkAuthNumberBtn: true,
      authNumberField: true,
      mobileNumberField: false,
      completeFindAccountBtn: true,
      resTimeData: '',
      validationText: '',
      timeCounter: 181,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      completeFindAccountDialog: false,
      userDataArray: [],
      dialogAlert: false,
    }
  },
  watch: {
    mobileNumber(value) {
      if (value.includes('.')) {
        return
      }
      if (value.length >= 11) {
        this.sendAuthNumberBtn = false
      } else {
        this.authNumber = ''
        this.sendAuthNumberBtn = true
        this.checkAuthNumberBtn = true
        this.authNumberField = true
        this.timeCounter = 181
        this.resTimeData = ''
        this.validationText = ''
        this.timeStop()
      }
    },
    authNumber(value) {
      if (value.includes('.')) {
        return
      }
      value.length >= 4
        ? (this.checkAuthNumberBtn = false)
        : (this.checkAuthNumberBtn = true)
    },
  },
  methods: {
    //휴대폰번호 자릿수안맞으면 다 초기화
    //타이머 끝나면 인증쪽만 초기화
    start() {
      this.polling = setInterval(() => {
        this.timeCounter-- //1씩 감소
        this.resTimeData = this.prettyTime()
        if (this.timeCounter <= 0) {
          this.timeStop() //
          this.authNumber = ''
          this.authNumberField = true
          this.checkAuthNumberBtn = true
        }
      }, 1000)
    },
    prettyTime() {
      const time = this.timeCounter / 60
      const minutes = parseInt(time)
      const secondes = Math.round((time - minutes) * 60)
      return this.pad(minutes, 2) + ':' + this.pad(secondes, 2)
    },
    pad(n, width) {
      n = n + ''
      return n.length >= width
        ? n
        : new Array(width - n.length + 1).join('0') + n
    },
    timeStop() {
      clearInterval(this.polling)
    }, // 재발행
    smsReset() {
      clearInterval(this.polling)
      this.timeCounter = 181
      this.start()
    },
    // checkMobileNumber(e) {
    //   if (e.target.value.includes('.')) {
    //     return
    //   }
    //   if (e.target.value.length >= 11) {
    //     this.mobileNumber = e.target.value
    //     this.sendAuthNumberBtn = false
    //   } else {
    //     this.authNumber = ''
    //     this.sendAuthNumberBtn = true
    //     this.checkAuthNumberBtn = true
    //     this.authNumberField = true
    //     this.timeCounter = 181
    //     this.resTimeData = ''
    //     this.validationText = ''
    //     this.timeStop()
    //   }
    // },
    // checkAuthNumber(e) {
    //   if (e.target.value.length >= 4) {
    //     this.authNumber = e.target.value
    //     this.checkAuthNumberBtn = false
    //   } else {
    //     this.checkAuthNumberBtn = true
    //   }
    // },
    sendAuthCode() {
      // setOneTimeAuthPhoneNumber(this.mobileNuber)
      const data = { phone: this.mobileNumber }
      PostSendOneTimePassword(data).then((result) => {
        if (result.status === 200) {
          this.smsReset()
          this.authNumberField = false
          this.authNumber = ''
          this.checkAuthNumberBtn = true
        }
      })
    },
    confirmAuthNumberBtn() {
      const data = { phone: this.mobileNumber, otpNumber: this.authNumber }
      PostAccessTokenForRegisterByPhone(data)
        .then((result) => {
          if (result.status === 200) {
            this.validationText = '인증이 완료되었습니다.'
            this.sendAuthNumberBtn = true
            this.checkAuthNumberBtn = true
            this.authNumberField = true
            this.mobileNumberField = true
            this.completeFindAccountBtn = false
            this.timeStop()
          } else {
            this.validationText = '인증번호가 일치하지 않습니다.'
          }
        })
        .catch((e) => {
          this.validationText = '인증번호가 일치하지 않습니다.'
        })
    },
    completeFindAccount() {
      //API호출
      const data = { phoneNumber: this.mobileNumber }
      GetEmail(data).then((result) => {
        // this.userDataArray = [...result.data]
        if (result.data.length > 0) {
          this.userDataArray = [...result.data]
          this.completeFindAccountDialog = true
        } else {
          this.dialogAlert = true
        }
      })
    },
    closeMobileAuthentication() {
      this.dialog = false
      this.mobileNumber = ''
      this.authNumber = ''
      this.sendAuthNumberBtn = true
      this.checkAuthNumberBtn = true
      this.authNumberField = true
      this.mobileNumberField = false
      this.completeFindAccountBtn = true
      this.resTimeData = ''
      this.validationText = ''
      this.timeCounter = 181
      this.completeFindAccountDialog = false
      this.timeStop()
    },
    executeLoginDialog() {
      this.closeMobileAuthentication()
      this.$emit('executeLoginDialog', false)
    },
    closeCompleteFindAccountDialog() {
      this.closeMobileAuthentication()
    },
    alertConfirmClick() {
      this.dialogAlert = false
      this.closeMobileAuthentication()
    },
    onPaste(e) {
      e.preventDefault()
      this.mobileNumber = ''
      return false
    },
    onInputMobileNumber(e) {
      const value = e.target._value.toString()

      e.target._value = e.target._value.replace(/[^0-9]/g, '')

      if (value.length >= 11) {
        e.preventDefault()
        this.mobileNumber = this.mobileNumber.substring(0, 11)
        return false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.findAccountFooter {
  position: absolute;
  text-align: center;
  bottom: 30px;
  width: 100%;
}
input::placeholder {
  color: #90949a !important;
}
</style>
