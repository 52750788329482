import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { EventUrl, EventPageUrl } from '@/plugins/enum'
import { displayApi } from '@/plugins/axios'

export const GetIsLike = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Array<ProductItem>>> =>
  await displayApi.get<Array<ProductItem>>(EventUrl.IS_LIKE, {
    ...options,
    params,
  })

export const GetIsSubscribe = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Array<DisplayMallSubscribe>>> =>
  await displayApi.get<Array<DisplayMallSubscribe>>(EventUrl.IS_SUBS, {
    ...options,
    params,
  })

export const PostAdon = async (
  data?: EventAdon,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Array<AdOnBanner>>> =>
  await displayApi.post<Array<AdOnBanner>>(EventUrl.ADON, data, options)

export const GetDisplayEvent = async (
  params: { eventNo: number },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<EventData>> =>
  await displayApi.get<EventData>(EventPageUrl.DISPLAY_EVENT, {
    ...options,
    params,
  })
export const GetPromotionList = async (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Promotion>> =>
  await displayApi.get<Promotion>(EventPageUrl.PROMOTION_LIST, {
    ...options,
  })

// 체크인 조회
export const GetCheckInList = async (
  params: { checkInNo: string },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CheckInDataGroup>> =>
  await displayApi.get<CheckInDataGroup>(EventPageUrl.CHECKIN_LIST, {
    ...options,
    params,
  })

// 체크인 하기
export const PostCheckIn = async (
  params: { checkInNo: string },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<{ isApply: boolean }>> => {
  return displayApi.post<{ isApply: boolean }>(
    EventPageUrl.CHECKIN_LIST,
    params,
    options
  )
}

// 체크인 번호 가져오기
export const GetCheckInNo = async (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<{ checkInNo: string }>> =>
  await displayApi.get<{ checkInNo: string }>(EventPageUrl.CHECK_IN_NO, {
    ...options,
  })

// 이전 체크인 내역 보기
export const GetPrevCheckInList = async (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<any>> =>
  await displayApi.get<any>(EventPageUrl.CHECK_PREV_LIST, {
    ...options,
  })

export const GetEventList = async (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<EventList>> =>
  await displayApi.get<EventList>(EventPageUrl.EVENT_LIST, {
    ...options,
  })

export const GetEventWApp = async (
  eventNo: string,
  path: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<EventWApp>> => {
  const query = new URLSearchParams()
  query.append('eventNo', eventNo)

  return await displayApi.get<EventWApp>(EventPageUrl.EVENT_W_APP, {
    headers: { 'X-Cellook-Path': path },
    params: query,
    ...options,
  })
}

export default {
  GetIsLike,
  GetIsSubscribe,
  PostAdon,
}
