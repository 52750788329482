var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:`d-flex align-center justify-space-around px-10 z-index-100 ${_vm.border}`,style:(`background:${_vm.headerColor};height:40px;top:0px;`)},[_c('div',{staticClass:"d-flex flex-row flex-sm-row align-center",on:{"click":_vm.handlePage}},[(_vm.needShowLogoImg)?_c('img',{staticStyle:{"width":"87px","height":"20px"},attrs:{"src":_vm.headerColor === '#ffffff'
          ? require('../../../assets/images/logo_cellook.svg')
          : require('../../../assets/images/logo_white.svg')}}):_c('Lottie',{staticStyle:{"width":"87px","height":"20px","margin":"0"},attrs:{"src":_vm.headerColor === '#ffffff'
          ? require('../../../assets/lottie/cellook.json')
          : require('../../../assets/lottie/cellook-w.json'),"options":{ autoplay: true, loop: false }},on:{"animCreated":_vm.handleAnim}})],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center",on:{"click":_vm.movoToSearch}},[_c('img',{attrs:{"src":_vm.headerColor === '#ffffff'
          ? `${require('../../../assets/images/search.svg')}`
          : `${require('../../../assets/images/search_white.svg')}`}})]),_c('div',{staticClass:"d-flex align-center",on:{"click":function($event){return _vm.handleDialog(true, 'login')}}},[_c('img',{staticClass:"pl-7",attrs:{"src":_vm.headerColor === '#ffffff'
          ? `${require('../../../assets/images/user.svg')}`
          : `${require('../../../assets/images/user_white.svg')}`}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }