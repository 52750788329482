
import Vue, { PropType } from 'vue'
// evnet
// onClick : 클릭 시, 실행 함수
export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    /**
     * Button <Props>
     * color : 버튼 색상 'pulgins/vuetify' 참조 ( 해당 base는 background-color / sub는 color에 해당 색상을 상속받음  )
     * small : 크기조절 없이 버튼 작게 ( Detail은 layout에서 )
     * text : 버튼 Text
     * disabled : 버튼 비활성화 여부 - true : 비활성화, false : 활성화
     * loading : 로딩 활성화 여부 - true : 활성화,  false : 비활성화
     * isPull : 버튼 최대 크기 여부 - true : 활성화, false : 비활성화
     */
    color: {
      type: String as PropType<string>,
      default: () => 'default',
    },
    small: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    text: {
      type: String as () => string,
      default: () => '',
    },
    disabled: {
      type: Boolean as () => boolean,
      default: () => false,
    },
    loading: {
      type: Boolean as () => boolean,
      default: () => false,
    },
    isPull: {
      type: Boolean as () => boolean,
      default: () => true,
    },
    border: {
      type: String as () => string,
      default: () => 'rounded-0',
    },
    borderColor: {
      type: String as () => string,
      default: () => 'primary_t',
    },
    outlined: {
      type: Boolean as () => boolean,
      default: () => false,
    },
    left: {
      type: Boolean as () => boolean,
      default: () => true,
    },
    width: {
      type: Number as () => number,
      default: () => null,
    },
    height: {
      type: Number as () => number,
      default: () => null,
    },
  },
  computed: {
    borderStyle() {
      return ``
    },
  },
})

interface Data {
  name: string
}

interface Methods {
  test(): void
}
interface Computed {
  // no need to mix with CountState, MetaState and CountGetters
  borderStyle: string
}

interface Props {
  color: string
  small: boolean
  text: string
  disabled: boolean
  loading: boolean
  isPull: boolean
  border: string
  borderColor: string
  outlined: boolean
  left: boolean
  width: number
  height: number
}
