import { RootState } from '@/store/RootState'
import { Module } from 'vuex'

export const BottomNaviStore: Module<BottomNaviState, RootState> = {
  namespaced: true,
  state: {
    lastTab: -1,
  },
  mutations: {
    setLastTab(state: BottomNaviState, lastTab: number) {
      state.lastTab = lastTab
    },
  },
}

export interface BottomNaviState {
  lastTab: number
}
