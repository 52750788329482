import { RootState } from '@/store/RootState'
import { Module } from 'vuex'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import {
  GetSearchAutoKeywordEncode,
  GetSearchEncode,
  GetSearchRank,
  GetSearchRecommendationEncode,
} from '../api/searchAPI/SearchAPI'
import {
  GetTrend,
  GetTrendHotRank,
  GetTrendTheseDayRise,
} from '../api/displayAPI/DisplayAPI'

import { GetShopTarget } from '../api/displayAPI/ShopAPI'
import { kakaoPixel, replaceGap } from '@/plugins/util'
import _ from 'lodash'
import store from '@/store/index'
import { BannerGetters, BannerState } from '@/store/BannerStore'
import { UserState } from '@/store/UserStore'

// const STORE_NAME = 'SearchStore'

const EMPTY_PAGING: Paging = {
  total: 0,
  size: 50,
  page: 0,
  pageSize: 0,
  hasNext: false,
}

const state: Search = {
  filterHelper: {
    sort: [],
    price: {
      title: '',
      filter: {},
    },
    saleRate: [],
  },
  searchTab: 1,
  recentSearchList: [],
  isAutoSave: true, // 로컬 스토리지에서 남아 있어야 함. 웹 진입시 로컬에서 상태값을 불러와서 저장
  searchWord: '',
  mallList: [],
  relationList: [],
  mallTextList: [],
  productList: [],
  debounce: 0,
  searchRecommendationList: [],
  // 인기 검색어
  rankList: [],
  refreshTime: '',
  // 지금 핫한, 요즘 뜨는 menu List
  trendHotRankKeywordList: [],
  trendThesedayRiseKeywordList: [],
  // 아이템 리스트
  hotTrendList: [],
  trendTheseDayRiseList: [],
  // 선택된 메뉴
  trendHotChip: { id: -1, title: '' },
  trendRiseChip: { id: -1, title: '' },
  ////////////////////////////////
  isShowAutoKeyword: false,
  isShowSearchResult: false,
  // 취향저격 //
  targetList: [],
  // Paging
  paging: _.cloneDeep(EMPTY_PAGING),
  isMatch: false,
  scroll: 0,
  searchParam: {
    'filters.maxPrice': undefined,
    'filters.minPrice': undefined,
    'filters.maxSaleRate': undefined,
    'filters.minSaleRate': undefined,
    'sort.option': 'latest',
  },
}

const mutations = {
  setSearchParam(state: Search, payload: SearchParams) {
    state.searchParam = payload
  },
  setRecentSearchList(state: Search, payload: string[] = []) {
    state.recentSearchList = payload
  },
  setIsAutoSave(state: Search, payload: boolean) {
    state.isAutoSave = payload
  },
  async setSearchWord(state: Search, payload: string) {
    if (payload !== state.searchWord) {
      store.commit('SearchStore/setSearchParam', { 'sort.option': 'latest' })
    }

    state.searchRecommendationList = []
    state.searchWord = payload
  },
  setMallList(state: Search, payload: any[] = []) {
    state.mallList = payload
  },
  setRelationList(state: Search, payload: any[] = []) {
    state.relationList = payload
  },
  setProductList(state: Search, payload: any[] = []) {
    state.productList = payload
  },
  setDebounce(state: Search, payload: number) {
    state.debounce = payload
  },
  setRankList(state: Search, payload: any[] = []) {
    state.rankList = payload
  },
  setHotTrendList(state: Search, payload: any[] = []) {
    state.hotTrendList = payload.splice(0, 9)
  },
  setTrendTheseDayRiseList(state: Search, payload: any[] = []) {
    state.trendTheseDayRiseList = payload.splice(0, 9)
  },
  setSearchRecommendationList(state: Search, payload: any[] = []) {
    state.searchRecommendationList = payload
  },
  setRefreshTime(state: Search, payload: string) {
    state.refreshTime = payload
  },
  setTrendHotRankKeywordList(
    state: Search,
    payload: { id: number; title: string }[] = []
  ) {
    state.trendHotRankKeywordList = payload
  },
  setTrendThesedayRiseKeywordList(
    state: Search,
    payload: { id: number; title: string }[] = []
  ) {
    state.trendThesedayRiseKeywordList = payload
  },
  setTrendHotChip(state: Search, payload: { id: number; title: string }) {
    state.trendHotChip = payload
  },
  setTrendRiseChip(state: Search, payload: { id: number; title: string }) {
    state.trendRiseChip = payload
  },
  setIsShowAutoKeyword(state: Search, payload: boolean) {
    state.isShowAutoKeyword = payload
  },
  setIsShowSearchResult(state: Search, payload: boolean) {
    state.isShowSearchResult = payload
  },
  setMallTextList(state: Search, payload: any[] = []) {
    state.mallTextList = payload
  },
  setTargetList(state: Search, payload: any[] = []) {
    state.targetList = payload
  },
  setPaging(state: Search, payload: Paging) {
    state.paging = payload
  },
  setIsMatch(state: Search, payload: boolean) {
    state.isMatch = payload
  },
  setScroll(state: Search, payload: number) {
    state.scroll = payload
  },
  setSearchTab(state: Search, payload: number) {
    state.searchTab = payload
  },
  setFilterHelper(state: Search, payload: SearchFilterHelper) {
    state.filterHelper = payload
  },
}

const actions: ActionTreeAdaptor<RecentlyActions, Search, RootState> = {
  async fetchSearchParams({ commit }, payload: SearchParams): Promise<void> {
    commit('setSearchParam', payload)
  },
  async fetchRecentSearch({ commit }): Promise<void> {
    const recentSearch: String[] = JSON.parse(
      localStorage.getItem('searchWordList') ?? '[]'
    )
    commit('setRecentSearchList', recentSearch)
  },
  async fetchGetSearch({ commit, state, dispatch }) {
    if (state.searchWord.trim() === '') return
    const searchData = replaceGap(state.searchWord)
    kakaoPixel('search', { keyword: searchData })
    // clearTimeout(state.debounce)
    dispatch('fetchGetSearchAutoKeyword')
    const params: any = {
      'paging.page': 0,
      'paging.limit': 50,
      keyword: searchData,
    }

    const { data } = await GetSearchEncode(
      createSearchParam(params, state.searchParam)
    )

    if (
      data.filterHelper &&
      data.filterHelper.price?.filter?.['filters.minPrice'] &&
      data.filterHelper.price?.filter?.['filters.maxPrice']
    ) {
      commit('setFilterHelper', data.filterHelper)
    }

    commit('setMallList', data.autoKeywordResult?.mall ?? [])
    commit('setRelationList', data.autoKeywordResult?.relation ?? [])
    commit('setProductList', data.list ?? [])
    commit('setPaging', data.summary?.paging ?? _.cloneDeep(EMPTY_PAGING))
    commit('setIsShowSearchResult', true)
    if (state.productList.length === 0) {
      const { data } = await GetShopTarget()
      commit('setTargetList', data)
    }
    // 자동 저장 확인
    if (state.isAutoSave === true) {
      const searchData = replaceGap(state.searchWord)
      const searchWordList = JSON.parse(
        localStorage.getItem('searchWordList') ?? '[]'
      )
      // 같은 검색어 존재 확인
      const findIndex = searchWordList.findIndex(
        (word: string) => word === searchData
      )
      // 존재하면 제거
      if (findIndex !== -1) {
        searchWordList.splice(findIndex, 1)
      }
      // 최근 검색어 앞에 추가
      searchWordList.unshift(searchData)
      // 배열길이가 20보다 크면 pop()
      if (searchWordList.length > 20) searchWordList.pop()
      localStorage.setItem('searchWordList', JSON.stringify(searchWordList))
    }
  },
  async fetchGetSearchInfinity({ commit, state }, payload: number) {
    const params: any = {
      'paging.page': payload ?? 0,
      'paging.limit': state.paging.size,
      keyword: state.searchWord,
    }
    const { data } = await GetSearchEncode(
      createSearchParam(params, state.searchParam)
    )
    commit('setMallList', data.autoKeywordResult?.mall ?? [])
    commit('setRelationList', data.autoKeywordResult?.relation ?? [])
    commit('setProductList', [...state.productList, ...(data.list ?? [])])
    commit('setPaging', data.summary?.paging ?? _.cloneDeep(EMPTY_PAGING))
  },
  async fetchGetSearchAutoKeyword({ commit, state, dispatch }) {
    if (state.searchWord.trim() === '') return
    const searchData = replaceGap(state.searchWord)
    // commit('setIsShowSearchResult', false)
    const params = {
      keyword: searchData,
      limit: 20,
    }
    if (searchData) {
      clearTimeout(state.debounce)
      commit(
        'setDebounce',
        setTimeout(async () => {
          const { data } = await GetSearchAutoKeywordEncode(params)
          commit('setMallTextList', data.mall ?? [])
          commit('setRelationList', data.relation ?? [])
          commit('setIsShowAutoKeyword', true)
        }, 300)
      )
    } else {
      clearTimeout(state.debounce)
    }
  },
  async fetchGetSearchRank({ commit }) {
    const { data } = await GetSearchRank()
    commit('setRankList', data.keyword ?? [])
    commit('setRefreshTime', data.refreshTime ?? '')
  },
  async fetchGetTrendHotRank({ commit, state }) {
    const params = {
      hotRankKeyword: state.trendHotChip.title,
    }
    const { data } = await GetTrendHotRank(params)
    commit('setHotTrendList', _.cloneDeep(data))
  },
  async fetchGetTrendTheseDayRise({ commit, state }) {
    const params = {
      thesedayRiseId: state.trendRiseChip.id,
    }
    const { data } = await GetTrendTheseDayRise(params)
    commit('setTrendTheseDayRiseList', data ?? [])
  },
  async fetchGetSearchRecommendation({ commit, state }) {
    if (state.searchWord.trim() === '') return
    const searchData = replaceGap(state.searchWord)

    const payload = {
      'paging.limit': 50,
      keyword: searchData,
      'sort.option': 'maxview',
    }
    const { data } = await GetSearchRecommendationEncode(payload)
    commit('setSearchRecommendationList', data.list ?? [])
    commit('setIsMatch', data.isMatch ?? false)
  },
  async fetchGetTrend({ commit }) {
    const { data } = await GetTrend()
    commit('setTrendHotRankKeywordList', data.trendHotRankKeywordList ?? [])
    commit(
      'setTrendThesedayRiseKeywordList',
      data.trendThesedayRiseKeywordList ?? []
    )
  },
  async fetchClearResponse({ commit }) {
    commit('setScroll', 0)
    commit('setSearchWord', '')
    commit('setSearchParam', { 'sort.option': 'latest' })
    commit('setMallList', [])
    commit('setRelationList', [])
    commit('setProductList', [])
    commit('setSearchRecommendationList', [])
    commit('setTargetList', [])
    commit('setPaging', _.cloneDeep(EMPTY_PAGING))
    commit('setIsShowSearchResult', false)
    // commit
    commit('setMallTextList', [])
    commit('setRelationList', [])
    commit('setIsShowAutoKeyword', false)
    commit('setSearchTab', 1)
  },
  async fetchSearchTab({ commit }, payload) {
    commit('setSearchTab', payload)
  },
  // Store를 사용하는데 매번 API 불러오는 바람에 해당 함수 분리
  // Search Refactoring 시 수정
  async fetchInitChip({ commit }) {
    const initChip = { id: -1, title: '' }
    commit('setTrendHotChip', initChip)
    commit('setTrendRiseChip', initChip)
  },
}

const createSearchParam = (params: any, searchParam: any) => {
  for (const searchParamKey in searchParam) {
    if (searchParamKey && searchParam[searchParamKey]) {
      params[searchParamKey] = searchParam[searchParamKey]
    }
  }
  return params
}

const getters: GetterTreeAdaptor<SearchGetters, Search, RootState> = {
  getSearchParam(state: Search) {
    return state.searchParam
  },
}

export interface SearchGetters {
  getSearchParam: SearchParams
}

export const SearchStore: Module<Search, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export interface Search {
  filterHelper: SearchFilterHelper
  searchTab: number
  recentSearchList: string[]
  isAutoSave: boolean
  searchWord: string
  mallList: SearchAutoKeywordResultMall[]
  relationList: any[]
  mallTextList: any[]
  productList: any[]
  debounce: number
  rankList: any[]
  hotTrendList: any[]
  trendTheseDayRiseList: any[]
  searchRecommendationList: any[]
  refreshTime: string
  trendHotRankKeywordList: { id: number; title: string }[]
  trendThesedayRiseKeywordList: { id: number; title: string }[]
  trendHotChip: { id: number; title: string }
  trendRiseChip: { id: number; title: string }
  isShowAutoKeyword: boolean
  isShowSearchResult: boolean
  targetList: SearchShopTarget[]
  paging: Paging
  isMatch: boolean
  scroll: number
  searchParam: any
}

export type RecentlyActions = {
  fetchSearchParams: (payload: SearchParams) => Promise<void>
  fetchRecentSearch: (payload: string[]) => Promise<void>
  fetchGetSearch: () => Promise<void>
  fetchGetSearchInfinity: (page: number) => Promise<void>
  fetchGetSearchAutoKeyword: () => Promise<void>
  fetchGetSearchRank: () => Promise<void>
  fetchGetTrendHotRank: () => Promise<void>
  fetchGetTrendTheseDayRise: () => Promise<void>
  fetchGetSearchRecommendation: () => Promise<void>
  fetchGetTrend: () => Promise<void>
  fetchClearResponse: () => Promise<void>
  fetchSearchTab: (payload: number) => Promise<void>
  fetchInitChip(): Promise<void>
}
