import { RootState } from '@/store/RootState'
import { Module } from 'vuex'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import { GetSinglePromotionPlaneX2 } from '@/api/displayAPI/DisplayAPI'

const getters: GetterTreeAdaptor<ExhibtionGetters, ExhibitionState, RootState> =
  {
    getEventExhibitionScrollLocation(state: ExhibitionState) {
      return state.eventScrollLocation
    },
    getPromotionSingleMallPaging(state: ExhibitionState) {
      return state.singleMallPaging
    },
    getPromotionSingleData(state: ExhibitionState) {
      return state.singlePromotionDataGroup
    },
    getPromotionMultiMallPaging(state: ExhibitionState) {
      return state.multiMallPaging
    },
    getPromotionMultiMallCarouselInitData(state: ExhibitionState) {
      return state.multiMallCarouselInitData
    },
    getMultiPromotionGroup(state: ExhibitionState): MultiPromotionGroup {
      return (
        JSON.parse(
          sessionStorage.getItem(KEY_STORAGE_MULTI_MALL_PROMOTION_GROUP) ??
            'null'
        ) ?? state.history.multiPromotionGroup
      )
    },
    getPromotion(state: ExhibitionState): Promotion {
      return state.history.promotion
    },
    getHistory(state: ExhibitionState): ExhibitionHistory {
      return state.history
    },
    getPromotionListScrollLocation(state: ExhibitionState): number {
      return state.promotionListScrollLocation
    },
  }

const actions: ActionTreeAdaptor<ExhibitionAction, ExhibitionState, RootState> =
  {
    async fetchEventExhibitionScrollLocation({ commit }, params) {
      commit('setEventExhibitionScrollLocation', params)
    },
    async fetchPromotionSingleMallPaging({ commit }, params) {
      commit('setPromotionSingleMallPaing', params)
    },
    async fetchSingleMallExihibitionData({ commit }, { promotionNo, path }) {
      const { data } = await GetSinglePromotionPlaneX2(promotionNo, path)
      commit('setPromotionSingleData', data)
    },
    async fetchMultiMallExhibitionData({ commit }, payload) {
      await sessionStorage.setItem(
        KEY_STORAGE_MULTI_MALL_PROMOTION_GROUP,
        JSON.stringify(payload)
      )
      await commit('setMultiPromotionGroupHistory', payload)
    },
    async fetchPromotionMultiMallPaging({ commit }, params) {
      commit('setPromotionMultiMallPaing', params)
    },
    async fetchPromotionMultiMallCarouselInitData({ commit }, params) {
      commit('setPromotionMultiMallCarouselInitData', params)
    },
    async fetchPromotionListScrollLocation({ commit }, params) {
      commit('setPromotionListScrollLocation', params)
    },
    async fetchPromotion({ commit }, params) {
      commit('setPromotionHistory', params)
    },
  }

export const ExhibitionStore: Module<ExhibitionState, RootState> = {
  namespaced: true,
  state: {
    eventScrollLocation: 0,
    singleMallPaging: [],
    multiMallPaging: {},
    multiMallCarouselInitData: {},
    singlePromotionDataGroup: {
      promotionNo: 0,
      title: '',
      shopId: 0,
      startDt: '',
      endDt: '',
      existsArrivalGuarantee: false,
      isClosed: false,
      timerInfo: {
        title: '',
        isUse: false,
      },
      buttonBeforeImageUrlList: [],
      videoUrl: '',
      buttonInfo: {
        type: '',
        text: '',
        textColor: '',
        buttonColor: '',
        backgroundColor: '',
      },
      buttonAfterImageUrlList: [],
      shoppingLiveButtonInfo: {
        type: '',
        text: '',
        textColor: '',
        buttonColor: '',
        backgroundColor: '',
        isUse: false,
        link: '',
      },
      recommendGroup: {
        mainGroup: {
          carouselSearchProductList: [],
          searchProductList: [],
        },
        subGroup: {
          imageUrl: '',
          searchProductList: [],
        },
      },
      categoryGroup: {
        imageUrl: '',
        categoryList: [],
      },
      bottomBannerList: [
        {
          icon: '',
          link: '',
        },
      ],
      shareInfo: {
        title: '',
        thumbnail: '',
      },
      gaInfo: {
        title: '',
      },
    },
    promotionListScrollLocation: 0,
    history: {
      multiPromotionGroup: {} as MultiPromotionGroup,
      promotion: {} as Promotion,
    },
  },
  getters,
  mutations: {
    setEventExhibitionScrollLocation(state: ExhibitionState, payload: number) {
      state.eventScrollLocation = payload
    },
    setPromotionSingleMallPaing(state: ExhibitionState, payload) {
      state.singleMallPaging = payload
    },
    setPromotionSingleData(
      state: ExhibitionState,
      payload: RenewalSinglePromotionGroup
    ) {
      state.singlePromotionDataGroup = payload
    },
    setPromotionMultiMallPaing(state: ExhibitionState, payload: {}) {
      state.multiMallPaging = payload
    },
    setPromotionMultiMallCarouselInitData(
      state: ExhibitionState,
      payload: object
    ) {
      state.multiMallCarouselInitData = payload
    },
    setHistory(state: ExhibitionState, payload: ExhibitionHistory) {
      state.history = payload
    },
    setMultiPromotionGroupHistory(
      state: ExhibitionState,
      payload: MultiPromotionGroup
    ) {
      state.history.multiPromotionGroup = payload
    },
    setPromotionHistory(state: ExhibitionState, payload: Promotion) {
      state.history.promotion = payload
    },
    setPromotionListScrollLocation(state: ExhibitionState, payload: number) {
      state.promotionListScrollLocation = payload
    },
  },
  actions,
}

export interface ExhibitionState {
  eventScrollLocation: any
  singleMallPaging:
    | [
        {
          page: number
          limit: number
        }
      ]
    | []
  multiMallPaging: any
  multiMallCarouselInitData: any
  singlePromotionDataGroup: RenewalSinglePromotionGroup
  promotionListScrollLocation: number
  history: ExhibitionHistory
}

export interface ExhibtionGetters {
  getHistory: ExhibitionHistory
  getEventExhibitionScrollLocation: number
  getPromotionSingleMallPaging: any
  getPromotionSingleData: RenewalSinglePromotionGroup
  getMultiPromotionGroup: MultiPromotionGroup
  getPromotionMultiMallPaging: any
  getPromotionMultiMallCarouselInitData: any
  getPromotionListScrollLocation: number
  getPromotion: Promotion
}

export type ExhibitionAction = {
  fetchEventExhibitionScrollLocation: (payload: {
    delayMs: number
  }) => Promise<void>
  fetchPromotionSingleMallPaging: (
    payload: [
      {
        page: number
        limit: number
      }
    ]
  ) => Promise<void>
  fetchSingleMallExihibitionData: (payload: {
    promotionNo: number
    path: string
  }) => Promise<void>
  fetchPromotionMultiMallPaging: (payload: { delayMs: number }) => Promise<void>
  fetchPromotionMultiMallCarouselInitData: (payload: {
    delayMs: number
  }) => Promise<void>
  fetchMultiMallExhibitionData: (payload: MultiPromotionGroup) => Promise<void>
  fetchPromotionListScrollLocation: (payload: number) => Promise<void>
  fetchPromotion: (payload: Promotion) => Promise<void>
}

export const KEY_STORAGE_MULTI_MALL_PROMOTION_GROUP =
  'key_storage_multi_mall_promotion_group'
export interface ExhibitionHistory {
  multiPromotionGroup: MultiPromotionGroup
  promotion: Promotion
}
