
import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import Lottie from '@/components/common/lottie/LottieView.vue'
import { AnimationItem } from 'lottie-web'
import { destroyLottieAnim } from '@/plugins/util/lottie'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Lottie },
  data() {
    return {
      needShowLogoImg: false,
      anim: null,
    }
  },
  computed: {
    ...mapState('HomeStore', ['isNotFirstLottiePlay']),
    ...mapState('DeviceStore', ['deviceInfo']),
    ...mapState('CommonStore', ['headerColor']),
    border() {
      return this.$route.path.includes('categoryMain')
        ? 'border-bottom-gray'
        : ''
    },
  },
  created() {
    this.needShowLogoImg = this.isNotFirstLottiePlay
  },
  beforeDestroy() {
    destroyLottieAnim(this.anim)
  },
  methods: {
    ...mapMutations('HomeStore', ['setFirstLottiePlay']),
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    ...mapActions('HomeStore', ['fetchInit', 'fetchInitScroll']),
    ...mapActions('MyPageStore', ['asyncGetMyPage', 'asyncGetSettings']),
    ...mapActions('DibsStore', ['asyncGetDibsSubscribe']),
    ...mapActions('SearchStore', ['fetchClearResponse', 'fetchInitChip']),
    ...mapActions('DisplayStore', ['asyncGetDisplay']),
    handleDialog(yn: boolean, type: string): void {
      if (this.isLogined) {
        if (this.$route.fullPath === '/mypage') {
          this.$tracking('header_mypage')
          this.asyncGetMyPage()
          this.asyncGetDibsSubscribe()
          if (this.deviceInfo !== null) {
            this.asyncGetSettings()
          }
          return
        }
        this.$router.push('/mypage')
        return
      }

      if (type === 'login') {
        this.fetchLoginDialog({ yn })
        return
      }
    },
    handlePage() {
      this.$tracking('gnb_logo')
      const pathName = this.$route.name
      if (pathName === 'Main') {
        this.fetchInit()
        this.asyncGetDisplay()
        this.fetchInitScroll()
        this.$vuetify.goTo('#app', { container: this.$getAppHtml })
      } else {
        this.$router.push('/')
      }
    },
    movoToSearch() {
      this.$tracking('search')
      // reset store
      this.fetchInitChip()
      this.fetchClearResponse()
      // sessionStorage.setItem('searchReturnPath', this.$route.fullPath)
      this.$router.push('/search')
    },
    handleAnim(anim: AnimationItem | null) {
      this.anim = anim
      this.anim?.addEventListener('DOMLoaded', () => {
        this.setFirstLottiePlay(true)
      })
      this.anim?.addEventListener('complete', () => {
        console.log('Header.vue Lottie anim complete')
        this.needShowLogoImg = true
      })
    },
  },
})
interface Data {
  anim: AnimationItem | null
  needShowLogoImg: boolean
}
interface Methods {
  asyncGetMyPage: () => Promise<void>
  asyncGetSettings: () => Promise<void>
  asyncGetDibsSubscribe: () => Promise<void>
  fetchClearResponse: () => Promise<void>
  fetchInitChip(): Promise<void>
  handleDialog(yn: boolean, type: string): void
  fetchLoginDialog({ yn }: { yn: boolean }): void
  handlePage(): void
  movoToSearch(): void
  handleAnim(anim: AnimationItem | null): void
  setFirstLottiePlay(payload: boolean): void
  asyncGetDisplay(): void
  fetchInit(): void
  fetchInitScroll(): void
}
interface Computed {
  deviceInfo: DeviceInfo
  isNotFirstLottiePlay: boolean
  border: string
}
interface Props {}
