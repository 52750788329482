import { AnimationItem } from 'lottie-web'

export function destroyLottieAnim(anim: AnimationItem | null) {
  if (!anim) {
    return
  }
  console.log('lottie anim destroy call')
  anim?.stop()
  anim?.destroy()
}
