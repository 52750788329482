import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { NoticeUrl } from '../../plugins/enum'
import { displayApi } from '../../plugins/axios'

export const GetView = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<NoticeView>> =>
  await displayApi.get<NoticeView>(NoticeUrl.NOTICE_VIEW, {
    ...options,
    params,
  })
export const GetViewDetail = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<NoticeViewDetail>> =>
  await displayApi.get<NoticeViewDetail>(NoticeUrl.NOTICE_VIEW_DETAIL, {
    ...options,
    params,
  })
export const GetLastNoticeTime = async (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<NoticeLastDate>> =>
  displayApi.get<NoticeLastDate>(NoticeUrl.NOTICE_LAST_DATE, {
    ...options,
  })

export default {
  GetView,
  GetViewDetail,
}
