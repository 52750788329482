import { RootState } from '@/store/RootState'
import { Module } from 'vuex'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import {
  GetCategoryMain,
  GetCategoryPlanexDetail,
  GetCategoryPlanexList,
  GetCategoryTopShop,
} from '@/api/displayAPI/CategoryAPI'

import { initCategoryHistory } from '@/util/category'

const getters: GetterTreeAdaptor<CategoryGetters, CategoryState, RootState> = {
  getCategoryHistory(state: CategoryState) {
    return state.history
  },
  getCategoryItemGroup(state: CategoryState) {
    return state.history.category.categoryItemGroupList
  },
  getCategoryTopBannerGroup(state: CategoryState) {
    return state.history.category.topBannerItemGroup
  },
  getCategoryBottomBannerGroup(state: CategoryState) {
    return state.history.category.bottomBannerItemGroup
  },
  getSeasonHotItemGroup(state: CategoryState) {
    return state.history.category.seasonHotItemGroup
  },
  getCategoryTopShop(state: CategoryState) {
    return state.history.topShop
  },
  getCategoryPlanex(state: CategoryState) {
    return state.history.planex.list
  },
  getCategoryCode(state: CategoryState) {
    return state.history.page.categoryCode
  },
  getPageHistory(state: CategoryState) {
    return state.history
  },
  getScrollLocation(state: CategoryState) {
    return state.history.page.scrollLocation
  },
  getPromotionNo(state: CategoryState) {
    return state.history.page.promotionNo
  },
  // 리펙대상
  getMarginHeight(state: CategoryState) {
    return state.history.page.marginHeight
  },
  getSearchProduct(state: CategoryState) {
    return state.history.page.searchList
  },
  getCategoryMainScrollLocation(state: CategoryState): number {
    return state.categoryMainScrollLocation
  },
}

const actions: ActionTreeAdaptor<CategoryAction, CategoryState, RootState> = {
  // 각 페이지 내부에서 매번 호출하는데... 이것도 refresh가 되기 전에는 되지 않도록 하는 것이 좋지 않나
  async fetchCategoryMain({ commit }) {
    const { data } = await GetCategoryMain()
    //카테고리 전체 클라이언트에서 하드코딩
    const allCategoryItemGroup: CategoryItemGroup = {
      categoryList: [
        { categoryCode: '7', categoryName: '전체', categoryList: [] },
      ],
    }
    data.categoryItemGroupList.splice(0, 0, allCategoryItemGroup)
    commit('setCategory', data)
  },
  async fetchCategoryTopShop({ commit }) {
    const { data } = await GetCategoryTopShop()
    commit('setCategoryTopShop', data)
  },
  async fetchCategoryPlanex({ commit }) {
    const { data } = await GetCategoryPlanexList()
    commit('setCategoryPlanex', data)
  },
  async fetchCategoryPlanexDetail({ commit }, promotionNo) {
    const { data } = await GetCategoryPlanexDetail({ promotionNo })
    commit('setCategoryPlanexDetail', data)
  },
  async fetchCategoryHistory({ commit }, payload) {
    commit('setCategoryHistory', payload)
  },
  //fetch page information
  async fetchCategoryCode({ commit }, params) {
    commit('setCategoryCode', params)
  },

  async fetchInitCategoryHistory({ commit }) {
    commit('setInitCategoryHistory')
  },

  async fetchScrollLocation({ commit }, params) {
    commit('setScrollLocation', params)
  },

  async fetchMarginHeight({ commit }, payload) {
    commit('setMarginHeight', payload)
  },

  async fetchCategoryRefresh({ commit }, payload) {
    commit('setCategoryRefresh', payload)
  },
  async fetchSearchProducts({ commit }, payload) {
    commit('setSearchProducts', payload)
  },
  async fetchCategoryBestSellerHistory(
    { commit },
    payload: { item: ProductItem; index: number }
  ) {
    commit('setCategoryBestSellerHistoryIsLike', payload)
  },
  async fetchCategoryMainScrollLocation({ commit }, payload: number) {
    commit('setCategoryMainScrollLocation', payload)
  },
}
export const CategoryStore: Module<CategoryState, RootState> = {
  namespaced: true,
  state: {
    history: {
      category: {
        init: true,
        categoryItemGroupList: {} as CategoryItemGroup[],
        topBannerItemGroup: {} as CategoryBannerList,
        bottomBannerItemGroup: {} as CategoryBannerList,
        themeItemGroup: {} as ThemeItemGroup,
        seasonHotItemGroup: {
          seasonHotItemList: [],
        },
      },
      topShop: { init: true } as CategoryTopShop,
      page: initCategoryHistory,
      planex: {
        list: [],
        detail: {} as PlanexCategoryDetail,
      },
    },
    categoryPlanex: [],
    pageHistory: initCategoryHistory,
    categoryRefresh: false,
    categoryMainScrollLocation: 0,
  },
  getters,
  mutations: {
    setCategory(state: CategoryState, payload: CategoryMain) {
      state.history.category = { ...payload, init: false }
    },
    setCategoryTopShop(state: CategoryState, payload: CategoryTopShop) {
      state.history.topShop = { ...payload, init: false }
    },
    setCategoryPlanex(state: CategoryState, payload: CategoryPlanex[]) {
      state.history.planex.list = payload
    },
    setCategoryPlanexDetail(
      state: CategoryState,
      payload: PlanexCategoryDetail
    ) {
      state.history.planex.detail = payload
    },
    /////////page history/////////
    setCategoryCode(state: CategoryState, payload: string) {
      state.pageHistory.categoryCode = payload
    },

    setInitCategoryHistory(state: CategoryState) {
      state.history.page = initCategoryHistory
    },

    setScrollLocation(state: CategoryState, payload: number) {
      state.history.page.scrollLocation = payload
    },

    // 기본적으로 page history가 나뉘어져 있는데, 나중에 합쳐할 작업을 해야할 것 같음
    // 일단 양식을 따르고 추후 카테고리 리펙토링 대상
    setMarginHeight(state: CategoryState, payload: number) {
      state.history.page.marginHeight = payload
    },

    setCategoryRefresh(state: CategoryState, payload: boolean) {
      state.categoryRefresh = payload
    },
    setCategoryHistory(state: CategoryState, payload: CategoryHistoryPayload) {
      const { history } = state
      state.history = {
        ...history,
        category: { ...history.category, ...payload.category },
        page: { ...history.page, ...payload.page },
        topShop: { ...history.topShop, ...payload.topShop },
        planex: { ...history.planex, ...payload.planex },
      }
    },
    setSearchProducts(state: CategoryState, payload: ProductItem[]) {
      state.history.page.searchList = payload
    },

    setCategoryBestSellerHistoryIsLike(
      state: CategoryState,
      payload: { item: ProductItem; index: number }
    ) {
      state.history.page.bestSearchList[payload.index] = payload.item
    },
    setCategoryMainScrollLocation(state: CategoryState, payload: number) {
      state.categoryMainScrollLocation = payload
    },
  },
  actions,
}

export interface CategoryState {
  history: CategoryHistory
  categoryPlanex: CategoryPlanex[]
  pageHistory: CategoryPageHistory
  categoryRefresh: boolean
  categoryMainScrollLocation: number
}

export interface CategoryGetters {
  getCategoryHistory: CategoryHistory
  getCategoryItemGroup: CategoryItemGroup[]
  getSeasonHotItemGroup: CategorySeason
  getCategoryTopShop: CategoryTopShop
  getCategoryPlanex: CategoryPlanex[]
  getCategoryTopBannerGroup: CategoryBannerList
  getCategoryBottomBannerGroup: CategoryBannerList
  getCategoryCode: string
  getPageHistory: CategoryHistory
  getScrollLocation: number
  getPromotionNo: number
  getMarginHeight: number
  getSearchProduct: ProductItem[]
  getCategoryMainScrollLocation: number
}

export type CategoryAction = {
  fetchCategoryMain(): Promise<void>
  fetchInitCategoryHistory(): Promise<void>
  fetchCategoryTopShop(): Promise<void>
  fetchCategoryPlanex(): Promise<void>
  fetchCategoryCode(payload: string): Promise<void>
  fetchScrollLocation(payload: { scrollLocation: number }): Promise<void>
  fetchMarginHeight(payload: number): Promise<void>
  fetchCategoryRefresh(payload: boolean): Promise<void>
  fetchCategoryHistory(payload: CategoryHistoryPayload): Promise<void>
  fetchCategoryPlanexDetail(payload: number): Promise<void>
  fetchSearchProducts(payload: ProductItem[]): Promise<void>
  fetchCategoryBestSellerHistory(payload: {
    item: ProductItem
    index: number
  }): Promise<void>
  fetchCategoryMainScrollLocation(payload: number): Promise<void>
}
