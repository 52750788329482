import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { AccountUrl } from '../../plugins/enum'
import { accountApi } from '../../plugins/axios'

export const PostSendOneTimePassword = async (
  data: {
    phone?: string
  },
  params?: AppVersionParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.post<any>(
    AccountUrl.ONE_TIME_AUTH_SEND_ONE_TIME_PASSWORD,
    data,
    {
      ...options,
      params,
    }
  )

export const PostAccessTokenForRegisterByPhone = async (
  data: {
    phone?: string
    otpNumber?: number
  },
  params?: AppVersionParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.post<any>(
    AccountUrl.ONE_TIME_AUTH_ACCESS_TOKEN_FOR_REGISTER_BY_PHONE,
    data,
    {
      ...options,
      params,
    }
  )

export const PostSendEmailLinkForChange = async (
  data: {
    guid?: string
  },
  params?: AppVersionParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.post<any>(
    AccountUrl.ONE_TIME_AUTH_SEND_EMAIL_LINK_FOR_CHANGE,
    data,
    {
      ...options,
      params,
    }
  )

export const PostSigninAndUp = async (
  data: { userType?: number },
  params?: AppVersionParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.post<any>(AccountUrl.SOCIAL_SIGNIN_AND_UP, data, {
    ...options,
    params,
  })

export default {
  PostSendOneTimePassword,
  PostAccessTokenForRegisterByPhone,
  PostSendEmailLinkForChange,
}
