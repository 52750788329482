import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { CategoryUrl } from '../../plugins/enum'
import { displayApi } from '../../plugins/axios'

export const GetCategoryMain = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CategoryMain>> =>
  await displayApi.get<CategoryMain>(CategoryUrl.CATEGORY_MAIN, {
    ...options,
    params,
  })

export const GetCategoryBestSeller = async (
  params: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CategoryBestSeller>> =>
  await displayApi.get<CategoryBestSeller>(CategoryUrl.CATEGORY_BESTSELLER, {
    ...options,
    params,
  })

export const GetCategoryNewArrivals = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CategoryNewArrivals>> =>
  await displayApi.get<CategoryNewArrivals>(CategoryUrl.CATEFORY_NEWARRIVALS, {
    ...options,
    params,
  })

export const GetCategoryTopShop = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CategoryTopShop>> =>
  await displayApi.get<CategoryTopShop>(CategoryUrl.CATEGORY_TOPSHTOP, {
    ...options,
    params,
  })

export const GetCategoryPlanexList = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CategoryPlanex[]>> =>
  await displayApi.get<CategoryPlanex[]>(CategoryUrl.CATEGORY_PLANEX_LIST, {
    ...options,
    params,
  })

export const GetCategoryPlanexDetail = async (
  params: { promotionNo: number },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<PlanexCategoryDetail>> =>
  await displayApi.get<PlanexCategoryDetail>(
    CategoryUrl.CATEGORY_PLANEX_DETAIL,
    { ...options, params }
  )

export const GetCategoryProduct = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CategoryProduct>> =>
  await displayApi.get<CategoryProduct>(CategoryUrl.CATEGORY_PRODUCT, {
    ...options,
    params,
  })

export const GetSeasonProduct = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SeasonProduct>> =>
  await displayApi.get<SeasonProduct>(CategoryUrl.SEASON_PRODUCTS, {
    ...options,
    params,
  })

export default {
  GetCategoryMain,
  GetCategoryBestSeller,
  GetCategoryNewArrivals,
  GetCategoryTopShop,
  GetCategoryPlanexList,
  GetCategoryPlanexDetail,
  GetCategoryProduct,
  GetSeasonProduct,
}
