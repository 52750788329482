<template>
  <svg
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="55" height="55" rx="20" fill="#404346" />
    <path
      d="M18.438 32.968C17.038 32.968 15.946 32.5807 15.162 31.806C14.3873 31.0313 14 29.9253 14 28.488V23.2C14 23.0895 14.0895 23 14.2 23H16.068C16.1785 23 16.268 23.0895 16.268 23.2V28.404C16.268 30.1587 16.996 31.036 18.452 31.036C19.1613 31.036 19.7027 30.826 20.076 30.406C20.4493 29.9767 20.636 29.3093 20.636 28.404V23.2C20.636 23.0895 20.7255 23 20.836 23H22.676C22.7865 23 22.876 23.0895 22.876 23.2V28.488C22.876 29.9253 22.484 31.0313 21.7 31.806C20.9253 32.5807 19.838 32.968 18.438 32.968Z"
      fill="white"
    />
    <path
      d="M31.466 32.8C31.4004 32.8 31.3389 32.7678 31.3016 32.7138L29.5309 30.1562C29.4935 30.1022 29.432 30.07 29.3664 30.07H27.5852C27.4747 30.07 27.3852 30.1595 27.3852 30.27V32.6C27.3852 32.7105 27.2957 32.8 27.1852 32.8H25.3172C25.2067 32.8 25.1172 32.7105 25.1172 32.6V23.2C25.1172 23.0895 25.2067 23 25.3172 23H29.3592C30.2272 23 30.9785 23.1447 31.6132 23.434C32.2572 23.7233 32.7519 24.134 33.0972 24.666C33.4425 25.198 33.6152 25.828 33.6152 26.556C33.6152 27.284 33.4379 27.914 33.0832 28.446C32.7785 28.9071 32.3576 29.2738 31.8204 29.5461C31.7077 29.6032 31.6667 29.7468 31.739 29.8504L33.5778 32.4856C33.6703 32.6181 33.5754 32.8 33.4138 32.8H31.466ZM31.3192 26.556C31.3192 26.0053 31.1419 25.5853 30.7872 25.296C30.4325 24.9973 29.9145 24.848 29.2332 24.848H27.5852C27.4747 24.848 27.3852 24.9375 27.3852 25.048V28.064C27.3852 28.1745 27.4747 28.264 27.5852 28.264H29.2332C29.9145 28.264 30.4325 28.1147 30.7872 27.816C31.1419 27.5173 31.3192 27.0973 31.3192 26.556Z"
      fill="white"
    />
    <path
      d="M35.4121 23.2C35.4121 23.0895 35.5017 23 35.6121 23H37.4801C37.5906 23 37.6801 23.0895 37.6801 23.2V30.752C37.6801 30.8625 37.7697 30.952 37.8801 30.952H42.3941C42.5046 30.952 42.5941 31.0415 42.5941 31.152V32.6C42.5941 32.7105 42.5046 32.8 42.3941 32.8H35.6121C35.5017 32.8 35.4121 32.7105 35.4121 32.6V23.2Z"
      fill="white"
    />
  </svg>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({})
</script>
