<template>
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="19" cy="19" r="19" fill="#F5F7FA" />
    <g clip-path="url(#clip0_11334_28900)">
      <path
        d="M23.3175 10.3635H14.6811C14.0849 10.3635 13.6016 10.8469 13.6016 11.4431V26.5567C13.6016 27.1529 14.0849 27.6362 14.6811 27.6362H23.3175C23.9137 27.6362 24.397 27.1529 24.397 26.5567V11.4431C24.397 10.8469 23.9137 10.3635 23.3175 10.3635Z"
        stroke="#222222"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1406 13.3323H23.8565"
        stroke="#222222"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1406 24H23.8565"
        stroke="#222222"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="18.9941" cy="25.8455" r="0.5" fill="#222222" />
    </g>
    <defs>
      <clipPath id="clip0_11334_28900">
        <rect
          width="25.9091"
          height="25.9091"
          fill="white"
          transform="translate(6.04492 6.04541)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({})
</script>
