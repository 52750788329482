<template>
  <svg
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="55" height="55" rx="20" fill="#F9E000" />
    <g clip-path="url(#clip0_11334_31871)">
      <path
        d="M28 16C20.82 16 15 20.5887 15 26.25C15 29.91 17.4337 33.1213 21.0937 34.935C20.895 35.6213 19.8137 39.3525 19.7712 39.6462C19.7712 39.6462 19.745 39.8663 19.8875 39.95C20.03 40.0338 20.1975 39.9688 20.1975 39.9688C20.6062 39.9112 24.94 36.8675 25.69 36.3388C26.44 36.445 27.2112 36.5 27.9987 36.5C35.1787 36.5 40.9987 31.9113 40.9987 26.25C41 20.5887 35.18 16 28 16Z"
        fill="#3B1E1E"
      />
    </g>
    <defs>
      <clipPath id="clip0_11334_31871">
        <rect
          width="26"
          height="23.9925"
          fill="white"
          transform="translate(15 16)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({})
</script>
