import { RootState } from '@/store/RootState'
import { Module } from 'vuex'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import {
  GetLastNoticeTime,
  GetUserProfileForMyPage,
} from '@/api/displayAPI/MyPageAPI'
import { GetSettings } from '@/api/accountAPI/UsersAPI'
import { UserData } from '@/util/mypage'
import { logout } from '@/plugins/util/logout'

const getters: GetterTreeAdaptor<MyPageGetters, MyPageState, RootState> = {
  getUserProfile(state: MyPageState): UserProfileGroup {
    return state.userInfo.userProfile
  },
  getLikeItemGroup(state: MyPageState) {
    return state.userInfo.likeItemGroup
  },
  getShopItemGroup(state: MyPageState) {
    return state.userInfo.shopItemGroup
  },
  getUserSetting(state: MyPageState) {
    return state.userSetting
  },
  getLastNoticeDate(state: MyPageState) {
    return state.lastRegDt
  },
}

const actions: ActionTreeAdaptor<MyPageAction, MyPageState, RootState> = {
  async asyncGetMyPage({ commit }) {
    try {
      const { data } = await GetUserProfileForMyPage()
      // 애플 유저 & 네이버 유저 확인
      if (
        !(
          data?.userProfile.userType === 1003 ||
          data?.userProfile.userType === 1004
        )
      ) {
        await logout()
        return
      }

      commit('setMyPage', data)
    } catch (e) {
      commit('setMyPage', UserData)
      console.log('e', e)
    }
  },
  async asyncGetSettings({ commit }) {
    try {
      const { data } = await GetSettings()
      commit('setUserSetting', data)
    } catch (e) {
      console.log('e', e)
    }
  },
  async fetchLastNoticeDate({ commit }) {
    try {
      const { data } = await GetLastNoticeTime()
      commit('setLastNoticeTime', data)
    } catch (e) {
      console.log('e', e)
    }
  },
  async fetchOnSkeleton({ commit }, yn) {
    commit('setOnSkeleton', yn)
  },
}

export const MyPageStore: Module<MyPageState, RootState> = {
  namespaced: true,
  state: {
    // TODO : userStore info와 중복 불필요하다 느낌 확인 후 제거
    userInfo: UserData,
    userSetting: {
      version: '',
      pushYN: '',
      adYN: '',
      isLatestVersion: false,
    },
    lastRegDt: '',
    onSkeleton: false,
  },
  getters,
  mutations: {
    setMyPage(state: MyPageState, userInfo: UserGroup) {
      state.userInfo = userInfo
    },
    setUserSetting(state: MyPageState, payload: Object) {
      state.userSetting = payload
    },
    setLastNoticeTime(
      state: MyPageState,
      payload: { lastRegDt: string | null }
    ) {
      state.lastRegDt = payload.lastRegDt
    },
    setOnSkeleton(state: MyPageState, payload: boolean) {
      state.onSkeleton = payload
    },
  },
  actions,
}

export interface MyPageState {
  userInfo: UserGroup
  userSetting: any
  onSkeleton: boolean
  lastRegDt: string | null
}

export interface MyPageGetters {
  getUserProfile: UserProfileGroup
  getLikeItemGroup: LikeItemGroup
  getShopItemGroup: ShopItemGroup
  getUserSetting: Object
  getLastNoticeDate: string | null
}

export type MyPageAction = {
  asyncGetMyPage: (payload: { delayMs: number }) => Promise<void>
  asyncGetSettings: (payload: { delayMs: number }) => Promise<void>
  fetchLastNoticeDate: () => Promise<void>
  fetchOnSkeleton: (payload: boolean) => Promise<void>
}
