<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    transition="scroll-x-reverse-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="d-flex justify-center align-center"
        style="width: 295px; height: 50px"
        v-bind="attrs"
        v-on="on"
      >
        <span
          class="font-g14-20 rixgo-bold white--text text-decoration-underline"
        >
          계정 정보를 잊으셨나요? 계정찾기
        </span>
      </div>
    </template>
    <v-card>
      <div>
        <v-toolbar light color="white" outlined flat>
          <v-btn icon light color="black" @click="dialog = false">
            <v-icon small class="">$vuetify.icons.prevIcon</v-icon>
          </v-btn>
          <v-toolbar-title></v-toolbar-title>
          <v-spacer align="center" class="font-g15-16 rixgo-bold ml-4"
            >계정찾기</v-spacer
          >
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false"> Save </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="line">
          <MobileAuthentication @executeLoginDialog="executeLoginDialog" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import MobileAuthentication from '../login/MobileAuthentication.vue'
export default {
  name: 'FindAccount',
  components: { MobileAuthentication },
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    }
  },
  methods: {
    executeLoginDialog() {
      this.dialog = false
    },
  },
}
</script>
<style lang="scss" scoped>
.line {
  border-bottom: 1px solid #f4f4f4;
}
</style>
