<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogAlert"
      max-width="300"
      style="z-index: 100"
      class="rounded-0"
    >
      <v-card>
        <div class="pt-18 pl-12 pr-12">
          <div class="pb-20">
            <div class="font-g15-16 rixgo-regular gray700--text pb-10">
              {{ alertText }}
            </div>
            <div
              v-if="alertDescription !== ''"
              class="font-g14-15 rixgo-regular gray500--text"
            >
              {{ alertDescription }}
            </div>
          </div>
          <div class="text-end pb-9">
            <v-btn
              width="84"
              class="font-g13-14 rixgo-bold blue white--text"
              rounded
              text
              @click="alertConfirmClick"
            >
              확인
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'DialogAlert',
  props: {
    dialogAlert: {
      type: Boolean,
      default: false,
    },
    alertText: {
      type: String,
      default: '',
    },
    alertDescription: {
      type: String,
      default: '',
    },
  },
  methods: {
    alertConfirmClick() {
      this.$emit('alertConfirmClick')
    },
  },
}
</script>
<style lang="scss" scoped>
.line {
  border-bottom: 1px solid #f4f4f4;
}
</style>
