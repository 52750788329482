
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
// Pull To Refresh Height
const MAX_HEIGHT = 120
// 중복 터치 이벤트 체크 Time
const OVERLAP_PREVENT_TIMER = 100
// Refresh 동작 시, 잠깐 동안 Pending 시킬 Time
const PENDING_TIMER = 400
// Touch Event Out Check Time
// 디바이스 밖으로 Touch event 감지가 되지 않을 경우가 존재 해당 이슈에 대한 보완처리
const TOUCH_EVENT_OUT_VALID_TIME = 1000
// 참조 페이지
// https://medium.com/@i_am_root/pull-to-refresh-%EC%A7%81%EC%A0%91-%EA%B5%AC%ED%98%84%ED%95%B4%EB%B3%B4%EC%9E%90-4253b3ff8bac
export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    prevent: {
      type: Boolean,
      default: false,
    },
    popupPath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      loadingIcon: false,
      touchStartY: 0,
      touchStartX: 0,
      loadingHeight: 0,
      timer: null,
      count: 0,
      reloadYn: false,
      multiTouchTimer: null,
      touchOutValidTimer: null,
    }
  },
  watch: {
    loading(yn) {
      // refresh touch event가 발생하는 동안 scroll event 방지하기 위함
      if (yn) this.$getAppHtml.style.overflowY = 'hidden'
      else this.$getAppHtml.style.overflowY = 'scroll'
      this.$emit('pullToState', yn)
    },
  },
  computed: {
    ...mapState('PullToRefreshStore', ['touchless']),
    ...mapState('BannerStore', ['onBanner']),
    topPadding() {
      const path = this.$route.path
      if (path.startsWith('/category') && path !== '/categoryMain') return ''
      if (path === '/categoryMain') return 'category-header-margin-top'
      if (path === '/storeHome') return ''
      if (path === '/dibs') return ''
      return 'mt-20'
    },
  },
  methods: {
    ...mapActions('PullToRefreshStore', ['fetchRefreshNoti']),
    async handleTouchMove(e: TouchEvent) {
      //  prevent : Props로 건네받을 경우 / touchless : Store로 건네받을 경우 - 편한 거 쓰는 것이 좋을 듯
      if (this.prevent || this.touchless) return
      if (!this.$isMobile()) return
      // 최초 start는 carousel을 사용할 경우 체크할 수 없어 move 최초 이벤트를 활용
      if (this.$getAppHtml.scrollTop > 0) return
      if (this.touchStartY === 0 || this.touchStartX === 0) {
        this.touchStartY = e.changedTouches[0].clientY
        this.touchStartX = e.changedTouches[0].pageX
        return
      }

      // Touch timer 초기화
      this.clearMultiTouchTimer()
      this.clearTouchOutValidTimer()

      const touchEvent = e.changedTouches[0]
      const screenY = touchEvent.clientY
      // 아래로 댕길때 && 높이 100px 이상
      if (screenY <= this.touchStartY + 100) return
      this.loading = true

      // reload 위치 선정
      if (screenY - this.touchStartY > 0) {
        // 나중에 제한 없이 배민처럼 하면 재밌을 듯
        if (MAX_HEIGHT >= screenY - this.touchStartY) {
          this.reloadYn = false
          this.loadingHeight = screenY - this.touchStartY
        } else {
          this.reloadYn = true
          this.loadingHeight = MAX_HEIGHT
        }

        // Icon Size가 될 시에만 표시가 자연스러움
        // z-index에 따라 잘려보이는게 더 좋을 수도? 조금 더 자연스럽게 할 필요성 있음
        if (this.loadingHeight >= 32) this.loadingIcon = true
        else this.loadingIcon = false
      }

      this.touchOutValidTimer = setTimeout(() => {
        this.handleTouchEnd()
      }, TOUCH_EVENT_OUT_VALID_TIME)
    },
    async handleTouchEnd() {
      if (!this.$isMobile()) return
      this.multiTouchTimer = setTimeout(() => {
        if (this.reloadYn) {
          this.touchStartY = 0
          this.touchStartX = 0
          this.reloadYn = false
          this.loadingHeight = MAX_HEIGHT
          setTimeout(async () => {
            sessionStorage.setItem('appInit', 'N')
            await this.handleRefresh()
            this.fetchRefreshNoti()
            this.loading = false
            this.loadingHeight = 0
          }, PENDING_TIMER)
        } else {
          this.reloadYn = false
          this.touchStartY = 0
          this.touchStartX = 0
          this.loadingHeight = 0
          this.loading = false
        }
      }, OVERLAP_PREVENT_TIMER)
    },
    handleRefresh() {
      this.$emit('handleRefresh')
    },
    clearTouchOutValidTimer() {
      if (this.touchOutValidTimer) {
        clearTimeout(this.touchOutValidTimer)
        this.touchOutValidTimer = null
      }
    },
    clearMultiTouchTimer() {
      if (this.multiTouchTimer) {
        clearTimeout(this.multiTouchTimer)
        this.multiTouchTimer = null
      }
    },
  },
})
interface Data {
  loading: boolean
  touchStartY: number
  touchStartX: number
  loadingHeight: number
  timer: number | null
  reloadYn: boolean
  multiTouchTimer: number | null
  touchOutValidTimer: number | null
  loadingIcon: boolean
}
interface Methods {
  handleTouchMove: (e: TouchEvent) => void
  handleTouchEnd: () => void
  handleRefresh: () => void
  fetchRefreshNoti: () => void
  clearTouchOutValidTimer: () => void
  clearMultiTouchTimer: () => void
}
interface Computed {
  topPadding: string
  touchless: boolean
  onBanner: boolean
}
interface Props {
  prevent: boolean
  popupPath: string
}
