export const AllItemsData: AllItemsGroup = {
  titleImage: null,
  displayType: 0,
  title: '',
  subTitle: '',
  searchProductList: [],
}

export const BestSellerData: BestSellerGroup = {
  titleImage: null,
  displayType: 0,
  title: '',
  subTitle: '',
  bestSellerItemList: [{ id: 0, title: '', searchProductList: [] }],
}

export const NewArrivalsData: NewArrivalsItemGroup = {
  display: 0,
  title: '',
  subTitle: '',
  displayCount: 0,
  titleImage: null,
  newArrivalsItemList: [
    {
      titleImage: null,
      icon: '',
      id: 0,
      productCount: 0,
      shopId: '',
      shopName: '',
      title: '',
      searchProductList: [],
    },
  ],
}

export const PlanexData: PlanexItemGroup = {
  displayType: 0,
  title: '',
  planexItemList: [{ link: '', subTitle: '', title: '' }],
}

export const TabMenuData: TabMenuItemGroup = {
  displayType: 0,
  title: '',
  tabMenuItemList: [{ tabType: 0, link: '', title: '' }],
}

export const TopShopData: TopShopItemGroup = {
  titleImage: null,
  displayType: 0,
  title: '',
  subTitle: '',
  updDt: '',
  categoryTopShopButtonBottom: '',
  topShopItemList: [],
}

export const TrendData: TrendItemGroup = {
  titleImage: null,
  displayType: 0,
  title: '',
  subTitle: '',
  trendButtonList: [],
  trendHotRankKeywordList: [],
  trendThesedayRiseKeywordList: [],
}

export const ShoppingLiveItemGroup: ShoppingLiveItemGroup = {
  titleImage: null,
  title: '',
  subTitle: '',
  displayType: 0,
}

export const MyCollectionData: MyCollectionItemGroup = {
  displayType: 0,
  title: '',
  subTitle: '',
  searchProductList: [],
}

export const DisplayData: DisplayGroup = {
  allItemsGroup: AllItemsData,
  bestSellerItemGroup: BestSellerData,
  newArrivalsItemGroup: NewArrivalsData,
  planexItemGroup: PlanexData,
  tabMenuItemGroup: TabMenuData,
  topShopItemGroup: TopShopData,
  trendItemGroup: TrendData,
  myCollectionItemGroup: MyCollectionData,
  shoppingLiveItemGroup: ShoppingLiveItemGroup,
}
