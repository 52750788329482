import { RootState } from '@/store/RootState'
import { mapActions, mapState, Module, mapGetters } from 'vuex'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import { GetView } from '../api/displayAPI/NoticeAPI'
import { NoticeData } from '../util/notice'

const STORE_NAME = 'NoticeStore'

const getters: GetterTreeAdaptor<NoticeGetters, NoticeState, RootState> = {
  getNoticeViewOne(state: NoticeState) {
    return state.notice.noticeViewList
  },
  getNoticeViewOnePaging(state: NoticeState) {
    return state.notice.paging
  },
  getNoticeViewOneDetail(state: NoticeState) {
    return state.notice.noticeDetail
  },
}

const actions: ActionTreeAdaptor<NoticeAction, NoticeState, RootState> = {
  async asyncGetNotice({ commit }) {
    try {
      const { data } = await GetView()
      commit('setNotice', data)
    } catch (e) {
      console.log('e', e)
    }
  },
}

export const NoticeStore: Module<NoticeState, RootState> = {
  namespaced: true,
  state: {
    notice: NoticeData,
  },
  getters,
  mutations: {
    setNotice(state: NoticeState, notice: NoticeGroup) {
      state.notice = notice
    },
  },
  actions,
}

export const mappedNoticeState = mapState(STORE_NAME, ['notice'])
export const mappedNoticeAction = mapActions(STORE_NAME, ['asyncGetNotice'])
export const mappedCountGetters = mapGetters(STORE_NAME, [
  'getNoticeViewOne',
  'getNoticeViewOnePaging',
  'getNoticeViewOneDetail',
])

export interface NoticeState {
  notice: NoticeGroup
}

export interface NoticeGetters {
  getNoticeViewOne: NoticeView
  getNoticeViewOnePaging: NoticeViewPaging
  getNoticeViewOneDetail: NoticeViewDetail
}

export type NoticeAction = {
  asyncGetNotice: (payload: { delayMs: number }) => Promise<void>
}
