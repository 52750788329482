
import Vue from 'vue'
import { mapState } from 'vuex'
import FlipBanner from '@/components/common/layout/FlipBanner.vue'
import CommonHeader from '@/components/common/layout/CommonHeader.vue'
import { needAnimHeaderNames, showFlipBannerNames } from '@/helper/banner'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { FlipBanner, CommonHeader },
  props: {
    onBanner: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showMainHeader: false,
      needAnimHeader: false,
      showFlipBanner: false,
      needShowLogoImg: false,
    }
  },
  computed: {
    ...mapState('HomeStore', ['isNotFirstLottiePlay']),
    ...mapState('BannerStore', ['flipBanner']),
    headerAnimClass() {
      //애니메이션이 필요 없으면 헤더 고정처리
      if (!this.needAnimHeader) {
        return 'common-header-none'
      }
      return this.onBanner ? 'common-header-visible' : 'common-header-animate'
    },
  },
  created() {
    this.needShowLogoImg = this.isNotFirstLottiePlay
  },
  watch: {
    $route(val) {
      this.showMainHeader = [
        'Main',
        'Dibs',
        'MyPage',
        'RecentlyProduct',
        'CategoryMain',
      ].some((item) => item === val.name)

      this.needAnimHeader = needAnimHeaderNames.some(
        (item) => item === val.name
      )

      this.showFlipBanner = showFlipBannerNames.some(
        (item) => item === val.name
      )
    },
  },
})

interface Data {
  showMainHeader: boolean
  needAnimHeader: boolean
  showFlipBanner: boolean
  needShowLogoImg: boolean
}
interface Methods {}
interface Computed {
  flipBanner: boolean
  isNotFirstLottiePlay: boolean
  headerAnimClass: string
}
interface Props {
  onBanner: boolean
}
