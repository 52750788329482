import Vue from 'vue'
import Store from '@/store/index'
import { openSubWebView } from '@/plugins/util/appInterface'
import { isMobile } from '@/plugins/util'
import VueRouter from 'vue-router'
import DeviceDetector from 'device-detector-js'

export const rendingOutsidePage = (
  device: IDeviceDetector,
  payload: RendingParam
) => {
  const { browser_name } = device
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isApp = Store.state.DeviceStore.deviceInfo

  if (isApp) return openSubWebView(payload)

  // Mobile Web 접속 & Safari 접속 시
  if (
    (!isApp && isMobile()) ||
    browser_name === 'Safari' || // PC
    browser_name.startsWith('Chrome Mobile')
  ) {
    history.pushState(null, 'null', location.href)
    window.location.replace(payload.url)
  } else {
    // PC Web 접속, Safari 제외
    window.open(payload.url, '_blank')
  }
}
export const rendingOutsidePageWithoutDevice = (payload: RendingParam) => {
  const deviceDetector = new DeviceDetector()
  const userAgent = window.navigator.userAgent
  const browserName = deviceDetector.parse(userAgent).client?.name ?? ''

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isApp = Store.state.DeviceStore.deviceInfo

  if (isApp) return openSubWebView(payload)

  // Mobile Web 접속 & Safari 접속 시
  if (
    (!isApp && isMobile()) ||
    browserName === 'Safari' || // PC
    browserName.startsWith('Chrome Mobile')
  ) {
    history.pushState(null, 'null', location.href)
    window.location.replace(payload.url)
  } else {
    // PC Web 접속, Safari 제외
    window.open(payload.url, '_blank')
  }
}

export const rendingOutsidePageOrInternal = (
  router: VueRouter,
  device: IDeviceDetector,
  payload: RendingParam
) => {
  const url = payload.url
  if (url?.includes('https') || url?.includes('http')) {
    const linkUrl = new URL(url)
    const internalURL = new URL(process.env.VUE_APP_CELLOOK_URL)

    //우리 URL 이 아닌경우
    if (linkUrl.host !== internalURL.host) {
      rendingOutsidePage(device, payload)
    }
    return
  }

  try {
    router.push(url)
  } catch (e) {
    console.error(e)
  }
}

Vue.prototype.$rendingOutsidePage = rendingOutsidePage
Vue.prototype.$rendingOutsidePageOrInternal = rendingOutsidePageOrInternal
