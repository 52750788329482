import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { UserUrl } from '@/plugins/enum'
import { displayApi } from '@/plugins/axios'
import store from '@/store'

export const GetSubscribeCheck = async (
  params: { shopId: string }, // 콤마로 구분된 string 을 넣을 수 있음 ex) 1,2,3
  options?: AxiosRequestConfig
): Promise<AxiosResponse<SubscribeShopCheckList>> =>
  await displayApi.get<any>(UserUrl.SUBSCRIBE_CHECK, { ...options, params })

export const PostSubscribe = async (
  shopId: string,
  mallName: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  const data = {
    shopId: shopId,
    userEventData: userEventDataForSubscribe(mallName),
  }
  return displayApi.post<any>(UserUrl.SUBSCRIBE_FOR_USER_EVENT, data, options)
}

export const DeleteSubscribe = async (
  shopId: string,
  mallName: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  const data = { userEventData: userEventDataForSubscribe(mallName) }

  return await displayApi.delete<any>(
    `${UserUrl.SUBSCRIBE_FOR_USER_EVENT}/${shopId}`,
    {
      ...options,
      data,
    }
  )
}
function userEventDataForSubscribe(mallName: string): UserEventData {
  return {
    strData1: mallName,
    uuid: store?.state?.DeviceStore?.deviceInfo?.uid,
    appVersion: store?.state?.DeviceStore?.deviceInfo?.appVer,
    platform: store.state.DeviceStore.deviceInfo?.os,
  }
}
export const GetLikeProduct = async (
  params?: { productNo: string },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<LikeProduct>> =>
  await displayApi.get<LikeProduct>(UserUrl.LIKE_ITEM_CHECK, {
    ...options,
    params,
  })

export const GetLike = async (
  params?: { productNo: string },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<LikeProduct>> =>
  await displayApi.get<LikeProduct>(UserUrl.LIKE_ITEM, {
    ...options,
    params,
  })

function userEventData(
  product?: MdPickShopSubItem | ProductItem
): UserEventData {
  return {
    productNo: product?.productNo,
    price: product?.discountPrice,
    strData1: product?.shopName ?? product?.mallName,
    strData2: product?.category1,
    strData3: product?.category2,
    uuid: store?.state?.DeviceStore?.deviceInfo?.uid,
    appVersion: store?.state?.DeviceStore?.deviceInfo?.appVer,
    platform: store.state.DeviceStore.deviceInfo?.os,
  }
}

export const PostLikeProduct = async (
  product?: MdPickShopSubItem | ProductItem,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  const payload = {
    productNo: product?.productNo,
    userEventData: userEventData(product),
  }

  return await displayApi.post<any>(
    UserUrl.LIKE_ITEM_FOR_USER_EVENT,
    payload,
    options
  )
}

export const DeleteLikeProduct = async (
  products: MdPickShopSubItem[] | ProductItem[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  const data = []

  for (const element of products) {
    const eventData = userEventData(element)
    data.push({ productNo: element.productNo, userEventData: eventData })
  }

  return displayApi.delete<any>(UserUrl.LIKE_ITEM_FOR_USER_EVENT, {
    ...options,
    data: { deleteLikeList: data },
  })
}

export const PutSubscribePin = async (
  params: { shopId?: number; isPin?: boolean },
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await displayApi.put<any>(UserUrl.SUBSCRIBE_PIN, params, options)

export const GetRecentlyView = async (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<any>> =>
  await displayApi.get<LikeProduct>(UserUrl.RECENTLY_VIEW, options)

export const PostRecentlyView = async (
  data: { productNo: string },
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await displayApi.post<any>(UserUrl.RECENTLY_VIEW, data, options)

export const DeleteRecentlyView = async (
  path: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await displayApi.delete<any>(`${UserUrl.RECENTLY_VIEW}/${path}`, options)

export const DeleteAllRecentlyView = async (
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await displayApi.delete<any>(`${UserUrl.RECENTLY_VIEW}/all`, options)

export default {
  GetSubscribeCheck,
  PostSubscribe,
  DeleteSubscribe,
  PostLikeProduct,
  DeleteLikeProduct,
  PutSubscribePin,
  GetLike,
  GetRecentlyView,
  PostRecentlyView,
  DeleteRecentlyView,
  DeleteAllRecentlyView,
}
