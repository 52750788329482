import { RootState } from '@/store/RootState'
import { mapActions, mapState, Module } from 'vuex'
import { ActionTreeAdaptor } from '@/util'

const STORE_NAME = 'BottomSheetStore'

const actions: ActionTreeAdaptor<
  BottomSheetStoreAction,
  BottomSheetStoreState,
  RootState
> = {
  async fetchOnSheet({ commit }, payload) {
    commit('setOnSheet', payload)
  },
  async fetchOffSheet({ commit }) {
    commit('setOffSheet')
  },
}

export const BottomSheetStore: Module<BottomSheetStoreState, RootState> = {
  namespaced: true,
  state: {
    showSheet: false,
    type: 1,
    title: '몰',
  },
  mutations: {
    setOnSheet(
      state: BottomSheetStoreState,
      payload: { type: number; title: string }
    ) {
      state.showSheet = true
      state.type = payload.type
      state.title = payload.title
    },
    setOffSheet(state: BottomSheetStoreState) {
      state.showSheet = false
      state.type = 1
      state.title = '몰'
    },
  },
  actions,
}

export interface BottomSheetStoreState {
  showSheet: boolean
  type: number
  title: string
}

export type BottomSheetStoreAction = {
  fetchOnSheet: (payload: { type: number; title: string }) => Promise<void>
  fetchOffSheet: () => Promise<void>
}
