import { RootState } from '@/store/RootState'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import { Module } from 'vuex'
import { GetUserProfile, SetUserLogout } from '../api/accountAPI/UsersAPI'
import store from './index'
import { BannerGetters } from '@/store/BannerStore'

const state: UserState = {
  user: {
    birthYear: '',
    email: '',
    name: '',
    nickName: '',
    phone: '',
    sex: '',
    signUpDt: '',
    userType: null,
  },
}
const getters: GetterTreeAdaptor<UserGetter, UserState, RootState> = {
  isLogined(state: UserState) {
    return state.user.userType !== null
  },
  userEmail(state: UserState) {
    return state.user.email
  },
  userName(state: UserState) {
    return state.user.nickName
  },
}

const mutations = {
  setUser(state: UserState, user: UserViewInfo) {
    state.user = user
  },
  setLogout(state: UserState) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    state.user = {
      birthYear: '',
      email: '',
      name: '',
      nickName: '',
      phone: '',
      sex: '',
      signUpDt: '',
      userType: null,
    }
  },
}

const actions: ActionTreeAdaptor<UserActions, UserState, RootState> = {
  async fetchUserProfile({ commit }) {
    try {
      const { data } = await GetUserProfile()
      commit('setUser', data)
    } catch (e) {
      commit('setUser', {
        birthYear: '',
        email: '',
        name: '',
        nickName: '',
        phone: '',
        sex: '',
        signUpDt: '',
        userType: null,
      })
    }
  },
  async fetchUserLogout({ commit }) {
    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')
    if (accessToken && refreshToken) {
      try {
        store.dispatch('LoadingStore/fetchLoading', { yn: true })
        await SetUserLogout({
          accessToken,
          refreshToken,
        })
        commit('setLogout')
      } catch (e) {
        console.log('logout fail', e)
      } finally {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        store.dispatch('LoadingStore/fetchLoading', { yn: false })
      }
    }
  },
}

export const UserStore: Module<UserState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
export interface UserGetter {
  isLogined: boolean
  userEmail: string
  userName: string
}
export interface UserState {
  user: {
    birthYear: string
    email: string
    name: string
    nickName: string
    phone: string
    sex: string
    signUpDt: string
    userType: number | null // 1003 Naver
  }
}

export type UserActions = {
  fetchUserProfile: (user: UserState) => Promise<void>
  fetchUserLogout: () => Promise<void>
}
