
import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import Lottie from '@/components/common/lottie/LottieView.vue'
import { AnimationItem } from 'lottie-web'
import { destroyLottieAnim } from '@/plugins/util/lottie'
import { onRequestAdonBanner } from '@/helper/banner'

const TAB_RECENT_PRODUCT = 4
const KEY_STORAGE_LOTTIE_RECENT_PRODUCT = 'KEY_STORAGE_LOTTIE_RECENT_PRODUCT'

export default Vue.extend<Data, Methods, Computed>({
  components: { Lottie },
  filters: {
    activeIcon(active: boolean, menu: any) {
      if (active) {
        return menu.activeIcon
      } else {
        return menu.icon
      }
    },
  },
  data() {
    return {
      tab: 0,
      navigationInfo: [
        {
          key: 1,
          name: '홈',
          path: '/',
          icon: '$vuetify.icons.homeIcon',
          activeIcon: '$vuetify.icons.activeHomeIcon',
          tab: 0,
          tracking: 'gnb_home',
        },
        {
          key: 2,
          name: '카테고리',
          path: '/categoryMain',
          icon: '$vuetify.icons.categoryIcon',
          activeIcon: '$vuetify.icons.activeCategoryIcon',
          tab: 1,
          tracking: 'gnb_category',
        },
        {
          key: 3,
          name: '찜',
          path: '/dibs',
          icon: '$vuetify.icons.dibsIcon',
          activeIcon: '$vuetify.icons.activeDibsIcon',
          tab: 2,
          tracking: 'gnb_like',
        },
        {
          key: 4,
          name: '마이페이지',
          path: '/mypage',
          icon: '$vuetify.icons.myPageIcon',
          activeIcon: '$vuetify.icons.activeMyPageIcon',
          tab: 3,
          tracking: 'gnb_mypage',
        },
        {
          key: 5,
          name: '최근본상품',
          path: '/recentlyProduct',
          icon: '$vuetify.icons.recentlyProductIcon',
          activeIcon: '$vuetify.icons.activeRecentlyProductIcon',
          tab: 4,
          tracking: 'gnb_recentview',
        },
      ],
      clickCnt: 0,
      recentlyProductLength:
        localStorage.getItem('recentProducts')?.length ?? 0,
      isShowBottomNaviAnim: false,
      anim: null,
    }
  },
  computed: {
    ...mapState('RecentlyStore', ['recentlyProduct']),
    ...mapState('BottomNaviStore', ['lastTab']),
    ...mapState('DibsStore', ['dibsSearchInfo']),
    ...mapState('CategoryStore', ['categoryRefresh']),
    computedRoute() {
      const path = this.$route.fullPath
      if (
        path.startsWith('/category') ||
        path.startsWith('/exhibitionEvent') ||
        path.startsWith('/promotionSingleMall') ||
        path.startsWith('/promotionMultiMall') ||
        path.startsWith('/singleMallPromotion') ||
        path.startsWith('/promotionList') ||
        path.startsWith('/shopping-live') ||
        path.startsWith('/promotionList') ||
        path.startsWith('/event-list')
      )
        return `/categoryMain`
      if (path.startsWith('/dibs')) return `/dibs`
      if (path.startsWith('/mypage')) return `/mypage`
      if (path.startsWith('/recently')) return `/recentlyProduct`
      if (path.startsWith('/storeHome')) return this.storeHomeRoute()
      return `/`
    },
  },
  watch: {
    '$route.path'() {
      this.activeTab()
    },
  },
  async created() {
    this.activeTab()

    const animStatus = sessionStorage.getItem(KEY_STORAGE_LOTTIE_RECENT_PRODUCT)
    console.log('##', this.recentlyProductLength, animStatus)
    if (this.recentlyProductLength && !animStatus) {
      this.isShowBottomNaviAnim = true
      sessionStorage.setItem(KEY_STORAGE_LOTTIE_RECENT_PRODUCT, 'true')
    }
  },
  beforeDestroy() {
    destroyLottieAnim(this.anim)
  },
  methods: {
    ...mapMutations('BottomNaviStore', ['setLastTab']),
    ...mapActions('DisplayStore', ['asyncGetDisplay']),
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    ...mapActions('HomeStore', ['fetchInit', 'fetchInitScroll']),
    ...mapActions('CategoryStore', [
      'fetchCategoryMain',
      'fetchCategoryRefresh',
    ]),
    ...mapActions('MyPageStore', [
      'asyncGetMyPage',
      'asyncGetSettings',
      'fetchLastNoticeDate',
    ]),
    ...mapActions('DibsStore', [
      'asyncGetDibsSubscribe',
      'fetchRefresh',
      'fetchDibsSearchInfo',
    ]),
    ...mapActions('RecentlyStore', ['fetchBestSeller']),
    ...mapActions('RecentlyStore', ['fetchRecentlyProduct']),
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('RouteHistoryStore', ['fetchPreviousPageScroll']),

    async onMoveToMenu(path: string, tab: number) {
      sessionStorage.setItem('appInit', 'N')
      this.$tracking(this.navigationInfo[tab].tracking)

      if (this.$route.path === '/storeHome') {
        this.$router.push(path)
      }
      if (
        this.$route.path === '/promotionSignUp' ||
        this.$route.path === '/promotionSignUpSeptember' ||
        this.$route.path === '/promotionSignUp2' ||
        this.$route.name === 'Event'
      ) {
        this.$router.push('/')
      }

      if (tab == TAB_RECENT_PRODUCT) {
        this.isShowBottomNaviAnim = false
        destroyLottieAnim(this.anim)
        sessionStorage.setItem(KEY_STORAGE_LOTTIE_RECENT_PRODUCT, 'false')
      }
      // 현재 같은 페이지로 이동 시 에러
      // 추후 같은 페이지일 시에 해당 페이지에 대한 api request or dispatch store
      if (path === '/' && this.computedRoute === '/') {
        onRequestAdonBanner('Main')
        this.fetchInit()
        this.fetchInitScroll()
        this.asyncGetDisplay()
        this.$vuetify.goTo('#app', { container: this.$getAppHtml })
        return
      }
      // categoryMain 에서 categoryMain 누루면
      if (path === '/categoryMain' && this.$route.path === '/categoryMain') {
        try {
          await this.fetchLoading({ yn: true })
          await this.fetchCategoryMain()
          onRequestAdonBanner('CategoryMain')
          this.fetchCategoryRefresh(!this.categoryRefresh)

          this.fetchPreviousPageScroll({
            name: '/categoryMain',
            scrollTop: 0,
          })
          return
        } catch (error) {
          console.log(error)
        } finally {
          await this.fetchLoading({ yn: false })
        }
      }

      if (path === '/mypage' && this.computedRoute === '/mypage') {
        try {
          await this.fetchLoading({ yn: true })
          await this.fetchLastNoticeDate()
          this.$getAppHtml.scrollTo({ top: 0 })
          if (this.isLogined) {
            await this.asyncGetMyPage()
            await this.asyncGetDibsSubscribe()
            return
          }
        } catch (error) {
          console.log(error)
        } finally {
          await this.fetchLoading({ yn: false })
        }
      }

      // 최근본상품에서 최근본상품 다시 눌렀을때
      if (
        path === '/recentlyProduct' &&
        this.computedRoute === '/recentlyProduct'
      ) {
        onRequestAdonBanner('RecentlyProduct')
        await this.fetchBestSeller()
        await this.fetchRecentlyProduct()
        this.$getAppHtml.scrollTo({ top: 0 })
        return
      }

      //찜 탭을 눌러서 로그인 시켜야 할 때
      if (path === '/dibs' && !this.isLogined) {
        localStorage.setItem('redirectPath', '/dibs')
        await this.fetchLoginDialog({ yn: true })
        return
      }

      //찜 탭에서 찜 다시 눌렀을때
      if (path === '/dibs' && this.computedRoute === '/dibs') {
        onRequestAdonBanner('Dibs')
        await this.fetchDibsSearchInfo({
          ...this.dibsSearchInfo,
          availableFlag: false,
          saleFlag: false,
        })
        await this.fetchRefresh(true)
        this.$getAppHtml.scrollTo({ top: 0 })
        return
      }

      this.setLastTab(tab)
      await this.$router.push(path)
    },
    activeTab() {
      const findMenu = this.navigationInfo.filter(
        (item: MenuItem) => item.path === this.computedRoute
      )
      if (findMenu.length > 0) {
        this.setLastTab(findMenu[0].tab)
      }
    },
    needShowRecentViewLottie(index: number) {
      return (
        index === this.navigationInfo.length - 1 &&
        this.recentlyProductLength > 0 &&
        this.isShowBottomNaviAnim
      )
    },
    storeHomeRoute() {
      if (this.lastTab === -1) {
        return '/'
      }

      return (
        this.navigationInfo.find((value) => {
          return value.tab === this.lastTab
        })?.path ?? '/'
      )
    },
    handleAnim(anim: AnimationItem | null) {
      this.anim = anim
    },
  },
})
interface MenuItem {
  key: number
  name: string
  path: string
  icon: string
  activeIcon: string
  tab: number
  tracking: string
}
interface Data {
  tab: number
  navigationInfo: MenuItem[]
  clickCnt: number
  recentlyProductLength: number
  isShowBottomNaviAnim: boolean
  anim: AnimationItem | null
}
interface Methods {
  asyncGetDisplay(): void
  onMoveToMenu(path: string, tab: number): void
  fetchLoginDialog(payload: { yn: boolean }): void
  activeTab(): void
  fetchBestSeller(): Promise<void>
  fetchRecentlyProduct(): Promise<void>
  fetchInit(): Promise<void>
  fetchInitScroll(): Promise<void>
  needShowRecentViewLottie(index: number): void
  setLastTab(tab: number): void
  storeHomeRoute(): string
  asyncGetMyPage(): Promise<void>
  asyncGetDibsSubscribe(): Promise<void>
  fetchCategoryMain(): Promise<void>
  fetchLoading(payload: { yn: boolean }): Promise<void>
  fetchRefresh(payload: boolean): void
  handleAnim(anim: AnimationItem | null): void
  fetchDibsSearchInfo(payload: DibsSearchInfo): void
  fetchLastNoticeDate(): Promise<void>
  fetchCategoryRefresh: (payload: boolean) => Promise<void>
  fetchPreviousPageScroll: (payload: {
    name: string
    scrollTop: number
  }) => Promise<void>
}

interface Computed {
  recentlyProduct: ProductItem[]
  computedRoute: string
  lastTab: number
  dibsSearchInfo: DibsSearchInfo
  categoryRefresh: boolean
}
