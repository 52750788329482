
import Vue from 'vue'

export default Vue.extend<Data, Computed, Methods, Props>({
  name: 'TitleSubscribeShareHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    isTitleCenter: {
      type: Boolean,
      default: false,
    },
    shareIconVisible: {
      type: Boolean,
      default: true,
    },
    isSubscribeProps: {
      type: Boolean,
      default: false,
      required: true,
    },
    isSubscribeShow: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    goBack() {
      this.$emit('goBack')
    },
    share() {
      this.$emit('share')
    },
    subscribe() {
      this.$emit('subscribe')
    },
  },
})

interface Computed {}

interface Data {}

interface Methods {
  goBack(): void
  share(): void
  subscribe(): void
}
interface Props {
  title: String
  shareIconVisible: Boolean
  isSubscribeProps: boolean
  isSubscribeShow: boolean
  isTitleCenter: boolean
}
