import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { AccountUrl } from '../../plugins/enum'
import { accountApi } from '../../plugins/axios'

export const PostAuthorization = async (
  data: {
    userType?: number
  },
  params?: AppVersionParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.post<any>(AccountUrl.SOCIAL_AUTHORIZATION, data, {
    ...options,
    params,
  })

export const PostRegister = async (
  data: {
    userEventData?: {
      uuid?: string
      relationId?: number
      platform?: string
      version?: string
      appVersion?: string
      ip?: string
      eventType?: string
      actionType?: string
      productNo?: string
      mallId?: string
      price?: number
      strData1?: string
      strData2?: string
      strData3?: string
      numData1?: number
      numData2?: number
      numData3?: number
    }
    userType?: number
  },
  params?: AppVersionParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.post<any>(AccountUrl.SOCIAL_REGISTER, data, {
    ...options,
    params,
  })

export const PutDisconnet = async (
  data: { userType?: number },
  params?: AppAccessParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AccountCommonResponse>> =>
  await accountApi.put<AccountCommonResponse>(
    AccountUrl.SOCIAL_DISCONNECT,
    data,
    {
      ...options,
      params,
    }
  )

export const PostLoginApple = async (
  params?: AppVersionParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.post<any>(AccountUrl.SOCIAL_LOGIN_APPLE, null, {
    ...options,
    params,
  })

export const GetLoginApple = async (
  params?: AppVersionParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.post<any>(AccountUrl.SOCIAL_LOGIN_APPLES, {
    ...options,
    params,
  })

export const PostSigninAndUp = async (
  data: { userType?: number },
  params?: AppVersionParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> =>
  await accountApi.post<TokenInfo>(AccountUrl.SOCIAL_SIGNIN_AND_UP, data, {
    ...options,
    params,
  })

export default {
  PostAuthorization,
  PostRegister,
  PutDisconnet,
  PostLoginApple,
  GetLoginApple,
  PostSigninAndUp,
}
