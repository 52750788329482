<template>
  <v-dialog
    v-model="completeFindAccountDialog"
    fullscreen
    persistent
    transition="scroll-x-reverse-transition"
  >
    <v-card>
      <v-toolbar light color="white" outlined flat>
        <v-btn icon light color="black" @click="closeCompleteFindAccountDialog">
          <v-icon small color="default_t">$vuetify.icons.prevIcon</v-icon>
        </v-btn>
        <v-toolbar-title></v-toolbar-title>
        <v-spacer align="center" class="font-g15-16 rixgo-bold ml-4"
          >아이디 찾기</v-spacer
        >
        <v-toolbar-items>
          <v-btn dark text @click="dialog = false"> Save </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="pt-15 pl-10">
        <v-icon color="default_t">$vuetify.icons.signInCompleteBangIcon</v-icon>
        <div class="font-g13-18 rixgo-regular pt-7 pb-25">
          <div class="font-g16-18 rixgo-bold pb-2">네이버</div>
          총 {{ userDataArray.length }}개의 계정으로 가입이 되어 있어요.
        </div>
        <div class="dividerTop"></div>
      </div>
      <div
        v-for="item in userDataArray"
        :key="item.email"
        class="d-flex align-center justify-space-between fill-width pt-8 pl-15 pr-18"
      >
        <div>
          <div class="campton-semibold">
            {{ item.email }}
          </div>
          <div>
            <div class="font-g11-12 rixgo-semibold gray500--text">
              {{ item.connectDt.split('T')[0].replaceAll('-', ' . ') }} 연결
            </div>
            <div class="dividerMid mt-8"></div>
          </div>
        </div>
        <div class="font-g13-14 rixgo-regular">
          {{ item.channelCode === 1003 ? '네이버' : '애플' }}
        </div>
      </div>
      <div class="findAccountFooter">
        <v-btn
          class="font-g14-20 rixgo-bold blue white--text"
          height="52"
          width="90%"
          @click="executeLoginDialog"
        >
          로그인 하기
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'FindAccount',
  components: {},
  props: {
    completeFindAccountDialog: {
      type: Boolean,
      required: true,
    },
    userDataArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },

  methods: {
    executeLoginDialog() {
      this.$emit('executeLoginDialog')
    },
    closeCompleteFindAccountDialog() {
      //계정찾기
      this.$emit('closeCompleteFindAccountDialog')
    },
  },
})
</script>
<style lang="scss" scoped>
.findAccountFooter {
  position: absolute;
  text-align: center;
  bottom: 30px;
  width: 100%;
}
.dividerTop {
  border-bottom: solid 2px #404346;
  width: 94%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dividerMid {
  border-bottom: solid 1px #f4f4f4;
  width: 90%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
