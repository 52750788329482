import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { Store } from '@/store/Store'
import { CountStore } from '@/store/CountStore'
import { MetaStore } from '@/store/MetaStore'
import { BannerStore } from '@/store/BannerStore'
import { RecentlyStore } from '@/store/RecentlyStore'
import { DisplayStore } from '@/store/DisplayStore'
import { ProfileStore } from '@/store/ProfileStore'
import { CategoryStore } from '@/store/CategoryStore'
import { DialogStore } from '@/store/DialogStore'
import { DibsStore } from '@/store/DibsStore'
import { MyPageStore } from '@/store/MyPageStore'
import { SearchStore } from '@/store/SearchStore'
import { BottomSheetStore } from '@/store/BottomSheet'
import { NoticeStore } from '@/store/NoticeStore'
import { HomeStore } from '@/store/HomeStore'
import { LoadingStore } from '@/store/LoadingStore'
import { BridgeStore } from '@/store/BridgeStore'
import { UserStore } from '@/store/UserStore'
import { DeviceStore } from '@/store/DeviceStore'
import { BottomNaviStore } from '@/store/BottomNaviStore'
import { ExhibitionStore } from '@/store/ExhibitionStore'
import { PullToRefreshStore } from '@/store/PullToRefreshStore'
import { CommonStore } from '@/store/CommonStore'
import { StoreHomeStore } from '@/store/StoreHomeStore'
import { CheckInStore } from '@/store/CheckInStore'
import { NLiveStore } from '@/store/NLiveStore'
import { RouteHistoryStore } from '@/store/RouteHistoryStore'
import { CelltiqueStore } from '@/store/CelltiqueStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Store,
    CountStore,
    MetaStore,
    BannerStore,
    RecentlyStore,
    DisplayStore,
    ProfileStore,
    CategoryStore,
    DialogStore,
    DibsStore,
    MyPageStore,
    SearchStore,
    BottomSheetStore,
    NoticeStore,
    HomeStore,
    LoadingStore,
    BridgeStore,
    UserStore,
    DeviceStore,
    BottomNaviStore,
    ExhibitionStore,
    PullToRefreshStore,
    CommonStore,
    StoreHomeStore,
    CheckInStore,
    RouteHistoryStore,
    NLiveStore,
    CelltiqueStore,
  },
  plugins: [
    createPersistedState({
      paths: ['SearchStore', 'CategoryStore', 'MyPageStore'],
    }),
  ],
})
