import { RootState } from '@/store/RootState'
import { ActionTreeAdaptor } from '@/util'
import { Module } from 'vuex'
import { GetSettings, GetUserSettings } from '../api/accountAPI/UsersAPI'

const actions: ActionTreeAdaptor<DeviceActions, DeviceState, RootState> = {
  async fetchGetSettings({ commit }) {
    const { data } = await GetSettings()
    console.log(data)
    commit('setDeviceSettings', data ?? {})
  },
  async fetchGetUserSettings({ commit }) {
    const { data } = await GetUserSettings()
    commit('setUserSettings', data ?? {})
  },
  async fetchSetPushYn({ commit, state }, payload: any) {
    console.log('setDevicePush 데이터 반영 payload', payload)
    let data = null
    if (payload === 'true' || payload === true) {
      data = 'Y'
    } else {
      data = 'N'
    }
    commit('setPushYNFromDevice', data)
    console.log('setDevicePush 데이터 반영:', state.pushYNFromDevice)
  },
}

export const DeviceStore: Module<DeviceState, RootState> = {
  namespaced: true,
  state: {
    deviceInfo: null,
    deviceSettings: {
      version: '0.0.0',
      pushYN: 'N', // Y N
      adYN: 'N', // Y N
      isLatestVersion: false,
      isKakaoAvailable: true,
    } as DeviceSettings,
    pushYNFromDevice: null, //기기에서 직접 받아온 값
    userSetting: null,
  },
  mutations: {
    setDeviceInfo(state: DeviceState, payload: DeviceInfo) {
      state.deviceInfo = payload
    },
    setDeviceSettings(state: DeviceState, payload: DeviceSettings) {
      state.deviceSettings = payload
    },
    setUserSettings(state: DeviceState, payload: UserSettingInfo) {
      state.userSetting = payload
    },
    setPushYNFromDevice(state: DeviceState, payload: 'Y' | 'N' | null) {
      state.pushYNFromDevice = payload
    },
  },
  actions,
}

export interface DeviceState {
  deviceInfo: DeviceInfo | null
  deviceSettings: DeviceSettings
  pushYNFromDevice: 'Y' | 'N' | null
  userSetting: UserSettingInfo | null
}

export type DeviceActions = {
  fetchGetSettings: () => Promise<void>
  fetchGetUserSettings: () => Promise<void>
  fetchSetPushYn: (payload: any) => Promise<void>
}

/**
 * Device info 를 가져옴.
 */
//  @JavascriptInterface
//  fun getDeviceInfo(): String {
//      val jsonObject = JsonObject().apply {
//          addProperty("uid", appPreference.get(AppPreference.DEVICDE_UUID,""))
//          addProperty("adId", appPreference.get(AppPreference.DEVICDE_ADID,""))
//          addProperty("os", "A")
//          addProperty("appVer", BuildConfig.VERSION_NAME)
//          addProperty("pushToken", appPreference.get(AppPreference.FIREBASE_TOKEN, ""))
//          addProperty("token", appPreference.get(AppPreference.TOKEN_INFO, ""))
//      }
//      return jsonObject.toString()
//  }
