
/**
 * @필수
 * @선행1 onRequest의 전달된 데이터로 API호출
 * @선행2 호출된 response list를 infinite plugin에서 replaceList를 사용하여 list 전달
 * @선행3 아이템의 크기를 동일 시하게 하고, class name으로 'infiniteItem' 전달
 */
import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    // 현재 category쪽만 기능 적용이기에 default : CategoryStore
    storeName: {
      type: String,
      default: 'CategoryStore',
    },
    // list limit
    limit: {
      type: Number,
      default: 50,
    },
    // 첫 번째, 혹은 마지막 리스트의 percent
    threshold: {
      type: Number,
      default: 80,
    },
    // 한 줄의 표시될 아이템의 갯수
    rowRange: {
      type: Number,
      default: 2,
    },
    totalCount: {
      type: Number,
      default: 10000000,
    },
  },
  data() {
    return {
      scroll: 0,
      marginHeight: 0,
      requestApi: false,
      itemHeight: 0,
    }
  },
  created() {
    // redirect기능을 사용 시, 저장 시점에서 getMarginHeight의 데이터를 store에 저장 후 getter의 형태로 만들어서 store의 name을 props로 전달
    this.marginHeight = this.$store.getters[`${this.storeName}/getMarginHeight`]
    this.$getAppHtml.addEventListener('scroll', this.appScrollhandler)
  },
  beforeDestroy() {
    this.$getAppHtml.removeEventListener('scroll', this.appScrollhandler)
  },
  computed: {
    ...mapState('CommonStore', ['scrollTopNoti']),
    container() {
      return this.$refs.container as HTMLElement
    },
    placeholder() {
      return this.$refs.placeholder as HTMLElement
    },
  },
  watch: {
    list(newData) {
      // 삭제, 혹은 추가된 크기만큼 scroll 조절하기 위해 margin height값
      if (newData.length <= 0) return
      let firstKey = newData[0].groupKey
      this.marginHeight =
        this.itemHeight *
        (this.limit / this.rowRange) *
        (firstKey <= 0 ? 0 : firstKey)
      this.$emit('onChangeMarginHeight', this.marginHeight)
      this.requestApi = false
    },
    scrollTopNoti() {
      this.$emit('onRequest', 0, 'reset')
    },
  },
  methods: {
    appScrollhandler() {
      if (this.list.length <= 0) return
      if (!this.itemHeight) {
        this.itemHeight =
          document.getElementsByClassName('infiniteItem')[0]?.clientHeight ?? 0
      }
      const { scrollHeight, clientHeight, scrollTop } = this.$getAppHtml
      this.$emit('onChangeScroll', scrollTop)
      this.scroll = scrollTop

      if (this.requestApi) return // 요청중이면 return
      // <위에서 아래로 내릴 경우> 마지막 group key 기준 20퍼센트를 보았을 때, 요청
      if (
        scrollHeight <=
        clientHeight +
          scrollTop +
          this.itemHeight *
            ((this.limit / this.rowRange) * (this.threshold / 100))
      ) {
        return this.onAppend()
      }

      // <아래에서 위로 올릴 경우> 첫 번째 group key 기준 20퍼센트를 보았을 때, 요청
      if (
        scrollHeight -
          this.container.offsetHeight +
          this.placeholder.offsetHeight +
          this.itemHeight *
            ((this.limit / this.rowRange) * (this.threshold / 100)) >=
        scrollTop
      ) {
        return this.onPrepend()
      }
    },
    onAppend() {
      let lastKey = this.list[this.list.length - 1].groupKey ?? 0
      if ((lastKey + 1) * this.limit >= this.totalCount) return
      this.requestApi = true
      this.$emit('onRequest', lastKey + 1, 'next')
    },
    onPrepend() {
      let firstKey = this.list[0].groupKey ?? 0
      if (firstKey <= 0) return
      this.requestApi = true
      this.$emit('onRequest', firstKey - 1, 'prev')
    },
  },
})

interface Data {
  requestApi: boolean
  marginHeight: number
  scroll: number
  itemHeight: number
}
interface Methods {
  onAppend(): void
  onPrepend(): void
  appScrollhandler(): void
}
interface Computed {
  container: HTMLElement
  placeholder: HTMLElement
  scrollTopNoti: boolean
}
interface Props {
  list: Array<ProductItem>
  storeName: string
  limit: number
  threshold: number
  rowRange: number
  totalCount: number
}
