import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { AccountUrl } from '../../plugins/enum'
import { accountApi } from '../../plugins/axios'

export const GetProfile = async (
  params?: AppVersionParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AppProfileResponse>> =>
  await accountApi.get<AppProfileResponse>(AccountUrl.APP_PROFILE, {
    ...options,
    params,
  })

export const PutAppAccess = async (
  data: UserPushMgtForDeviceAndAccess,
  params?: AppAccessParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AccountCommonResponse>> =>
  await accountApi.put<AccountCommonResponse>(
    AccountUrl.APP_PROFILE_ACCESS,
    data,
    {
      ...options,
      params,
    }
  )

export const PutAppPush = async (
  data: UserPushMgtForPushToken,
  params?: AppAccessParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AccountCommonResponse>> =>
  await accountApi.put<AccountCommonResponse>(
    AccountUrl.APP_PROFILE_PUSH,
    data,
    {
      ...options,
      params,
    }
  )

export const PutAppAd = async (
  data: { adYn?: string },
  params?: AppAccessParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AccountCommonResponse>> =>
  await accountApi.put<AccountCommonResponse>(AccountUrl.APP_PROFILE_AD, data, {
    ...options,
    params,
  })

export const PutAppAdId = async (
  data: { adId?: string },
  params?: AppAccessParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<AccountCommonResponse>> =>
  await accountApi.put<AccountCommonResponse>(
    AccountUrl.APP_PROFILE_AD_ID,
    data,
    {
      ...options,
      params,
    }
  )

export default {
  GetProfile,
  PutAppAccess,
  PutAppPush,
  PutAppAd,
  PutAppAdId,
}
