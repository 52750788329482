<template>
  <svg
    width="74"
    height="32"
    viewBox="0 0 74 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2.5" y="2.5" width="69" height="27" rx="13.5" stroke="#FF5266" />
    <rect
      x="4.5"
      y="4.5"
      width="65"
      height="23"
      rx="11.5"
      fill="#FF5266"
      stroke="#FF5266"
    />
    <path
      d="M15.03 17.55C13.92 17.55 13.15 16.73 13.15 15.6C13.15 14.47 13.92 13.65 15.03 13.65C15.93 13.65 16.53 14.17 16.71 15.02H18.45C18.23 13.26 16.89 12.07 15.04 12.07C12.99 12.07 11.44 13.55 11.44 15.6C11.44 17.65 12.99 19.13 15.04 19.13C16.89 19.13 18.23 17.94 18.45 16.18H16.71C16.53 17.03 15.93 17.55 15.03 17.55ZM23.9506 12.2V14.93H21.1806V12.2H19.4906V19H21.1806V16.45H23.9506V19H25.6306V12.2H23.9506ZM31.9744 13.67V12.2H26.8344V19H31.9744V17.53H28.5244V16.35H31.5444V14.91H28.5244V13.67H31.9744ZM36.1726 17.55C35.0626 17.55 34.2926 16.73 34.2926 15.6C34.2926 14.47 35.0626 13.65 36.1726 13.65C37.0726 13.65 37.6726 14.17 37.8526 15.02H39.5926C39.3726 13.26 38.0326 12.07 36.1826 12.07C34.1326 12.07 32.5826 13.55 32.5826 15.6C32.5826 17.65 34.1326 19.13 36.1826 19.13C38.0326 19.13 39.3726 17.94 39.5926 16.18H37.8526C37.6726 17.03 37.0726 17.55 36.1726 17.55ZM45.1432 19H47.2532L44.4432 15.18L47.0332 12.2H45.0332L42.3232 15.32V12.2H40.6332V19H42.3232V17.62L43.3232 16.46L45.1432 19ZM53.0453 12.2V19H54.7353V12.2H53.0453ZM60.5059 12.2V16.03L57.2259 12.2H55.9359V19H57.6259V15.27L60.8859 19H62.1859V12.2H60.5059Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>
