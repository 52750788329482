export const SubscribeInfoData: DibsSubscribeInfo = {
  todayShopItemGroup: {
    title: '',
    displayMallType: 1,
    newShopItemList: [
      {
        shopId: '',
        shopName: '',
        icon: '',
        link: '',
        isNew: true,
        isAvailable: true,
      },
    ],
    recommendShopItemList: [
      {
        shopId: '',
        shopName: '',
        icon: '',
        link: '',
        isNew: true,
        isAvailable: true,
      },
    ],
  },
}
export const SubscribeShopItemData: SubscribeShopItemGroup = {
  subscribeShopItemGroup: {
    title: '',
    count: 0,
    displayMallType: 1,
    subscribeShopItemList: [
      {
        shopId: '',
        shopName: '',
        icon: '',
        link: '',
        nameKr: '',
        isNew: true,
        isPin: true,
        sex: '',
        age: 0,
        ageKr: '',
        subscribeCount: 0,
        isSubscribed: true,
        isAvailable: true,
      },
    ],
  },
}

export const DibsTabData: DibsTabCategory = {
  categoryList: [{ categoryCode: 0, categoryName: '', productCount: 0 }],
}
export const DibsLikeProductInfo: DibsLikeProductItem = {
  folderId: 0,
  count: 0,
  isSale: true,
  isAvailable: true,
  searchProductList: [
    {
      no: 0,
      docId: '',
      docState: '',
      goodsNo: '',
      productNo: '',
      nameKr: '',
      nameEn: '',
      mallName: '',
      categoryCode: '',
      category1: '',
      categoryCode1: '',
      category2: '',
      categoryCode2: '',
      category3: '',
      categoryCode3: '',
      category4: '',
      categoryCode4: '',
      categoryNameFromMall: '',
      sex: '',
      age: '',
      price: 0,
      discountPrice: 0,
      discountRate: 0,
      mallScore: 0,
      mallIcon: '',
      mallId: '',
      productScore: 0,
      weekScore: 0,
      monthScore: 0,
      newarrivalScore: 0,
      latestScore: 0,
      mdOrder: 0,
      viewCnt: 0,
      saleCnt: 0,
      likeCnt: 0,
      terms: '',
      tags: '',
      showaTag1: '',
      showaTag2: '',
      colors: '',
      isLike: true,
      isBest: true,
      isNew: true,
      fullTerms: '',
      urlPc: '',
      urlMobile: '',
      imageUrl1: '',
      imageUrl2: '',
      isAvailable: true,
      imageInfo: {
        height: 0,
        width: 0,
        url: '',
      },
      updateTime: '',
      createTime: '',
    },
  ],
}

export const DibsData: DibsGroup = {
  todayShopItemGroup: SubscribeInfoData,
  subscribeShopItemGroup: SubscribeShopItemData,
}

export const DibsProductData: DibsProductGroup = {
  categoryList: DibsTabData,
  productData: DibsLikeProductInfo,
}
// export const DibsLikeProductData: DibsLikeProductItemGroup = {
//   productData: DibsLikeProductInfo,
// }
