
import Vue from 'vue'
import Button from '../buttons/Button.vue'
export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Alert',
  components: { Button },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    subContent: {
      type: String,
      default: '',
    },
    once: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {},
})

interface Data {}
interface Methods {}
interface Computed {}
interface Props {
  show: boolean
  title: string
  content: string
  subContent: string
  once: boolean
}
