import Vue from 'vue'
import { RootState } from '@/store/RootState'
import { Module } from 'vuex'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import {
  GetStoreDisplay,
  GetStoreTotalDisplay,
} from '@/api/displayAPI/DisplayAPI'

const getters: GetterTreeAdaptor<HomeStoreGetters, HomeStoreState, RootState> =
  {
    getBestSellerHistory(state: HomeStoreState) {
      return state.pageHistory.main.bestSeller
    },
    getNewArrivalsHistory(state: HomeStoreState) {
      return state.pageHistory.main.newArrivals
    },
    getTopShopHistory(state: HomeStoreState) {
      return state.pageHistory.main.topShop
    },
    getTrendHistory(state: HomeStoreState) {
      return state.pageHistory.main.trend
    },
    getMainAllItemHistory(state: HomeStoreState) {
      return state.pageHistory.main.allItem
    },
    getStoreHistory(state: HomeStoreState) {
      return state.pageHistory.store
    },
    getAllItemsHistory(state: HomeStoreState) {
      return state.pageHistory.allItems
    },
    getMdPickHistory(state: HomeStoreState) {
      return state.pageHistory.mdPick
    },
    getSaleHistory(state: HomeStoreState) {
      return state.pageHistory.sale
    },
    getHomeState(state: HomeStoreState) {
      return state
    },
    getMenuHistory(state: HomeStoreState) {
      return state.pageHistory.tabMenu
    },
    getPlanexHistory(state: HomeStoreState) {
      return state.pageHistory.main.planex
    },
    getShoppingLiveHistory(state: HomeStoreState) {
      return state.pageHistory.main.shoppingLive
    },
  }

const actions: ActionTreeAdaptor<HomeStoreAction, HomeStoreState, RootState> = {
  async fetchInit({ commit }) {
    commit('setInitMain')
    commit('setInitStore')
    commit('setInitAllItems')
    commit('setInitMdPick')
    commit('setInitSale')
  },
  async fetchInitScroll({ commit }) {
    commit('setInitScroll')
  },
  async fetchRedirect({ commit }, payload) {
    commit('setRedirect', payload)
  },
  async fetchScroll({ commit }, payload) {
    commit('setScroll', payload)
  },
  async fetchMainHistory({ commit }, payload) {
    commit('setMainHistory', payload)
  },
  // 다른 곳도 아래와 같은 형태가 좋을 것 같음 참고
  async fetchStore({ commit }) {
    await Promise.all([GetStoreDisplay(), GetStoreTotalDisplay()])
      .then(([storeMall, totalMall]) => {
        commit('setStoreHistory', {
          init: false,
          ...storeMall.data,
          ...totalMall.data,
        })
      })
      .catch((e) => {
        console.log('get store tab e', e)
      })
  },
  async fetchStoreHistory({ commit }, payload) {
    commit('setStoreHistory', payload)
  },
  async fetchAllItemsHistory({ commit }, payload) {
    commit('setAllItemsHistory', payload)
  },
  async fetchMdPickHistory({ commit }, payload) {
    commit('setMdPickHistory', payload)
  },
  async fetchSaleHistory({ commit }, payload) {
    commit('setSaleHistory', payload)
  },
  async fetchMainTab({ commit }, payload) {
    commit('setMainTab', payload)
  },
  async fetchSwipe({ commit }, payload) {
    commit('setSwipe', payload)
  },
  async fetchInitMain({ commit }) {
    commit('setInitMain')
  },
  async fetchInitStore({ commit }) {
    commit('setInitStore')
  },
  async fetchInitAllItems({ commit }) {
    commit('setInitAllItems')
  },
  async fetchInitMdPick({ commit }) {
    commit('setInitMdPick')
  },
  async fetchInitSale({ commit }) {
    commit('setInitSale')
  },
  async fetchHomeHistory({ commit }) {
    const preState = JSON.parse(localStorage.getItem('preHomeHistory') ?? '{}')
    if (Object.keys(preState).length > 0) commit('setHomeHistory', preState)
  },
  async fetchMainInit({ commit }, payload) {
    commit('setMainInit', payload.yn)
  },
  async fetchOnSkeleton({ commit }, payload) {
    commit('setOnSkeleton', payload)
  },
  async fetchMenuHistory({ commit }, payload) {
    commit('setMenuHistory', payload)
  },
}

export const HomeStore: Module<HomeStoreState, RootState> = {
  namespaced: true,
  state: {
    onSkeleton: true,
    // TODO : 흐음... redirect 랑 pageHistory 를 분리하는게 더 이상한 느낌...? 목적이 같은데
    // 리팩토링 확인 대상
    redirect: {
      activeTab: 0,
      scroll: [0, 0, 0, 0, 0],
    },
    pageHistory: {
      tabMenu: {
        displayType: 0,
        tabMenuItemList: [],
        title: '',
      },
      // Main
      main: {
        init: true,
        bestSeller: {
          titleImage: '',
          active: {
            id: 1,
            title: '전체',
            searchProductList: [],
          },
          bestSellerItemList: [],
          title: '',
          subTitle: '',
          displayType: 0,
          limit: 12,
        },
        newArrivals: {
          active: { id: 0, index: 0, shopName: 'New Arrivlas' },
          display: 0,
          title: '',
          subTitle: '',
          displayCount: 0,
          newArrivalsItemList: [],
          searchProductList: [],
          titleImage: '',
        },
        topShop: {
          titleImage: '',
          active: 0,
          categoryTopShopButtonBottom: '',
          displayType: 0,
          subTitle: '',
          title: '',
          topShopItemList: [],
          updDt: '',
        },
        trend: {
          titleImage: '',
          active: 0,
          hotActive: {
            id: 1,
            title: '',
            list: [],
          },
          riseActive: {
            id: 1,
            title: '',
            list: [],
          },
          displayType: 0,
          subTitle: '',
          title: '',
          trendButtonList: ['지금 핫한', '요즘 뜨는'],
          trendHotRankKeywordList: [],
          trendThesedayRiseKeywordList: [],
        },
        planex: {
          displayType: 0,
          planexItemList: [],
          title: '',
        },
        allItem: {
          titleImage: null,
          displayType: 0,
          searchProductList: [],
          subTitle: '',
          title: '',
        },
        shoppingLive: {
          titleImage: null,
          title: '',
          displayType: 0,
          subTitle: '',
        },
      },
      store: {
        init: true,
        carouselActive: {
          topCarousel: 0,
          bottomCarousel: 0,
          bestCarousel: 0,
        },
        newShopGroup: {
          title: '',
          newShopList: [],
        },
        selectedShopGroup: {
          backgroundImage: '',
          selectedShopList: [],
        },
        bestShopGroup: {
          title: '',
          subTitle: '',
          bestShopList: [],
        },
        title: '',
        subTitle: '',
        shopList: [],
        paging: {
          hasNext: false,
          page: 0,
          pageSize: 0,
          size: 0,
          total: 0,
        },
      },
      // All Items
      allItems: {
        init: true,
        title: '',
        allCount: 0,
        searchProductList: [],
        paging: {
          total: 0,
          size: 0,
          page: 0,
          pageSize: 0,
          hasNext: true,
        },
      },
      mdPick: {
        init: true,
        title: '',
        subTitle: '',
        bottomBannerItem: {
          bannerClickUrl: '',
          bannerViewEventUrl: '',
          icon: '',
          link: '',
        },
        mdPickShopItemList: [],
      },
      // Sale
      sale: {
        init: true,
        topBannerItemGroup: {
          bannerItemList: [],
        },
        searchProductList: [],
        activeRate: {
          title: '',
          filter: {
            'filters.minSaleRate': '1',
            'filters.maxSaleRate': '100',
          },
        },
        activeCate: {
          categoryName: '',
          categoryList: [],
          categoryCode: '',
        },
        paging: {
          'paging.page': 0,
          'paging.limit': 50,
        },
        bannerItemList: [],
        bestItem: { title: '', searchProductList: [] },
        categoryItem: { filterList: [] },
        percentItem: { filterList: [] },
        order: [],
      },
    },
    homeSwipeBlock: false,
    isNotFirstLottiePlay: false,
    storeShopId: '',
  },
  getters,
  mutations: {
    setRedirect(
      state: HomeStoreState,
      payload: { now: number; pre: number; scroll: number }
    ) {
      const { scroll } = state.redirect
      const { now, pre } = payload

      state.redirect.scroll[pre] = payload.scroll
      state.redirect.activeTab = now

      Vue.nextTick(() => {
        setTimeout(() => {
          document.getElementById('app')?.scrollTo({ top: scroll[now] })
        })
      })
    },
    setScroll(state: HomeStoreState, payload: { scroll: number }) {
      state.redirect.scroll[state.redirect.activeTab] = payload.scroll
    },
    setMainHistory(state: HomeStoreState, payload: MainHistoryPayload) {
      switch (payload.id) {
        case 'bestSeller':
          if (payload.bestSeller) {
            state.pageHistory.main.bestSeller = { ...payload.bestSeller }
          }
          break
        case 'newArrivals':
          if (payload.newArrivals) {
            state.pageHistory.main.newArrivals = { ...payload.newArrivals }
          }
          break
        case 'topShop':
          if (payload.topShop) {
            state.pageHistory.main.topShop = { ...payload.topShop }
          }
          break
        case 'trend':
          if (payload.trend) {
            state.pageHistory.main.trend = { ...payload.trend }
          }
          break
        case 'planex':
          if (payload.planex) {
            state.pageHistory.main.planex = { ...payload.planex }
          }
          break
        case 'allItem':
          if (payload.allItem) {
            state.pageHistory.main.allItem = { ...payload.allItem }
          }
          break
        case 'shoppingLive':
          if (payload.shoppingLive) {
            state.pageHistory.main.shoppingLive = { ...payload.shoppingLive }
          }
          break
      }
    },
    setStoreHistory(state: HomeStoreState, payload: StoreHistory) {
      state.pageHistory.store = { ...state.pageHistory.store, ...payload }
    },
    setAllItemsHistory(state: HomeStoreState, payload: AllItemsHistoryPayload) {
      state.pageHistory.allItems = { ...payload }
    },
    setMdPickHistory(state: HomeStoreState, payload: MdPickHistoryPayload) {
      state.pageHistory.mdPick = { ...payload }
    },
    setSaleHistory(state: HomeStoreState, payload: SaleHistoryPayload) {
      state.pageHistory.sale = { ...payload }
    },
    setMainTab(state: HomeStoreState, payload: { tab: number }) {
      state.redirect.activeTab = payload.tab
    },
    // TODO : AOS 성능 이슈 - 추후 확인
    setSwipe(state: HomeStoreState, payload: { yn: boolean }) {
      state.homeSwipeBlock = payload.yn
    },
    setInitMain(state: HomeStoreState) {
      const { topShop, trend } = state.pageHistory.main
      state.pageHistory.main = {
        init: true,
        bestSeller: {
          titleImage: null,
          active: {
            id: 0,
            title: '',
            searchProductList: [],
          },
          displayType: 0,
          bestSellerItemList: [],
          subTitle: '',
          title: '',
          limit: 12,
        },
        newArrivals: {
          titleImage: null,
          active: { id: 0, index: 0, shopName: 'New Arrivlas' },
          display: 0,
          title: '',
          subTitle: '',
          displayCount: 0,
          newArrivalsItemList: [],
          searchProductList: [],
        },
        topShop: {
          ...topShop,
        },
        trend: {
          ...trend,
        },
        planex: {
          displayType: 0,
          planexItemList: [],
          title: '',
        },
        allItem: {
          displayType: 0,
          searchProductList: [],
          subTitle: '',
          title: '',
          titleImage: null,
        },
        shoppingLive: {
          title: '',
          displayType: 0,
          subTitle: '',
          titleImage: null,
        },
      }
    },
    setInitStore(state: HomeStoreState) {
      state.pageHistory.store = {
        init: true,
        carouselActive: {
          topCarousel: 0,
          bottomCarousel: 0,
          bestCarousel: 0,
        },
        newShopGroup: {
          title: '',
          newShopList: [],
        },
        selectedShopGroup: {
          backgroundImage: '',
          selectedShopList: [],
        },
        bestShopGroup: {
          title: '',
          subTitle: '',
          bestShopList: [],
        },
        title: '',
        subTitle: '',
        shopList: [],
        paging: {
          size: 0,
          total: 0,
          hasNext: false,
          page: 0,
          pageSize: 0,
        },
      }
    },
    setInitAllItems(state: HomeStoreState) {
      state.pageHistory.allItems = {
        init: true,
        title: '',
        allCount: 0,
        searchProductList: [],
        paging: {
          size: 0,
          total: 0,
          hasNext: false,
          page: 0,
          pageSize: 0,
        },
      }
    },
    setInitMdPick(state: HomeStoreState) {
      state.pageHistory.mdPick = {
        ...state.pageHistory.mdPick,
        init: true,
      }
    },
    setInitSale(state: HomeStoreState) {
      state.pageHistory.sale = {
        ...state.pageHistory.sale,
        init: true,
        paging: {
          'paging.page': 0,
          'paging.limit': 50,
        },
      }
    },
    setHomeHistory(state: HomeStoreState, payload) {
      state.redirect = { ...payload.redirect }
      state.pageHistory = {
        ...payload.pageHistory,
      }
      state.homeSwipeBlock = payload.homeSwipeBlock
      localStorage.removeItem('preHomeHistory')
    },
    setMainInit(state: HomeStoreState, payload: boolean) {
      state.pageHistory.main.init = payload
    },
    setInitScroll(state: HomeStoreState) {
      state.redirect.activeTab = 0
      state.redirect.scroll = [0, 0, 0, 0, 0]
    },

    setOnSkeleton(state: HomeStoreState, payload: boolean) {
      state.onSkeleton = payload
    },
    setFirstLottiePlay(state: HomeStoreState, payload: boolean) {
      state.isNotFirstLottiePlay = payload
    },
    setMenuHistory(state: HomeStoreState, payload: TabMenuItemGroup) {
      state.pageHistory.tabMenu = { ...payload }
    },
    setPlanexHistory(state: HomeStoreState, payload: PlanexItemGroup) {
      state.pageHistory.main.planex = { ...payload }
    },
  },
  actions,
}

export interface HomeStoreState {
  onSkeleton: boolean
  redirect: {
    activeTab: number
    scroll: number[]
  }
  pageHistory: HomeHistory
  homeSwipeBlock: boolean
  isNotFirstLottiePlay: boolean
  storeShopId: string
}

export type HomeStoreAction = {
  fetchInit: () => Promise<void>
  fetchRedirect: ({
    now,
    pre,
  }: {
    now: number
    pre: number
    setScroll: number
  }) => Promise<void>
  fetchScroll: (payload: { scroll: number }) => Promise<void>
  fetchMainHistory: (payload: MainHistoryPayload) => Promise<void>
  fetchStore: () => Promise<void>
  fetchStoreHistory: (payload: StoreHistory) => Promise<void>
  fetchSaleHistory: (payload: SaleHistoryPayload) => Promise<void>
  fetchMainTab: (payload: { tab: number }) => Promise<void>
  fetchSwipe: (payload: { yn: boolean }) => Promise<void>
  fetchInitMain: () => Promise<void>
  fetchInitStore: () => Promise<void>
  fetchInitAllItems: () => Promise<void>
  fetchInitMdPick: () => Promise<void>
  fetchInitSale: () => Promise<void>
  fetchAllItemsHistory: (paylod: AllItemsHistoryPayload) => Promise<void>
  fetchMdPickHistory: (paylod: MdPickHistoryPayload) => Promise<void>
  fetchHomeHistory: () => Promise<void>
  fetchMainInit: (payload: { yn: boolean }) => Promise<void>
  fetchInitScroll: () => Promise<void>
  fetchOnSkeleton: (payload: boolean) => Promise<void>
  fetchMenuHistory: (payload: TabMenuItemGroup) => Promise<void>
}

export interface HomeStoreGetters {
  // v2 api 변경 후 재정의
  getBestSellerHistory: BestSellerInfo
  getNewArrivalsHistory: NewArrivalsInfo
  getTopShopHistory: TopShopInfo
  getTrendHistory: TrendInfo
  getStoreHistory: StoreHistory
  getSaleHistory: SaleHistoryPayload | SaleDisplayGroup
  getAllItemsHistory: AllItemsHistoryPayload
  getMdPickHistory: MdPickHistoryPayload
  getHomeState: HomeStoreState
  getMenuHistory: TabMenuItemGroup
  getPlanexHistory: PlanexItemGroup
  getMainAllItemHistory: AllItemsGroup
  getShoppingLiveHistory: ShoppingLiveItemGroup
}
