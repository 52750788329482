<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 17L21 12L16 7"
      stroke="#222222"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M21 12H3" stroke="#222222" stroke-linecap="round" />
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({})
</script>
