import { RootState } from '@/store/RootState'
import { Module } from 'vuex'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import {
  GetMainDisplay,
  GetMainDisplayAllItems,
  GetMainDisplayBestSeller,
  GetMainDisplayMyCollection,
  GetMainDisplayNewArrivals,
  GetMainDisplayTabMenu,
  GetMainDisplayTopShop,
  GetMainDisplayTrend,
} from '@/api/displayAPI/DisplayAPI'
import { DisplayData } from '@/util/default/display'
import store from '@/store/index'

const getters: GetterTreeAdaptor<DisplayGetters, DisplayState, RootState> = {
  getTabMenu(state: DisplayState) {
    return state.display.tabMenuItemGroup
  },
  getAllItem(state: DisplayState) {
    return state.display.allItemsGroup
  },
  getBestSeller(state: DisplayState) {
    return state.display.bestSellerItemGroup
  },
  getNewArrivals(state: DisplayState) {
    return state.display.newArrivalsItemGroup
  },
  getTopShop(state: DisplayState) {
    return state.display.topShopItemGroup
  },
  getTrend(state: DisplayState) {
    return state.display.trendItemGroup
  },
  getPlanex(state: DisplayState) {
    return state.display.planexItemGroup
  },
  getMyCollection(state: DisplayState) {
    return state.display.myCollectionItemGroup
  },
}

const actions: ActionTreeAdaptor<DisplayAction, DisplayState, RootState> = {
  async asyncGetDisplay({ commit }) {
    store.dispatch('LoadingStore/fetchLoading', { yn: true })
    try {
      const { data } = await GetMainDisplay()
      const {
        bestSellerItemGroup,
        newArrivalsItemGroup,
        trendItemGroup,
        topShopItemGroup,
        planexItemGroup,
        allItemsGroup,
        shoppingLiveItemGroup,
      } = data
      // 중복 호출 / main api by pass 기능이 추가되면서 최초 데이터는 모두 store에 저장해야할 의무가 생겻음.
      // 해당 기능 분리 하는 게 좋을 듯
      store.dispatch('HomeStore/fetchMenuHistory', data.tabMenuItemGroup)

      const { bestSellerItemList } = bestSellerItemGroup
      store.dispatch('HomeStore/fetchMainHistory', {
        id: 'bestSeller',
        bestSeller: {
          ...bestSellerItemGroup,
          active: bestSellerItemList[0],
          limit: 12,
          // searchProductList: bestSellerItemList[0].searchProductList,
        },
      })

      const { newArrivalsItemList } = newArrivalsItemGroup
      store.dispatch('HomeStore/fetchMainHistory', {
        id: 'newArrivals',
        newArrivals: {
          ...newArrivalsItemGroup,
          displayCount: newArrivalsItemList[0].productCount ?? 0,
          active: { id: 0, index: 0 },
          searchProductList: newArrivalsItemList[0].searchProductList,
        },
      })

      const { trendHotRankKeywordList, trendThesedayRiseKeywordList } =
        trendItemGroup
      store.dispatch('HomeStore/fetchMainHistory', {
        id: 'trend',
        trend: {
          ...trendItemGroup,
          active: 0,
          hotActive: { ...trendHotRankKeywordList[0], list: [] },
          riseActive: { ...trendThesedayRiseKeywordList[0], list: [] },
        },
      })
      store.dispatch('HomeStore/fetchMainHistory', {
        id: 'topShop',
        topShop: {
          ...topShopItemGroup,
          active: 0,
        },
      })

      store.dispatch('HomeStore/fetchMainHistory', {
        id: 'planex',
        planex: {
          ...planexItemGroup,
        },
      })
      store.dispatch('HomeStore/fetchMainHistory', {
        id: 'allItem',
        allItem: {
          ...allItemsGroup,
        },
      })
      store.dispatch('HomeStore/fetchMainHistory', {
        id: 'shoppingLive',
        shoppingLive: {
          ...shoppingLiveItemGroup,
        },
      })

      commit('setDisplay', data)
    } catch (e) {
      console.log('display home api', e)

      // get main display tab menu
      await GetMainDisplayTabMenu()
        .then((res) => {
          store.dispatch('HomeStore/fetchMenuHistory', res.data)
          commit('setTabMenu', res.data)
        })
        .catch((e) => console.log('get main display tab menu err', e))

      await GetMainDisplayBestSeller()
        .then((res) => {
          store.dispatch('HomeStore/fetchMainHistory', {
            id: 'bestSeller',
            bestSeller: {
              ...res.data,
              active: res.data.bestSellerItemList[0],
              limit: 12,
              // searchProductList:
              //   res.data.bestSellerItemList[0].searchProductList,
            },
          })
          commit('setBestSeller', res.data)
        })
        .catch((e) => console.log('get main display best seller err', e))

      // display 순서대로 위에서부터 어느정도 구현이 되었을 때 로딩 해제
      store.dispatch('LoadingStore/fetchLoading', { yn: false })

      await GetMainDisplayMyCollection()
        .then((res) => commit('setMyCollection', res.data))
        .catch((e) => console.log('get display my collection err', e))

      await GetMainDisplayNewArrivals()
        .then((res) => {
          store.dispatch('HomeStore/fetchMainHistory', {
            id: 'newArrivals',
            newArrivals: {
              ...res.data,
              displayCount: res.data.newArrivalsItemList[0].productCount ?? 0,
              active: { id: 0, index: 0 },
              searchProductList:
                res.data.newArrivalsItemList[0].searchProductList,
            },
          })
          commit('setNewArrivals', res.data)
        })
        .catch((e) => console.log('get main display new arrivals err', e))

      await GetMainDisplayTopShop()
        .then((res) => {
          store.dispatch('HomeStore/fetchMainHistory', {
            id: 'topShop',
            topShop: {
              ...res.data,
              active: 0,
            },
          })
          commit('setTopShop', res.data)
        })
        .catch((e) => console.log('get main display top shop err', e))

      await GetMainDisplayTrend()
        .then((res) => {
          store.dispatch('HomeStore/fetchMainHistory', {
            id: 'trend',
            trend: {
              active: 0,
              hotActive: res.data.trendHotRankKeywordList[0],
              riseActive: res.data.trendThesedayRiseKeywordList[0],
            },
          })
          commit('setTrend', res.data)
        })
        .catch((e) => console.log('get main display trend err', e))

      await GetMainDisplayAllItems()
        .then((res) => {
          store.dispatch('HomeStore/fetchMainHistory', {
            id: 'allItem',
            allItem: {
              ...res.data,
            },
          })
          commit('setAllItems', res.data)
        })
        .catch((e) => console.log('get main display all items err', e))
    } finally {
      setTimeout(
        () => {
          store.dispatch('LoadingStore/fetchLoading', { yn: false })
        },
        sessionStorage.getItem('appInit') === 'Y' ? 1000 : 0
      )
      store.dispatch('HomeStore/fetchMainInit', { yn: false })
    }
  },
  async fetchTopShopItemGroup({ commit }) {
    await GetMainDisplayTopShop()
      .then((res) => {
        commit('setTopShop', res.data)
      })
      .catch((e) => console.log('get main display top shop err', e))
  },
  async fetchAllITemGroup({ commit }) {
    await GetMainDisplayAllItems()
      .then((res) => commit('setAllItems', res.data))
      .catch((e) => console.log('get main display all items err', e))
  },
}

export const DisplayStore: Module<DisplayState, RootState> = {
  namespaced: true,
  state: {
    display: DisplayData,
  },
  getters,
  mutations: {
    setDisplay(state: DisplayState, display: DisplayGroup) {
      state.display = { ...display }
    },
    setTabMenu(state: DisplayState, tabMenu: TabMenuItemGroup) {
      state.display.tabMenuItemGroup = tabMenu
    },
    setBestSeller(state: DisplayState, bestSeller: BestSellerGroup) {
      state.display.bestSellerItemGroup = bestSeller
    },
    setMyCollection(state: DisplayState, myCollection: MyCollectionItemGroup) {
      state.display.myCollectionItemGroup = myCollection
    },
    setNewArrivals(state: DisplayState, newArrivals: NewArrivalsItemGroup) {
      state.display.newArrivalsItemGroup = newArrivals
    },
    setTopShop(state: DisplayState, topShop: TopShopItemGroup) {
      state.display.topShopItemGroup = topShop
    },
    setTrend(state: DisplayState, trend: TrendItemGroup) {
      state.display.trendItemGroup = trend
    },
    setPlanex(state: DisplayState, planex: PlanexItemGroup) {
      state.display.planexItemGroup = planex
    },
    setAllItems(state: DisplayState, allItems: AllItemsGroup) {
      state.display.allItemsGroup = allItems
    },
  },
  actions,
}

export interface DisplayState {
  display: DisplayGroup
}

export interface DisplayGetters {
  getAllItem: AllItemsGroup
  getBestSeller: BestSellerGroup
  getNewArrivals: NewArrivalsItemGroup
  getTopShop: TopShopItemGroup
  getTrend: TrendItemGroup
  getTabMenu: TabMenuItemGroup
  getPlanex: PlanexItemGroup
  getMyCollection: MyCollectionItemGroup
}

export type DisplayAction = {
  asyncGetDisplay: () => Promise<void>
  fetchTopShopItemGroup: () => Promise<void>
  fetchAllITemGroup: () => Promise<void>
}
