import { RootState } from '@/store/RootState'
import { Module } from 'vuex'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import Vue from 'vue'

const getters: GetterTreeAdaptor<
  RouteHistoryGetters,
  RouteHistoryState,
  RootState
> = {
  getPreviousPage(state: RouteHistoryState) {
    return state.previousPage
  },
  getScrollTopMap(state: RouteHistoryState) {
    return state.scrollTopMap
  },
}

const actions: ActionTreeAdaptor<
  RouteHistoryAction,
  RouteHistoryState,
  RootState
> = {
  async fetchPreviousPage({ commit }, payload) {
    commit('setPreviousPage', payload)
  },
  async fetchPreviousPageScroll(
    { commit },
    payload: { name: string; scrollTop: number }
  ) {
    commit('setPreviousPageScroll', payload)
  },
}

export const RouteHistoryStore: Module<RouteHistoryState, RootState> = {
  namespaced: true,
  state: {
    previousPage: {
      // vue-router 기본값
      fullPath: '/',
      name: null,
      path: '/',
    },
    //router hook 에서 스크롤 위치 저장
    scrollTopMap: Object.create(null),
  },
  getters,
  mutations: {
    setPreviousPage(state: RouteHistoryState, payload: PreviousPage) {
      state.previousPage = payload
    },
    setPreviousPageScroll(
      state: RouteHistoryState,
      payload: { name: string; scrollTop: number }
    ) {
      Vue.set(state.scrollTopMap, payload.name, payload.scrollTop)
    },
  },
  actions,
}

export interface RouteHistoryState {
  previousPage: PreviousPage
  scrollTopMap: {}
}

export interface RouteHistoryGetters {
  getPreviousPage: PreviousPage
  getScrollTopMap: {}
}

export type RouteHistoryAction = {
  fetchPreviousPage: (payload: PreviousPage) => Promise<void>
  fetchPreviousPageScroll: (payload: {
    name: string
    scrollTop: number
  }) => Promise<void>
}

interface PreviousPage {
  fullPath: string
  name: string | null
  path: string
}
