import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ShopUrl } from '@/plugins/enum'
import { displayApi } from '@/plugins/axios'

export const GetShopInfo = async (
  params: { shopId?: string },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Shop>> =>
  await displayApi.get<any>(ShopUrl.SHOP_INFO, { ...options, params })

export const GetShopInfoV3 = async (
  params: { shopId?: string },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Shop>> =>
  await displayApi.get<Shop>(ShopUrl.SHOP_INFO_V3, { ...options, params })

export const GetShopTarget = async (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Shop>> =>
  await displayApi.get<any>(ShopUrl.SHOP_TARGET, options)

export const GetShopProductList = async (
  params: ShopProductListRequestParam,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ShopProductList>> =>
  displayApi.get<ShopProductList>(
    `${
      ShopUrl.SHOP_INFO_RENEWAL_PRODUCT
    }?tackingUser.fingerPrint=${localStorage.getItem('uid')}`,
    { ...options, params }
  )
export default {
  GetShopInfo,
  GetShopTarget,
  GetShopProductList,
  GetShopInfoV3,
}
