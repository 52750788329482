import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { DisplayUrl, NaverUrl } from '@/plugins/enum'
import { displayApi } from '@/plugins/axios'

export const GetBroadCasts = async (
  pagePayload: { pageNum: number; pageSize: number } = {
    pageNum: 1,
    pageSize: 20,
  },
  options?: AxiosRequestConfig
): Promise<AxiosResponse<LiveBroadcasts>> => {
  const urlSearchParams = new URLSearchParams()
  urlSearchParams.append('pageNum', String(pagePayload.pageNum))
  urlSearchParams.append('pageSize', String(pagePayload.pageSize))

  const params = {
    path: NaverUrl.LIST_ALL,
    query: urlSearchParams.toString(),
  }

  return displayApi.get(DisplayUrl.SHOPPING_LIVE, {
    ...options,
    params: params,
  })
}

export const GetBroadCastById = async (
  channelId: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<LiveBroadcastItem>> => {
  const params = {
    path: NaverUrl.LIST_ONE,
    query: `broadcastId=${channelId}`,
  }

  return displayApi.get(DisplayUrl.SHOPPING_LIVE, {
    ...options,
    params: params,
  })
}

export const GetBroadCastsPlayCount = async (
  ids: number[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<LiveBroadcastsPlayCount[]>> => {
  const urlSearchParams = new URLSearchParams()
  for (const id of ids) {
    urlSearchParams.append('broadcastIds', String(id))
  }

  const params = {
    path: NaverUrl.PLAY_COUNT,
    query: urlSearchParams.toString(),
  }

  return displayApi.get(DisplayUrl.SHOPPING_LIVE, {
    ...options,
    params: params,
  })
}

//몰 이름으로 스토어 정보 조회
export const GetShopInfoByMallName = async (
  mallNames: string[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<LiveBroadcastsShopInfo[]>> => {
  const urlSearchParams = new URLSearchParams()
  for (const mallName of mallNames) {
    urlSearchParams.append('shopNameList', mallName)
  }
  if (options) {
    options.params = urlSearchParams
  }

  return displayApi.get(DisplayUrl.SHOPPING_LIVE_SHOP_INFO, {
    ...options,
    params: urlSearchParams,
  })
}

export const GetReplayBroadCastsVideoUrl = async (
  ids: number[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<LiveBroadCastVideo[]>> => {
  const urlSearchParams = new URLSearchParams()
  for (const id of ids) {
    urlSearchParams.append('broadcastIds', String(id))
  }

  const params = {
    path: NaverUrl.PLAY_INFO_REPLAY,
    query: urlSearchParams.toString(),
  }

  return displayApi.get(DisplayUrl.SHOPPING_LIVE, {
    ...options,
    params: params,
  })
}

export const GetOnairBroadCastsVideoUrl = async (
  ids: number[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<LiveBroadCastVideo[]>> => {
  const urlSearchParams = new URLSearchParams()
  for (const id of ids) {
    urlSearchParams.append('broadcastIds', String(id))
  }

  const params = {
    path: NaverUrl.PLAY_INFO_ONAIR,
    query: urlSearchParams.toString(),
  }

  return displayApi.get(DisplayUrl.SHOPPING_LIVE, {
    ...options,
    params: params,
  })
}
export default {
  PostBroadCasts: GetBroadCasts,
  GetBroadCastsPlayCount,
}
