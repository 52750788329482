import { Module } from 'vuex'
import { RootState } from '@/store/RootState'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import { GetDibsSubscribeInfo } from '@/api/displayAPI/DibsAPI'
import { DibsData, DibsProductData } from '@/util/dibs'

const getters: GetterTreeAdaptor<DibsGetters, DibsState, RootState> = {
  getDibsSubscribeMall(state: DibsState) {
    return state.dibs.todayShopItemGroup
  },
  getDibsSubscribeMallItem(state: DibsState) {
    return state.dibs.subscribeShopItemGroup
  },
  getDibsProductCategory(state: DibsState) {
    return state.dibsProduct.categoryList
  },
  getDibsState(state: DibsState) {
    return { ...state }
  },
}

const actions: ActionTreeAdaptor<DibsAction, DibsState, RootState> = {
  async asyncGetDibsSubscribe({ commit }) {
    try {
      const { data } = await GetDibsSubscribeInfo()
      commit('setDibs', data)
    } catch (e) {
      console.log('e', e)
    }
  },
  async fetchSwipe({ commit }, payload) {
    commit('setSwipe', payload)
  },
  async fetchDibsSearchInfo({ commit }, payload: DibsSearchInfo) {
    commit('setDibSearchInfo', payload)
  },
  async fetchDibsProductList({ commit }, payload: ProductItem[]) {
    commit('setDibsProductList', payload)
  },
  async fetchDibsScroll({ commit }, payload) {
    commit('setDibsScroll', payload)
  },
  async fetchRefresh({ commit }, payload) {
    commit('setDibsRefresh', payload)
  },

  async fetchScrollHistory({ commit }, payload) {
    commit('setScrollHistory', payload)
  },
  async fetchCount({ commit }, payload) {
    commit('setCount', payload)
  },
}

export const DibsStore: Module<DibsState, RootState> = {
  namespaced: true,
  state: {
    dibsRefresh: false,
    dibs: DibsData,
    dibsProduct: DibsProductData,
    dibsCount: 0,
    dibsProductList: [],
    dibsSwipeBlock: false,
    dibsSearchInfo: {
      activeTab: 0,
      category: {
        categoryCode: 0,
        categoryName: '',
        productCount: 0,
      },
      availableFlag: false,
      saleFlag: false,
    },
    dibsScroll: [0, 0],
  },
  getters,
  mutations: {
    setDibs(state: DibsState, dibs: DibsGroup) {
      state.dibs = dibs
    },
    setDibsProductData(state: DibsState, dibsProduct: DibsProductGroup) {
      state.dibsProduct = dibsProduct
    },
    setSwipe(state: DibsState, payload: { yn: boolean }) {
      state.dibsSwipeBlock = payload.yn
    },
    setDibSearchInfo(state: DibsState, payload: DibsSearchInfo) {
      state.dibsSearchInfo = { ...payload }
    },
    setDibsScroll(state: DibsState, payload: { scroll: number; pre?: number }) {
      state.dibsScroll[
        payload.pre !== undefined ? payload.pre : state.dibsSearchInfo.activeTab
      ] = payload.scroll
    },
    setDibsRefresh(state: DibsState, payload: boolean) {
      state.dibsRefresh = payload
    },
    setDibsProductList(state: DibsState, payload: ProductItem[]) {
      state.dibsProductList = payload
    },
    setScrollHistory(state: DibsState, payload: number[]) {
      state.dibsScroll = payload
    },
    setCount(state: DibsState, payload: number) {
      state.dibsCount = payload
    },
  },
  actions,
}

export interface DibsState {
  dibsRefresh: boolean
  dibs: DibsGroup
  dibsProduct: DibsProductGroup
  dibsProductList: ProductItem[]
  dibsSwipeBlock: boolean
  dibsSearchInfo: DibsSearchInfo
  dibsScroll: number[]
  dibsCount: number
}

export interface DibsGetters {
  getDibsState: DibsState
  getDibsSubscribeMall: DibsSubscribeInfo
  getDibsSubscribeMallItem: SubscribeShopItemGroup
  getDibsProductCategory: DibsTabCategory
}

export type DibsAction = {
  asyncGetDibsSubscribe: () => Promise<void>
  fetchSwipe: (payload: { yn: boolean }) => Promise<void>
  fetchDibsSearchInfo: (payload: DibsSearchInfo) => Promise<void>
  fetchDibsScroll: (payload: { scroll: number; pre?: number }) => Promise<void>
  fetchRefresh: (payload: boolean) => Promise<void>
  fetchDibsProductList: (payload: ProductItem[]) => Promise<void>
  fetchScrollHistory: (payload: Number[]) => Promise<void>
  fetchCount: (payload: number) => Promise<void>
}
