<template>
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.3335 4.58325L4.25016 7.49992L10.6668 1.08325"
      stroke="#404346"
      stroke-linecap="square"
    />
  </svg>
</template>

<script ts="lang">
import Vue from 'vue'
export default Vue.extend({})
</script>
