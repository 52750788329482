import { RootState } from '@/store/RootState'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import { Module } from 'vuex'
import { criteo, kakaoPixel } from '@/plugins/util'
import { openSubWebView } from '@/plugins/util/appInterface'

const BRIDGE_TIME_OUT = 2000

const getters: GetterTreeAdaptor<Getters, BridgeState, RootState> = {
  getBridgeDialog(state: BridgeState) {
    return state.showBridge
  },
  getDebounce(state: BridgeState) {
    return state.debounce
  },
}

const actions: ActionTreeAdaptor<BridgeActions, BridgeState, RootState> = {
  async fetchRendingProduct({ commit }, payload) {
    commit('setRendingProduct', payload)
  },
  async clearDebounce({ state }) {
    await clearTimeout(state.debounce)
  },
  async iosSubWebView({ state, commit }) {
    const payload = {
      url: state.src,
      title: state.mallName,
      shopId: state.shopId,
      productNo: state.productNo,
    }
    commit(
      'setDebounce',
      setTimeout(async () => {
        kakaoPixel('viewContent', { id: payload.productNo })
        criteo({ event: 'viewItem', item: payload.productNo })
        openSubWebView(payload)

        setTimeout(async () => {
          // bridge 팝업을 중간에 닫을 떄, 쌓아둔 history를 지우기 위함
          await commit('setShowBridge', false)
        }, 500)
      }, BRIDGE_TIME_OUT)
    )
  },
  async aosSubWebView({ state, commit }) {
    const payload = {
      url: state.src,
      title: state.mallName,
      shopId: state.shopId,
      productNo: state.productNo,
    }
    commit(
      'setDebounce',
      setTimeout(async () => {
        criteo({ event: 'viewItem', item: payload.productNo })
        kakaoPixel('viewContent', { id: payload.productNo })
        openSubWebView(payload)

        // bridge 팝업을 중간에 닫을 떄, 쌓아둔 history를 지우기 위함
        await commit('setShowBridge', false)
      }, BRIDGE_TIME_OUT)
    )
  },
  async hrefSubWebView({ state, commit }) {
    commit(
      'setDebounce',
      setTimeout(() => {
        criteo({ event: 'viewItem', item: state.productNo })
        kakaoPixel('viewContent', { id: state.productNo })
        window.location.replace(state.src)
        // 페이지 이동 시에는 load로 체크
        window.onload = async () => {
          await commit('setShowBridge', false)
        }
      }, BRIDGE_TIME_OUT)
    )
  },
  async openWindowSubWebView({ state, commit }) {
    commit(
      'setDebounce',
      setTimeout(async () => {
        criteo({ event: 'viewItem', item: state.productNo })
        kakaoPixel('viewContent', { id: state.productNo })
        window.open(state.src, '_blank')
        setTimeout(async () => {
          await commit('setShowBridge', false)
        }, 500)
      }, BRIDGE_TIME_OUT)
    )
  },
}

export const BridgeStore: Module<BridgeState, RootState> = {
  namespaced: true,
  state: {
    showBridge: false,
    src: '',
    shopId: '',
    mallName: '',
    productNo: '',
    debounce: 0,
    nameKr: '',
  },
  mutations: {
    setShowBridge(state: BridgeState, isShow: boolean) {
      state.showBridge = isShow
    },
    setSrc(state: BridgeState, src: string) {
      state.src = src
    },
    setShopId(state: BridgeState, shopId: string) {
      state.shopId = shopId
    },
    setMallName(state: BridgeState, mallName: string) {
      state.mallName = mallName
    },
    setShowBridgeForShop(state: BridgeState, shopInfo: Shop) {
      state.src = shopInfo.urlMobile
      state.shopId = String(shopInfo.shopId)
      state.mallName = shopInfo.shopName
      state.showBridge = true
    },
    setProductNo(state: BridgeState, productNo: string) {
      state.productNo = productNo
    },
    setDebounce(state: BridgeState, debounce: number) {
      state.debounce = debounce
    },
    setRendingProduct(state: BridgeState, payload: ProductItem) {
      state.showBridge = true
      state.src = payload.urlMobile
        ? payload.urlMobile +
          '?dtm_source=cellook&nt_source=cellook&nt_medium=cellook'
        : payload.urlPc +
          '?dtm_source=cellook&nt_source=cellook&nt_medium=cellook'
      state.shopId = payload.mallId
      state.mallName = payload.shopName ?? payload.mallName ?? ''
      state.productNo = payload.productNo
      state.nameKr = payload.nameKr ?? ''
    },
  },
  getters,
  actions,
}

export interface BridgeState {
  showBridge: boolean
  src: string
  shopId: string
  mallName: string
  productNo: string
  debounce: number
  nameKr: string
}

export interface Getters {
  getBridgeDialog: boolean
  getDebounce: number
}

export type BridgeActions = {
  clearDebounce: () => Promise<void>
  aosSubWebView: () => Promise<void>
  iosSubWebView: () => Promise<void>
  hrefSubWebView: () => Promise<void>
  openWindowSubWebView: () => Promise<void>
  fetchRendingProduct: (payload: ProductItem) => Promise<void>
}
