
import Vue from 'vue'
import Layout from '@/components/common/layout/Layout.vue'
import { setAccessToken } from '@/plugins/axios'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Loading from '@/components/common/loading/Loading.vue'
import BridgeDialog from '@/components/views/bridge/BridgeDialog.vue'
import LoginDialog from '@/components/views/login/LoginDialog.vue'
import { setUid } from '@/plugins/axios'
import Confirm from '@/components/common/dialogs/Confirm.vue'
import Alert from '@/components/common/dialogs/Alert.vue'
import { v4 as uuidv4 } from 'uuid'
import { onRequestAdonBanner } from '@/helper/banner'
import { useThrottleFn } from '@vueuse/core'

export default Vue.extend({
  name: 'App',
  components: {
    BridgeDialog,
    Layout,
    Loading,
    LoginDialog,
    Confirm,
    Alert,
  },
  data: () => ({
    prevScroll: 0,
    onTopScroll: false,
    mainTabChange: false,
    timer: 0,
  }),
  computed: {
    ...mapState('LoadingStore', ['loading']),
    ...mapState('BannerStore', ['onBanner', 'flipBanner']),
    ...mapState('DialogStore', [
      'loginDialog',
      'networkDialog',
      'showTokenAlert',
    ]),
    ...mapState('BridgeStore', ['showBridge']),
    ...mapState('DeviceStore', ['deviceInfo']),
    ...mapState('HomeStore', ['redirect']),
    ...mapGetters('ProfileStore', ['getProfile']),
    ...mapGetters('HomeStore', ['getHomeState']),
  },
  watch: {
    'getProfile.userInfo.retrySignIn'(yn) {
      if (yn) this.$logout()
    },
    'redirect.activeTab'() {
      // TODO : 리팩토링 대상
      // 현재 SPA 특성상 탭 / router 이동 시에 이전 스크롤 기준으로 flipbanner가 노출이 되는데
      // 위 사항을 막기 위해 애라 watch로 activeTab / path를 보는 건... 검토필요 ( Header, TopBanner 변경 작업이 필요성을 느낌 )
      this.mainTabChange = true
      setTimeout(() => {
        this.mainTabChange = false
      }, 500)
    },
    '$route.name': {
      immediate: true,
      handler(name) {
        onRequestAdonBanner(name)
      },
    },
    '$route.path'(path) {
      if (path === '/') {
        this.mainTabChange = true
        setTimeout(() => {
          this.mainTabChange = false
        }, 500)
      }
      setTimeout(() => {
        if (this.$getAppHtml.scrollTop < 100) {
          this.onTopScroll = false
        } else {
          this.onTopScroll = true
        }
      })
    },
  },
  async created() {
    // App 실행 시, 로그인 체크
    this.confirmLoginStatus()

    await this.getHybridAppInfo()
    // 앱 유저라면, uid 셋팅
    await this.getDeviceInfo()
    // healthCheck
    try {
      // 긴급 공지 확인 로직 - 최초 init
      await this.fetchGetProfile()
        .then(() => {
          this.validUrgentNotice()
        })
        .catch(() => {
          console.log('init get profile error')
        })

      // profile 패치 후 유효하지 않은 코튼 확인 후, 토큰 전체 삭제
      this.validateToken()
      // version check를 위한 profile info
      // QA 를 위한 Alpha Version 확인 log
      console.log('##################################')
      console.log('########### WEB VERSION ##########')
      console.log(`############## ${this.$webVersion} ############`)
      console.log('##################################')
      // 긴급 공지 확인 로직 - 5분주기
      this.timer = setInterval(() => {
        console.log('##################################')
        console.log('########### WEB VERSION ##########')
        console.log(`############## ${this.$webVersion} #############`)
        console.log('##################################')
        // 현재 버전
        this.fetchGetProfile()
          .then(async () => {
            const { appInfo } = this.getProfile
            const { androidMinVersion, iOSMinVersion, webVersion } = appInfo
            console.dir(`setInterval getProfile `, this.getProfile)
            this.$checkAppUpdate(androidMinVersion, iOSMinVersion)

            if (webVersion !== this.$webVersion) {
              // app 리로디 인터페이스 호출
              await this.$clearCache()
              await this.$reloadWebView()
              window.location.reload()
            }
            // 긴급 점검 공지 체크
            this.validUrgentNotice()
            // profile 패치 후 유효하지 않은 코튼 확인 후, 토큰 전체 삭제
            this.validateToken()
          })
          .catch((e) => {
            console.log('web version check error')
          })
      }, this.$urgentNoticeTime)
    } catch (e) {
      // this.$router.push('/500')
    }
    // AOS 에게 모달 닫힌 상태를 알림
    if (this.$device.isAndroid && this.deviceInfo !== null) {
      window.CellookBridge.closeModal(false)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$getAppHtml.addEventListener(
        'scroll',
        useThrottleFn(() => {
          this.handleScroll()
        }, 300)
      )
    })
    // test
    document.addEventListener(
      'visibilitychange',
      this.handleVisibilityChange,
      false
    )
    // 하이브리드앱 으로 부터 받은 이벤트
    window.addEventListener('message', this.receiveMessage, false)
    this.$criteo({ event: 'viewHome' })
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    this.timer = 0
    this.$getAppHtml.removeEventListener('scroll', this.handleScroll)
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange,
      false
    )
    document.removeEventListener('message', this.receiveMessage)
  },
  methods: {
    ...mapMutations('DeviceStore', ['setDeviceInfo']),
    ...mapMutations('BridgeStore', ['setShowBridge']),
    ...mapMutations('DialogStore', ['setLoginDialog']),
    ...mapActions('UserStore', ['fetchUserProfile']),
    ...mapActions('ProfileStore', ['fetchGetProfile']),
    ...mapActions('BannerStore', ['fetchOnBanner']),
    ...mapActions('DeviceStore', ['fetchGetSettings', 'fetchSetPushYn']),
    ...mapActions('DibsStore', ['asyncGetDibsSubscribe']),
    ...mapActions('BridgeStore', ['clearDebounce']),
    ...mapActions('DialogStore', ['fetchNetworkDialog', 'fetchTokenAlert']),
    async getHybridAppInfo() {
      // 하이브리드앱 정보 얻기
      try {
        if (this.$device.isAndroid) {
          const deviceInfo = await window.CellookBridge?.getDeviceInfo()
          await this.setDeviceInfo(JSON.parse(deviceInfo ?? 'null'))

          //디바이스 푸시 ON/OFF 정보가져오기
          const pushYNFromDevice = await window.CellookBridge?.getDevicePush()
          if (pushYNFromDevice) {
            await this.fetchSetPushYn(pushYNFromDevice)
          }
        } else if (this.$device.isIOS) {
          await window.webkit?.messageHandlers?.getDeviceInfo?.postMessage('')
          let device = 'null'
          if (window.iosDeviceInfo !== undefined) {
            device = JSON.stringify(window.iosDeviceInfo)
          }
          this.setDeviceInfo(JSON.parse(device))

          //디바이스 푸시 ON/OFF 정보가져오기
          await window.webkit?.messageHandlers?.getDevicePush?.postMessage('')
          if (window.iosPushYNFromDevice !== undefined) {
            await this.fetchSetPushYn(window.iosPushYNFromDevice)
          }
        }
      } catch (error) {
        console.log('getHybridAppInfo', error)
      }
    },
    // TODO
    handleScroll() {
      const { scrollTop, offsetHeight, scrollHeight } = this.$getAppHtml
      const { isIOS } = this.$device
      this.onTopScroll = scrollTop > 100

      // this.mainTabChange && scrollTop === this.redirect.scroll[0]
      if (this.mainTabChange) {
        this.fetchOnBanner({ onBanner: true })
        this.prevScroll = scrollTop
        return
      }

      if (isIOS && offsetHeight + scrollTop > scrollHeight) return // safari bounce back
      if (!isIOS && offsetHeight + scrollTop >= scrollHeight) return // safari 제외 맨아래 지점 체크
      if (!this.flipBanner) return // 플립배너를 미사용중이고

      if (this.prevScroll > scrollTop || scrollTop <= 0) {
        this.fetchOnBanner({ onBanner: true })
      } else {
        if (scrollTop < this.$flipBannerHeight) return
        this.fetchOnBanner({ onBanner: false })
      }

      this.prevScroll = scrollTop
    },
    async confirmLoginStatus() {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        setAccessToken(String(accessToken))
        this.fetchUserProfile()
        // 앱에게 토큰 정보 전달
        this.$setTokenSubWebView(accessToken ?? '')
        if (this.deviceInfo) this.redirectSubWebView()
      } else {
        console.log('비로그인')
      }

      //회원가입 정보동의창 취소 눌렀을때
      //로그인시도한 페이지로 리다이렉트 후 로그인 다이얼로그 오픈
      // if (!document.location.href.includes('loginLandingPage')) {
      //   this.setLoginDialog(true)
      // }
    },
    getWebUUidFor() {
      if (!localStorage.getItem('uid')) {
        return `w-${uuidv4()}`
      }
      return localStorage.getItem('uid')
    },
    async getDeviceInfo() {
      // setUid('42B92433-7FE5-4F49-8DB2-6C25ED2B65D3')
      const webUuid = this.getWebUUidFor()
      console.log('webUuid', webUuid)
      const uid = this.deviceInfo?.uid ?? webUuid
      await localStorage.setItem('uid', uid)
      setUid(uid)
      if (!this.deviceInfo) {
        return
      }
      try {
        await this.fetchGetSettings()
      } catch (error) {
        console.error(error)
      }
    },
    // test code
    async handleVisibilityChange() {
      if (document.visibilityState === 'hidden') {
        console.log('앱/브라우저 하이드 상태')
      } else {
        await this.getDeviceInfo()
        console.log('앱/브라우저 활성화 상태')
        // 만약에 서브 웹뷰에서 구독을 눌렀을 떄, 갱신을 하려면
        // 디바이스 정보가 있는 유저는 하이브리드 앱 유저
        if (this.isLogined && this.deviceInfo) {
          this.asyncGetDibsSubscribe()
        }
      }
    },
    receiveMessage(e: any) {
      if (typeof e.data !== 'object' && Array.isArray(e.data)) return
      if (e.data.type === 'moveRoute') {
        const values = {
          url: e.data.url,
        }
        this.$router.push(values.url)
      }

      if (e.data.type === 'cellookLogin') {
        this.$logout()
        const values = {
          src: e.data.url,
          title: e.data.title,
          shopId: e.data.shopId,
          productNo: e.data.productNo,
        }
        // url / shopId / title 로컬에 저장
        localStorage.setItem('receivedValue', JSON.stringify(values))
        // 로그인 모달 오픈
        if (!this.showTokenAlert) {
          this.setLoginDialog(true)
        }
      }

      if (e.data.type === 'cellookTokenRefresh') {
        const values = {
          src: e.data.url,
          title: e.data.title,
          shopId: e.data.shopId,
          productNo: e.data.productNo,
        }
        // url / shopId / title 로컬에 저장
        localStorage.setItem('receivedValue', JSON.stringify(values))
        this.redirectSubWebView()
      }

      // 열린 모달 닫기 ( 브릿지 / 로그인)
      if (e.data.type === 'cellookClose') {
        // BridgeDialog 열린 상태면 닫기
        if (this.showBridge) {
          this.clearDebounce()
          this.setShowBridge(false)
        }
        // 로그인 모달 열린 상태면 닫기
        if (this.loginDialog) {
          this.setLoginDialog(false)
          this.redirectSubWebView()
        }
      }

      if (e.data.type === 'cellookLogout') {
        try {
          // TODO : 05/31 logout global function
          this.$logout()
        } catch (error) {
          console.log(error)
        }
      }
      // SubWebView 에서, 상품을 보면, 웹에게 해당 ProductItem 을 전달해준다.
      // 최근본 상품에 추가 한다.
      if (
        e.data.type === 'cellookRecentProduct' &&
        typeof e.data.product === 'object'
      ) {
        this.$saveRecentProduct(e.data.product)
      }

      //디바이스 알림 on/off 받아온다.
      if (e.data.type === 'setDevicePush') {
        this.fetchSetPushYn(e.data.isOn)
        console.log('setDevicePush data', e.data.isOn)
      }
    },
    async handleNetworkDialog(yn: boolean) {
      if (!yn) return this.fetchNetworkDialog(false)
      // 새로고침 버튼
      // 팝업이 띄어져 있고, 네트워크 연결이 되어 있을 경우
      if (window.navigator.onLine) {
        this.fetchNetworkDialog(false)
        if (this.$device.isAndroid && this.deviceInfo) {
          return window.CellookBridge.reloadWebView()
        }

        if (this.$device.isIOS && this.deviceInfo) {
          return window.webkit.messageHandlers.reloadWebView.postMessage('')
        }

        this.$router.go(0)
      } else {
        this.fetchNetworkDialog(false)
        setTimeout(() => {
          this.fetchNetworkDialog(true)
        })
      }
    },
    // 이름 리네임 해주세요.
    validateToken() {
      // profile 패치 후 유효하지 않은 코튼 확인 후, 토큰 전체 삭제
      if (this.getProfile.userInfo?.retrySignIn === true) {
        this.$logout()
      }
    },
    redirectSubWebView() {
      // 하이브리드앱으로부터 전달받은 값이 있다면, 서브 웹뷰 오픈
      const receivedValue = JSON.parse(
        localStorage.getItem('receivedValue') ?? 'null'
      )

      if (receivedValue == null) return

      // removeItem
      localStorage.removeItem('receivedValue')
      const payload = {
        url: receivedValue.src,
        title: receivedValue.title,
        shopId: receivedValue.shopId,
        productNo: receivedValue.productNo,
      }
      this.$openSubWebView(payload)
    },
    async handleAlertClose() {
      // logout 상태에서 찜페이지 재진입 불가능
      localStorage.setItem('preHomeHistory', JSON.stringify(this.getHomeState))
      await this.fetchTokenAlert(false)
      this.setLoginDialog(true)
    },
    validUrgentNotice() {
      const { useYN, startDt, endDt } = this.getProfile.noticeMaintenance
      if (useYN === 'Y' && this.$moment().isBetween(startDt, endDt)) {
        return this.$router.push('/urgentNotice')
      }
    },
  },
})
