import { RootState } from '@/store/RootState'
import { Module } from 'vuex'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'

const actions: ActionTreeAdaptor<StoreHomeAction, StoreHomeState, RootState> = {
  async fetchHistory({ commit }, payload) {
    commit('setHistory', payload)
  },
  async fetchMarginHeight({ commit }, payload) {
    commit('setMarginHeight', payload)
  },
  async fetchScroll({ commit }, payload) {
    commit('setScroll', payload)
  },
  async fetchList({ commit }, payload) {
    commit('setList', payload)
  },
  async fetchHistoryInit({ commit }) {
    commit('setHistoryInit')
  },
}

const getters: GetterTreeAdaptor<StoreHomeGetters, StoreHomeState, RootState> =
  {
    getStoreHomeHistory(state: StoreHomeState) {
      return state.history
    },
    getMarginHeight(state: StoreHomeState) {
      return state.history.marginHeight
    },
  }

export const StoreHomeStore: Module<StoreHomeState, RootState> = {
  namespaced: true,
  state: {
    history: {
      init: true,
      shopInfo: {} as Shop,
      list: [],
      trendPickList: [],
      total: 0,
      marginHeight: 0,
      scroll: 0,
      categoryTab: 0,
      filterIndex: 2,
      sort: 'latest',
      active: 0,
    },
  },
  getters: getters,
  mutations: {
    setHistory(state: StoreHomeState, payload: StoreHomeHistory) {
      state.history = { ...payload, init: false }
    },
    setMarginHeight(state: StoreHomeState, payload: number) {
      state.history.marginHeight = payload
    },
    setScroll(state: StoreHomeState, payload: number) {
      state.history.scroll = payload
    },
    setList(state: StoreHomeState, payload: ProductItem[]) {
      state.history.list = payload
    },
    setHistoryInit(state: StoreHomeState) {
      state.history = {
        init: true,
        shopInfo: {} as Shop,
        list: [],
        trendPickList: [],
        total: 0,
        marginHeight: 0,
        scroll: 0,
        categoryTab: 0,
        filterIndex: 2,
        sort: 'latest',
        active: 0,
      }
    },
  },
  actions,
}

export interface StoreHomeState {
  history: StoreHomeHistory
}

export interface StoreHomeHistory {
  init: boolean
  shopInfo: Shop
  trendPickList: ProductItem[]
  list: ProductItem[]
  marginHeight: number
  scroll: number
  total: number
  categoryTab: number
  filterIndex: number
  sort: string
  active: number
}

export interface StoreHomeGetters {
  getStoreHomeHistory: StoreHomeHistory
  getMarginHeight: number
}

export type StoreHomeAction = {
  fetchHistory: (payload: StoreHomeState) => Promise<void>
  fetchMarginHeight: (payload: number) => Promise<void>
  fetchScroll: (payload: number) => Promise<void>
  fetchList: (payload: ProductItem[]) => Promise<void>
  fetchHistoryInit(): Promise<void>
}
