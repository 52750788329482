export const oneDepthTracking: any = {
  전체: 'cate_all',
  아우터: 'cate_outer',
  '티&탑': 'cate_teetop',
  스커트: 'cate_skirt',
  원피스: 'cate_ops',
  팬츠: 'cate_pants',
  데님팬츠: 'cate_denimpants',
  액티브웨어: 'cate_activewear',
  '홈/이너웨어': 'cate_homeinner',
  슈즈: 'cate_shoes',
  백: 'cate_bag',
  주얼리: 'cate_jewelry',
  코디소품: 'cate_cody',
}

export const onDepthGroupTracking = [
  'cate_all',
  'cate_clothes',
  'cate_bagshoes',
]
export const initCategoryHistory: CategoryPageHistory = {
  categoryCode: '7',
  categoryTab: 0,
  categoryName: '전체',
  categoryDetailTab: {
    categoryCode: '7',
    categoryName: '전체',
  },
  categoryFilterHelper: {
    sort: [],
    price: {
      title: '',
      filter: {
        'filters.maxPrice': 0,
        'filters.minPrice': 0,
      },
    },
    saleRate: [],
  },
  categoryFilter: 2,
  sortOption: 'latest',
  categoryDetailFilter: {
    priceData: { minPrice: 0, maxPrice: 0 },
    saleRateData: { minSaleRate: 0, maxSaleRate: 0, title: '' },
  },
  weeklyOrMonthly: [],
  weeklyOrMonthlyTab: 0,
  isSaleProduct: 0,
  paging: {
    page: 0,
    limit: 50,
    total: 0,
  },
  currentPage: '',
  scrollLocation: 0,
  promotionNo: 0,
  marginHeight: 0,
  searchList: [],
  bestSearchList: [],
}
