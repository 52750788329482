import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import icons from './icons'
import '../styles/_index.scss'

Vue.use(Vuetify)

export default new Vuetify({
  icons: icons,
  treeShake: true,
  options: {
    customProperties: true,
  },
  theme: {
    themes: {
      light: {
        default: '#FFFFFF', // showa 에서 사용하는 흰색
        default_t: '#222222', // showa 에서 사용하는 검정
        primary: '#003399', // Cellook 에서 사용하는 파랑
        primary_t: '#FFFFFF',
        secondary: '#D8D9DC', // showa에서 사용하는 회색
        secondary_t: '#FFFFFF',
        anchor: '#FFFFFF',
        anchor_t: '#D8D9DC',
        naver: '#5BC752', // naver 로고에 사용하는 초록
        naver_t: '#FFFFFF',
        apple: '#000000', // apple 로고에 사용하는 검정
        apple_t: '#FFFFFF',
        primary_s: '#003399',
        primary_s_t: '#003399',
        gray700_t: '',
        error: '#FFFFFF',
        // 단일 스타일 컬러
        white: '#FFFFFF', // 흰색
        purple: '#774EFF',
        red: '#FF5266',
        red_t: '#FFFFFF',
        black: '#000000',
        gray900: '#222222',
        gray800: '#59616E',
        gray700: '#404346',
        gray600: '#787E88',
        gray500: '#90949A',
        gray400: '#C2C4C9',
        gray300: '#D8D9DC',
        gray200: '#EAEBED',
        gray100: '#F7F8F9',
        gray50: '#F7F3E9',
        gradient: 'linear-gradient(135deg, #2416AD 0%, #6B1EAB 100%)',
        light_gradient: 'linear-gradient(135deg, #D250FF 0%, #461AC6 100%)',
        light_purple: '#F3F1FD',
        light_blue: '#F3F6FB',
        light_gray: '#F5F7FA',
        border_color: '#F4F4F4',
        dom_color: 'rgba(0, 0, 0, 0.4)',
        gray: '#F3F4F5',
        gray650: '#A8ACBD', //가칭 sale chip
        gray250: '#F5F6FA', //가칭 sale chip background-color
        gray500_gradient: 'linear-gradient(315deg, #2f4353 0%, #d2ccc4 74%)',
        purple_dark: '#473A72',
        // 변경 컬러
        blue: '#003399',
        blue800: '#002673',
        blue700: '#003399',
        blue600: '#1D54C4',
        blue500: '#2868E8',
        blue400: '#5E91F8',
        blue300: '#9EBCF8',
        blue200: '#CADCFF',
        blue100: '#E8EFFF',
        blue700_gradient: 'linear-gradient(135deg, #0C7CFF 0%, #003399 100%)',
        blue_gray: '#909881',
        orange100: '#CF4828',
      },
    },
  },
})
