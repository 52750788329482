import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { MyPageUrl } from '../../plugins/enum'
import { displayApi } from '../../plugins/axios'

// 확인 불가하여, 실제 개발자가 오는 리스폰스를 확인 후 인터페이스 작성 해야함
export const GetUserProfileForMyPage = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UserGroup>> =>
  await displayApi.get<UserGroup>(MyPageUrl.MY_PAGE, {
    ...options,
    params,
  })

export const GetLastNoticeTime = async (
  params?: any,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<LikeItemGroup>> =>
  await displayApi.get<LikeItemGroup>(MyPageUrl.MY_PAGE_LAST_NOTICE, {
    ...options,
    params,
  })

export default {
  GetUserProfileForMyPage,
}
