import Vue from 'vue'
import router from '@/router/index'
import { setSocialAccessToken, clearSocialAccessToken } from '@/plugins/axios'
import { PostSigninAndUp } from '@/api/accountAPI/SocialAPI'
export class Apple {
  async init() {
    await window.AppleID.auth.init({
      clientId: process.env.VUE_APP_APPLE_SERVICE_NAME,
      scope: 'name email',
      redirectURI: window.location.origin + '/loginLandingPageApple',
      state: 'cellook-apple',
      usePopup: true,
    })
  }
  async signIn() {
    try {
      const result = await window.AppleID.auth.signIn()

      setSocialAccessToken(result.authorization.id_token)
      const payload: any = {
        userType: 1004,
      }

      try {
        if (result.user.name) {
          payload.appleUser = {
            name: {
              firstName: unescape(result.user.name.firstName),
              lastName: unescape(result.user.name.lastName),
            },
          }
        }
      } catch (error) {
        console.log(error)
      }

      const { data } = await PostSigninAndUp(payload)
      localStorage.setItem('socialLoginPlattform', 'apple')
      localStorage.setItem('accessToken', data.accessToken) //3개월
      localStorage.setItem('refreshToken', data.refreshToken) //6개월
      if (data.isRegister) {
        localStorage.setItem('redirectPath', '/appleLoginMoreInformation')
      }
    } catch (e: any) {
      if (
        e.error !== 'user_cancelled_authorize' &&
        e.error !== 'popup_closed_by_user'
      ) {
        alert(
          '잠시 문제가 있어 로그인을 하지 못했습니다. 조금 뒤에 다시 시도해보세요.'
        )
        // 대체
      }
    } finally {
      clearSocialAccessToken()
    }
  }
}

export const apple = new Apple()
Vue.prototype.$apple = apple
