import { RootState } from '@/store/RootState'
import { Module } from 'vuex'
import { ActionTreeAdaptor } from '@/util'

const actions: ActionTreeAdaptor<CommonAction, CommonState, RootState> = {
  async fetchScrollTopNoti({ commit }) {
    commit('setScrollTopNoti')
  },
  async fetchHeaderColor({ commit }, payload: string) {
    commit('setHeaderColor', payload)
  },
}

export const CommonStore: Module<CommonState, RootState> = {
  namespaced: true,
  state: {
    scrollTopNoti: false,
    headerColor: '#ffffff',
  },
  getters: {},
  mutations: {
    setScrollTopNoti(state: CommonState) {
      state.scrollTopNoti = !state.scrollTopNoti
    },
    setHeaderColor(state: CommonState, payload: string) {
      state.headerColor = payload
    },
  },
  actions,
}

export interface CommonState {
  scrollTopNoti: boolean
  headerColor: string
}

export interface CommonGetters {}

export type CommonAction = {
  fetchScrollTopNoti: () => Promise<void>
  fetchHeaderColor: (payload: string) => Promise<void>
}
