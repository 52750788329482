import { RootState } from '@/store/RootState'
import { Module } from 'vuex'
import { ActionTreeAdaptor } from '@/util'

const actions: ActionTreeAdaptor<LoadginAction, LoadingState, RootState> = {
  // boolean의 값이 이젠 의미가 없어짐.
  // 제거하자니 해당 값으로 뛰우는 건지, 마는 건지 확인을 할 수 있어 일단 둠
  async fetchLoading({ commit }, { yn = false }) {
    commit('setLoading', yn)
  },
}

// let timer: number | undefined = 0
// 중복 이벤트 체크 Time
// scroll handler 현재 다른 페이지에서 time out을 1s로 사용하는 곳이 많이 있어 0.5s 추가적으로 확인

// 22.09.07 위 주석처럼 진행하려고 햇으나, 빠른 처리가 가능한 곳에서도 debounce를 주며하니 느려진 것 같아 제거
// 로직 자체를 변경을 해야할 것 같음
// const OVERLAP_PREVENT_TIME = 1500

export const LoadingStore: Module<LoadingState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
  },
  mutations: {
    setLoading(state: LoadingState, yn: boolean) {
      state.loading = yn

      // 22.09.07 위 주석처럼 진행하려고 햇으나, 빠른 처리가 가능한 곳에서도 debounce를 주며하니 느려진 것 같아 제거
      // 로직 자체를 변경을 해야할 것 같음
      // state.loading = true
      // if (timer) {
      //   clearTimeout(timer)
      //   timer = 0
      // }

      // timer = setTimeout(() => {
      //   state.loading = false
      //   clearTimeout(timer)
      //   timer = 0
      //   // scroll handler 현재 다른 페이지에서 time out을 1s로 사용하는 곳이 많이 있어 0.5s 추가적으로 확인
      //   // 만약 인터넷이 무척 느리면? 개선이 필요한 여지가 있음 - 여러 API들의 동적으로 작용을  해야하는데 개선 방안을 고민
      //   // L 현재 짜여진 형태로는 힘들어 1차 개선 진행 후, 추후 추가 개선
      // }, OVERLAP_PREVENT_TIME)
    },
  },
  actions,
}

export interface LoadingState {
  loading: boolean
}

export type LoadginAction = {
  fetchLoading: (payload: { yn: boolean }) => Promise<void>
}
