import Vue from 'vue'
import Store from '@/store/index'
import { clearAccessToken } from '@/plugins/axios'
import { setTokenSubWebView } from './appInterface'

// 이거 정리 한 번 필요함
// 전역에서 dispatch가 필요허지 않은 항목은 제거
export const logout = async () => {
  clearAccessToken()
  // 해당 fetchUserLogout 이 선행이 되어야함 - token 갱신
  await Store.dispatch('UserStore/fetchUserLogout')
  Store.dispatch('HomeStore/fetchInit')
  Store.dispatch('HomeStore/fetchMainInit', { yn: true })
  Store.dispatch('DisplayStore/asyncGetDisplay')
  Store.dispatch('RecentlyStore/fetchClearRecentlyProduct')
  Store.dispatch('UserStore/fetchUserProfile')
  Store.dispatch('ProfileStore/fetchGetProfile')
  Store.dispatch('MyPageStore/asyncGetMyPage')
  await Store.commit('DeviceStore/setUserSettings')
  setTokenSubWebView('')
}

Vue.prototype.$logout = logout
