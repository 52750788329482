import Router from '@/router'

const requireTokenPage: string[] = [
  '/dibs',
  '/mypage/info',
  '/mypage/withdrawal',
  '/mypage/withdrawalWrite',
  '/signInComplete',
  '/appleLoginMoreInformation',
]

export const tokenValidationPage = (path: string) => {
  if (!requireTokenPage.some((item) => item === path)) return
  Router.go(-2)
}
