import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/app'
import './plugins/axios'
import './plugins/util'
import './plugins/apple'
import './plugins/naver'
import './plugins/util/index'

import moment from 'vue-moment'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import vuetify from './plugins/vuetify'
import device from 'vue-device-detector-js'
import VueClipboard from 'vue-clipboard2' // ex ) https://github.com/Inndy/vue-clipboard2

import '@/plugins/component'
import '@egjs/vue-flicking/dist/flicking-inline.css'

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.use(device as any)
Vue.use(moment)
Vue.use(VueLodash, { name: 'custom', lodash: lodash })

Vue.config.productionTip = false
Vue.config.devtools = process.env.VUE_APP_ENV !== 'production'

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
