import { RootState } from '@/store/RootState'
import { Module } from 'vuex'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import {
  GetCheckInList,
  GetCheckInNo,
  GetPrevCheckInList,
} from '@/api/displayAPI/EventAPI'

const getters: GetterTreeAdaptor<CheckInGetters, CheckInState, RootState> = {
  getCheckInData(state: CheckInState) {
    return state.checkInDataGroup
  },
  getCheckInPrevData(state: CheckInState) {
    return state.checkInPrevDataGroup
  },
  getFromNoWhere(state: CheckInState) {
    return state.fromNoWhere
  },
  getNoticeNo(state: CheckInState) {
    return state.noticeNo
  },
  getCheckInStoreScrollLocation(state: CheckInState) {
    return state.scroll
  },
  getCheckInPrevPage(state: CheckInState) {
    return state.prevPage
  },
}

const actions: ActionTreeAdaptor<CheckInAction, CheckInState, RootState> = {
  async fetchCheckInNo({ commit }) {
    const { data } = await GetCheckInNo()
    commit('setCheckInNo', data.checkInNo)
  },
  async fetchCheckInData({ commit }, { checkInNo }) {
    const { data } = await GetCheckInList({ checkInNo })
    commit('setCheckInData', data)
  },
  async fetchFromNoWhere({ commit }, { status }) {
    commit('setFromNoWhere', status)
  },
  async fetchNoticeNo({ commit }, { no }) {
    commit('setNoticeNo', no)
  },
  async fetchCheckInStoreScrollLocation({ commit }, scroll) {
    commit('setCheckInStoreScrollLocation', scroll)
  },
  async fetchCheckInPrevPage({ commit }, payload) {
    commit('setCheckInPrevPage', payload)
  },
  async fetchCheckInPrevData({ commit }) {
    const { data } = await GetPrevCheckInList()
    commit('setCheckInPrevData', data.previousCheckInInfoList)
  },
}

export const CheckInStore: Module<CheckInState, RootState> = {
  namespaced: true,
  state: {
    no: '',
    fromNoWhere: false,
    checkInDataGroup: {
      today: '',
      totalCount: 0,
      checkInDateList: [
        {
          date: '',
          isApply: false,
          isCheckIn: false,
          prize: '',
        },
      ],
      checkInButtonInfo: {
        type: '',
        text: '',
        textColor: '',
        buttonColor: '',
        backgroundColor: '',
      },
      checkInNoticeImage: '',
      checkInGiveawayImage: '',
      checkInBannerList: [
        {
          icon: '',
          link: '',
        },
      ],
      previousCheckInResultButtonInfo: {
        isUse: false,
        link: '',
        type: '',
        text: '',
        textColor: '',
        buttonColor: '',
        backgroundColor: '',
      },
      isApplyActive: false,
    },
    checkInPrevDataGroup: [
      {
        endDate: '',
        hasNext: false,
        hasPrev: false,
        month: '',
        previousCheckInDateList: [
          {
            date: '',
            isApply: false,
            isCheckIn: false,
          },
        ],
        previousEventId: 0,
        startDate: '',
        totalCount: 0,
        year: '',
      },
    ],
    noticeNo: '',
    scroll: 0,
    prevPage: {
      // vue-router 기본값
      fullPath: '/',
      name: null,
      path: '/',
    },
  },
  getters,
  mutations: {
    setCheckInData(state: CheckInState, payload: CheckInDataGroup) {
      state.checkInDataGroup = payload
    },
    setCheckInPrevData(
      state: CheckInState,
      payload: Array<CheckInPrevDataGroup>
    ) {
      state.checkInPrevDataGroup = payload
    },
    setCheckInNo(state: CheckInState, payload: string) {
      state.no = payload
    },
    setFromNoWhere(state: CheckInState, payload: boolean) {
      state.fromNoWhere = payload
    },
    setNoticeNo(state: CheckInState, payload: string) {
      state.noticeNo = payload
    },
    setCheckInStoreScrollLocation(state: CheckInState, payload: number) {
      state.scroll = payload
    },
    setCheckInPrevPage(state: CheckInState, payload: PreviousPage) {
      state.prevPage = payload
    },
  },
  actions,
}

export interface CheckInState {
  no: string | null
  fromNoWhere: boolean
  checkInDataGroup: CheckInDataGroup
  checkInPrevDataGroup: Array<CheckInPrevDataGroup>
  noticeNo: string
  scroll: number
  prevPage: PreviousPage
}

export interface CheckInGetters {
  getCheckInData: CheckInDataGroup
  getFromNoWhere: boolean
  getNoticeNo: string
  getCheckInStoreScrollLocation: number
  getCheckInPrevPage: PreviousPage
  getCheckInPrevData: Array<CheckInPrevDataGroup>
}

export type CheckInAction = {
  fetchCheckInData: (payload: { checkInNo: string }) => Promise<void>
  fetchCheckInPrevData: () => Promise<void>
  fetchCheckInNo: () => Promise<void>
  fetchFromNoWhere: (payload: { status: boolean }) => Promise<void>
  fetchNoticeNo: (payload: { no: string }) => Promise<void>
  fetchCheckInStoreScrollLocation: (scroll: number) => Promise<void>
  fetchCheckInPrevPage: (payload: PreviousPage) => Promise<void>
}

interface PreviousPage {
  fullPath: string
  name: string | null
  path: string
}
