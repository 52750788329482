<template>
  <svg
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M55 20C55 8.95431 46.0457 0 35 0H20C8.95431 0 0 8.95431 0 20V35C0 46.0457 8.95431 55 20 55H35C46.0457 55 55 46.0457 55 35V20Z"
      fill="#4987FF"
    />
    <path
      d="M17.792 27.5833C18.2752 27.5833 18.667 27.1915 18.667 26.7083C18.667 26.225 18.2752 25.8333 17.792 25.8333C17.3087 25.8333 16.917 26.225 16.917 26.7083C16.917 27.1915 17.3087 27.5833 17.792 27.5833Z"
      fill="white"
      stroke="white"
      stroke-width="2.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.7085 27.5833C28.1917 27.5833 28.5835 27.1915 28.5835 26.7083C28.5835 26.225 28.1917 25.8333 27.7085 25.8333C27.2252 25.8333 26.8335 26.225 26.8335 26.7083C26.8335 27.1915 27.2252 27.5833 27.7085 27.5833Z"
      fill="white"
      stroke="white"
      stroke-width="2.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.625 27.5833C38.1082 27.5833 38.5 27.1915 38.5 26.7083C38.5 26.225 38.1082 25.8333 37.625 25.8333C37.1418 25.8333 36.75 26.225 36.75 26.7083C36.75 27.1915 37.1418 27.5833 37.625 27.5833Z"
      fill="white"
      stroke="white"
      stroke-width="2.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({})
</script>
