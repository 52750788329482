import { RootState } from '@/store/RootState'
import { mapActions, mapGetters, mapState, Module } from 'vuex'
import { ActionTreeAdaptor, GetterTreeAdaptor } from '@/util'
import { GetProfile } from '@/api/accountAPI/AppProfileAPI'
import { AppProfile, OptionFilterList } from '../../types/App'

const STORE_NAME = 'ProfileStore'
const DEFAULT_FILTER_TAB_INDEX = 2

const getters: GetterTreeAdaptor<ProfileGetters, ProfileState, RootState> = {
  getProfile(state: ProfileState) {
    return state.profile
  },
  getDefaultFilterIndex(state: ProfileState) {
    return (
      state.profile?.filter?.optionFilterList?.findIndex(
        (value?) => value?.isSelected === true
      ) ?? DEFAULT_FILTER_TAB_INDEX
    )
  },
  getFilterList(state: ProfileState) {
    return state.profile?.filter?.optionFilterList ?? {}
  },
  getCategoryList(state: ProfileState) {
    return state?.profile?.category?.categoryList
  },
}

const actions: ActionTreeAdaptor<ProfileAction, ProfileState, RootState> = {
  async fetchGetProfile({ commit }) {
    const { data } = await GetProfile()
    commit('setProfile', data)
  },
}

export const ProfileStore: Module<ProfileState, RootState> = {
  namespaced: true,
  state: {
    profile: {} as AppProfile,
  },
  getters,
  mutations: {
    setProfile(state: ProfileState, profile: AppProfile) {
      state.profile = profile
    },
  },
  actions,
}

export const mappedProfileState = mapState(STORE_NAME, ['profile'])
export const mappedProfileAction = mapActions(STORE_NAME, ['fetchGetProfile'])
export const mappedProfileGetters = mapGetters(STORE_NAME, [
  'getProfile',
  'getDefaultFilterIndex',
  'getFilterList',
])

export interface ProfileState {
  // v2 api 변경 후 재정의
  profile: AppProfile
}

export interface ProfileGetters {
  // v2 api 변경 후 재정의
  getProfile: AppProfile
  getDefaultFilterIndex: number
  getFilterList: OptionFilterList[]
  getCategoryList: CategoryItem[]
}

export type ProfileAction = {
  fetchGetProfile: (payload: { delayMs: number }) => Promise<void>
}
