
import Vue from 'vue'
import BottomNavi from '../navigations/BottomNavi.vue'
import Header from './Header.vue'
import BottomSheet from '../bottomSheets/ButtomSheets.vue'
import { mapActions, mapState } from 'vuex'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { BottomNavi, BottomSheet, Header },
  props: {
    onBanner: {
      type: Boolean,
      default: true,
    },
    onTopScroll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      needAnimHeader: false,
      showTopBanner: false,
      showHeader: false,
      showNavi: false,
      onMaxWidth: false,
      windowWidth: 0,
      showCategoryHeader: false,
    }
  },
  created() {
    this.fetchCheckInNo()
  },
  computed: {
    ...mapState('BottomSheetStore', ['showSheet', 'type', 'title']),
    ...mapState('BannerStore', ['flipBanner']),
    ...mapState('CheckInStore', ['no']),
    resizeStyle() {
      const number = ((this.windowWidth - 600) / 2 + 20).toString()
      return `right: ${number}px;`
    },
    floatingBtnBottom() {
      if (this.onTopScroll && this.showNavi) {
        return 'bottom:143px;'
      }
      if (this.showNavi) {
        return 'bottom:95px;'
      }
      if (!this.onTopScroll) {
        return 'bottom:20px;'
      }
      return 'bottom:68px;'
    },
    showFloatingCheckIn() {
      const routeName = this.$route.name
      return ![
        // 제외 페이지 목록
        'CheckIn',
        'MyPage',
        'CategoryExhibitionMain',
        'MyPageBoardBoard',
        'MyPageWithdrawal',
        'MyPageWithdrawalWrite',
        'MypageSetting',
        'FooterTerms',
        'FooterRecruit',
        'FooterPrivacy',
        'UrgentNotice',
        'AppleLoginMoreInformation',
        'SignInComplete',
        'LoginLandingPageApple',
        'LoginActionPage',
        'LoginLandingPage',
        'Info',
        'MarketingAgree',
        'PushNotice',
      ].some((item) => item === routeName)
    },
  },
  watch: {
    $route(val) {
      this.showNavi = [
        'Main',
        'CategoryMain',
        'CategoryProductList',
        'CategoryBestList',
        'CategoryNewList',
        'CategorySeasonList',
        'CategoryTopShopList',
        'CategoryExhibitionMain',
        'CategoryExhibitionList',
        'Dibs',
        'MyPage',
        'RecentlyProduct',
        'StoreHome',
        'ExhibitionEvent',
        'PromotionSignUp',
        'PromotionSingleMall',
        'SingleMallPromotion',
        'PromotionSignUpSeptember',
        'PromotionSignUp2',
        'Event',
        'PromotionList',
        'ShoppingLive',
        'EventList',
      ].some((item) => item === val.name)
    },
  },
  mounted() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapActions('BannerStore', ['fetchOnBanner']),
    ...mapActions('CommonStore', ['fetchScrollTopNoti']),
    ...mapActions('CheckInStore', ['fetchCheckInNo']),
    scrollTop() {
      this.fetchScrollTopNoti()
      this.$tracking('universal_go_to_top')
      this.fetchOnBanner({ onBanner: true })
      this.$vuetify.goTo('#app', { container: this.$getAppHtml })
    },
    handleResize() {
      this.windowWidth = window.innerWidth
      if (window.innerWidth > 600) {
        this.onMaxWidth = true
      } else {
        this.onMaxWidth = false
      }
    },
    goToCheckIn() {
      this.$tracking('checkin_floatingbtn')
      this.$router.push({
        name: 'CheckIn',
        query: { no: this.no },
      })
    },
  },
})

interface Data {
  showNavi: boolean
  onMaxWidth: boolean
  windowWidth: number
}
interface Methods {
  fetchOnBanner({ onBanner }: { onBanner: boolean }): void
  scrollTop(): void
  handleResize(): void
  fetchScrollTopNoti(): void
  goToCheckIn(): void
  fetchCheckInNo(): void
}
interface Computed {
  floatingBtnBottom: string
  resizeStyle: string
  title: string
  no: string
  showFloatingCheckIn: boolean
}
interface Props {
  onBanner: boolean
  onTopScroll: boolean
}
