
import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: '몰',
    },
  },
  watch: {
    show() {
      if (this.show) {
        setTimeout(() => {
          this.fetchOffSheet()
        }, 3000)
      }
    },
  },
  methods: {
    ...mapActions('BottomSheetStore', ['fetchOffSheet']),
  },
})
interface Data {}
interface Methods {
  fetchOffSheet(): void
}
interface Computed {}
interface Props {
  show: boolean
  type: number
  title: string
}
