import NewIcon from '../components/common/icons/New.vue'
import LikeIcon from '../components/common/icons/Like.vue'
import LogoIcon from '../components/common/icons/Logo.vue'
import NextIcon from '../components/common/icons/Next.vue'
import WhiteNextIcon from '../components/common/icons/WhiteNext.vue'
import Instagram from '../components/common/icons/Instagram.vue'
import KakaoChannel from '../components/common/icons/KakaoChannel.vue'
import NaverBlog from '../components/common/icons/NaverBlog.vue'
import AppDownloadIcon from '../components/common/icons/AppDownload.vue'
import PrevIcon from '../components/common/icons/Prev.vue'
import DownTriangleIcon from '../components/common/icons/DownTriangle.vue'
import CloseIcon from '../components/common/icons/Close.vue'
import CloseWhiteIcon from '../components/common/icons/CloseWhiteIcon.vue'
import CloseDetailDialog from '../components/common/icons/CloseDetailDialog.vue'
import NextHazyIcon from '../components/common/icons/NextHazy.vue'
import SymbolLogoB from '../components/common/icons/SymbolLogoB.vue'
import DownBtn from '../components/common/icons/DownBtn.vue'
import RefreshIcon from '../components/common/icons/Refresh.vue'
import RefreshActiveIcon from '../components/common/icons/RefreshActive.vue'
import OutlineNextIcon from '../components/common/icons/OutlineNext.vue'
import HomeIcon from '../components/common/icons/Home.vue'
import ActiveHomeIcon from '..//components/common/icons/ActiveHome.vue'
import CategoryIcon from '../components/common/icons/Category.vue'
import ActiveCategoryIcon from '../components/common/icons/ActiveCategory.vue'
import DibsIcon from '../components/common/icons/Dibs.vue'
import ActiveDibsIcon from '../components/common/icons/ActiveDibs.vue'
import MyPageIcon from '../components/common/icons/MyPage.vue'
import ActiveMyPageIcon from '../components/common/icons/ActiveMyPage.vue'
import RecentlyProductIcon from '../components/common/icons/RecentlyProduct.vue'
import ActiveRecentlyProductIcon from '../components/common/icons/ActiveRecentlyProduct.vue'
import BtnMoreIcon from '../components/common/icons/BtnMore.vue'
import TopVectorIcon from '../components/common/icons/TopVector.vue'
import TopVectorIcon2 from '../components/common/icons/TopVector2.vue'
import RightVectorIcon from '../components/common/icons/RightVector.vue'
import RightVectorBlackIcon from '../components/common/icons/RightVectorBlack.vue'
import RightVetorGrayIcon from '../components/common/icons/RightVectorGray.vue'
import BookmarkIcon from '../components/common/icons/Bookmark.vue'
import BookmarkActiveIcon from '../components/common/icons/BookmarkActive.vue'
import BookmarkRadiusIcon from '../components/common/icons/BookmarkRadius.vue'
import BookmarkRadiusActiveIcon from '../components/common/icons/BookmarkRadiusActive.vue'
import SharedKakaoIcon from '../components/common/icons/SharedKakao.vue'
import SharedUrlIcon from '../components/common/icons/SharedUrl.vue'
import SharedMoreIcon from '../components/common/icons/SharedMore.vue'
import SaleCheckIcon from '../components/common/icons/SaleCheck.vue'
import RegistFilterIcon from '../components/common/icons/RegistFilter.vue'
import OutlinePhoneIcon from '../components/common/icons/OutlinePhone.vue'
import OutlineUserIcon from '../components/common/icons/OutlineUser.vue'
import UpTriangleIcon from '../components/common/icons/UpTriangle.vue'
import AddIcon from '../components/common/icons/Add.vue'
import BookmarkGrayIcon from '../components/common/icons/BookmarkGray.vue'
import BookmarkWhiteIcon from '../components/common/icons/BookmarkWhite.vue'
import BookmarkWhiteActiveIcon from '../components/common/icons/BookmarkWhiteActive.vue'
import PinIcon from '../components/common/icons/Pin.vue'
import RightNextIcon from '../components/common/icons/RightNext.vue'
import MoreIcon from '../components/common/icons/More.vue'
import NewSmallIcon from '../components/common/icons/NewSmall.vue'
import SignInCompleteIcon from '../components/common/icons/SignInComplete.vue'
import SaleUncheckIcon from '../components/common/icons/SaleUncheck.vue'
import CaretRightIcon from '../components/common/icons/CaretRight.vue'
import CategoryExhibitionMoreBtnIcon from '../components/common/icons/CategoryExhibitionMoreBtn.vue'
import CloseArrChips from '../components/common/icons/CloseArrChips.vue'
import CheckIcon from '../components/common/icons/Check.vue'
import OutlineCheck from '../components/common/icons/OutlineCheck.vue'
import BadgeExportIcon from '../components/common/icons/BadgeExport.vue'
import DibsCheckOffIcon from '../components/common/icons/DibsCheckOff.vue'
import DibsCheckOnIcon from '../components/common/icons/DibsCheckOn.vue'
import CancelIcon from '../components/common/icons/Cancel.vue'
import ShareIcon from '../components/common/icons/ShareIcon.vue'
import EmptyIcon from '../components/common/icons/Empty.vue'
import CaretLeftIcon from '../components/common/icons/CaretLeft.vue'
import CaretLeftIcon2 from '../components/common/icons/CaretLeft2.vue'
import CaretRightIcon2 from '../components/common/icons/CaretRight2.vue'
import MinusIcon from '../components/common/icons/Minus.vue'
import ChevronDownGray from '@/components/common/icons/ChevronDownGray.vue'
import Gray500NextVector from '@/components/common/icons/Gray500NextVector.vue'
import Gray500PrevVector from '@/components/common/icons/Gray500PrevVector.vue'
import MallNewBadge from '@/components/common/icons/MallNewBadge.vue'
import DownIcon from '../components/common/icons/DownIcon.vue'
import UserIcon from '../components/common/icons/User.vue'
import ChevronRightDefault from '@/components/common/icons/ChevronRightDefault.vue'
import Naver from '@/components/common/icons/Naver.vue'
import Apple from '@/components/common/icons/Apple.vue'
import Coupon from '@/components/common/icons/Coupon.vue'
import CheckIn from '@/components/common/icons/CheckIn.vue'
import ChevronLeftSlim from '@/components/common/icons/ChevronLeftSlim.vue'
import ChevronLeftSlimWhite from '@/components/common/icons/ChevronLeftSlimWhite.vue'
import subscribeBlackSolid from '@/components/common/icons/SubscribeBlackSolid.vue'
import subscribeBlack from '@/components/common/icons/SubscribeBlack.vue'
import Store from '@/components/common/icons/Store.vue'
import ChevronLeftDefault from '@/components/common/icons/ChevronLeftDefault.vue'
import ChevronLeftDefaultWhite from '@/components/common/icons/ChevronLeftDefaultWhite.vue'
import ArrowRight from '@/components/common/icons/ArrowRight.vue'
import BlackPoint from '@/components/common/icons/BlackPoint.vue'
import CheckInFloating from '@/components/common/icons/CheckInFloating.vue'
import ViewCount from '@/components/common/icons/ViewCount.vue'
import Live from '@/components/common/icons/Live.vue'
import Time from '@/components/common/icons/Time.vue'

export default {
  values: {
    store: {
      component: Store,
    },
    subscribeBlack: {
      component: subscribeBlack,
    },
    subscribeBlackSolid: {
      component: subscribeBlackSolid,
    },
    appleIcon: {
      component: Apple,
    },
    naverIcon: {
      component: Naver,
    },
    chevronDownGrayIcon: {
      component: ChevronDownGray,
    },
    chevronRightDefaultIcon: {
      component: ChevronRightDefault,
    },
    chevronLeftSlimWhite: {
      component: ChevronLeftSlimWhite,
    },
    chevronLeftSlim: {
      component: ChevronLeftSlim,
    },
    chevronLeftDefault: {
      component: ChevronLeftDefault,
    },
    chevronLeftDefaultWhite: {
      component: ChevronLeftDefaultWhite,
    },
    newIcon: {
      component: NewIcon,
    },
    mallNewIcon: {
      component: MallNewBadge,
    },
    likeIcon: {
      component: LikeIcon,
    },
    logoIcon: {
      component: LogoIcon,
    },
    nextIcon: {
      component: NextIcon,
    },
    kakaoChannelIcon: {
      component: KakaoChannel,
    },
    instagramIcon: {
      component: Instagram,
    },
    naverBlogIcon: {
      component: NaverBlog,
    },
    appDownloadIcon: {
      component: AppDownloadIcon,
    },
    prevIcon: {
      component: PrevIcon,
    },
    downTriangleIcon: {
      component: DownTriangleIcon,
    },
    closeIcon: {
      component: CloseIcon,
    },
    closeWhiteIcon: {
      component: CloseWhiteIcon,
    },
    nextHazyIcon: {
      component: NextHazyIcon,
    },
    symbolLogoB: {
      component: SymbolLogoB,
    },
    downBtn: {
      component: DownBtn,
    },
    refreshIcon: {
      component: RefreshIcon,
    },
    outlineNextIcon: {
      component: OutlineNextIcon,
    },
    homeIcon: {
      component: HomeIcon,
    },
    activeHomeIcon: {
      component: ActiveHomeIcon,
    },
    categoryIcon: {
      component: CategoryIcon,
    },
    activeCategoryIcon: {
      component: ActiveCategoryIcon,
    },
    dibsIcon: {
      component: DibsIcon,
    },
    activeDibsIcon: {
      component: ActiveDibsIcon,
    },
    myPageIcon: {
      component: MyPageIcon,
    },
    activeMyPageIcon: {
      component: ActiveMyPageIcon,
    },
    recentlyProductIcon: {
      component: RecentlyProductIcon,
    },
    activeRecentlyProductIcon: {
      component: ActiveRecentlyProductIcon,
    },
    btnMoreIcon: {
      component: BtnMoreIcon,
    },
    topVectorIcon: {
      component: TopVectorIcon,
    },
    topVectorIcon2: {
      component: TopVectorIcon2,
    },
    rightVectorIcon: {
      component: RightVectorIcon,
    },
    rightVectorBlackIcon: {
      component: RightVectorBlackIcon,
    },
    rightVetorGrayIcon: {
      component: RightVetorGrayIcon,
    },
    bookmarkIcon: {
      component: BookmarkIcon,
    },
    bookmarkActiveIcon: {
      component: BookmarkActiveIcon,
    },
    bookmarkRadiusIcon: {
      component: BookmarkRadiusIcon,
    },
    bookmarkRadiusActiveIcon: {
      component: BookmarkRadiusActiveIcon,
    },
    sharedKakaoIcon: {
      component: SharedKakaoIcon,
    },
    sharedUrlIcon: {
      component: SharedUrlIcon,
    },
    sharedMoreIcon: {
      component: SharedMoreIcon,
    },
    saleCheckIcon: {
      component: SaleCheckIcon,
    },
    registFilterIcon: {
      component: RegistFilterIcon,
    },
    refreshActiveIcon: {
      component: RefreshActiveIcon,
    },
    upTriangleIcon: {
      component: UpTriangleIcon,
    },
    outlinePhoneIcon: {
      component: OutlinePhoneIcon,
    },
    outlineUserIcon: {
      component: OutlineUserIcon,
    },
    whiteNextIcon: {
      component: WhiteNextIcon,
    },
    addIcon: {
      component: AddIcon,
    },
    bookmarkGrayIcon: {
      component: BookmarkGrayIcon,
    },
    bookmarkWhiteIcon: {
      component: BookmarkWhiteIcon,
    },
    bookmarkWhiteActiveIcon: {
      component: BookmarkWhiteActiveIcon,
    },
    pinIcon: {
      component: PinIcon,
    },
    rightNextIcon: {
      component: RightNextIcon,
    },
    moreIcon: {
      component: MoreIcon,
    },
    newSmallIcon: {
      component: NewSmallIcon,
    },
    signInCompleteBangIcon: {
      component: SignInCompleteIcon,
    },
    saleUncheckIcon: {
      component: SaleUncheckIcon,
    },
    caretRightIcon: {
      component: CaretRightIcon,
    },
    categoryExhibitionMoreBtnIcon: {
      component: CategoryExhibitionMoreBtnIcon,
    },
    closeDetailDialog: {
      component: CloseDetailDialog,
    },
    closeArrChips: {
      component: CloseArrChips,
    },
    outlineCheckIcon: {
      component: OutlineCheck,
    },
    badgeExportIcon: {
      component: BadgeExportIcon,
    },
    dibsCheckOnIcon: {
      component: DibsCheckOnIcon,
    },
    dibsCheckOffIcon: {
      component: DibsCheckOffIcon,
    },
    checkIcon: {
      component: CheckIcon,
    },
    cancelIcon: {
      component: CancelIcon,
    },
    shareIcon: {
      component: ShareIcon,
    },
    emptyIcon: {
      component: EmptyIcon,
    },
    caretLeftIcon: {
      component: CaretLeftIcon,
    },
    caretLeftIcon2: {
      component: CaretLeftIcon2,
    },
    caretRightIcon2: {
      component: CaretRightIcon2,
    },
    minusIcon: {
      component: MinusIcon,
    },
    gray500NextVector: {
      component: Gray500NextVector,
    },
    gray500PrevVector: {
      component: Gray500PrevVector,
    },
    downIcon: {
      component: DownIcon,
    },
    userIcon: {
      component: UserIcon,
    },
    couponIcon: {
      component: Coupon,
    },
    checkIn: {
      component: CheckIn,
    },
    arrowRightIcon: {
      component: ArrowRight,
    },
    blackPointIcon: {
      component: BlackPoint,
    },
    checkInFloating: {
      component: CheckInFloating,
    },
    viewCount: {
      component: ViewCount,
    },
    live: {
      component: Live,
    },
    time: {
      component: Time,
    },
  },
}
