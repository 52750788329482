<template>
  <svg
    width="140"
    height="32"
    viewBox="0 0 140 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_13179_20564)">
      <path d="M57 0H53V32H57V0Z" fill="black" />
      <path d="M65 0H61V32H65V0Z" fill="black" />
      <path d="M124 0H120V32H124V0Z" fill="black" />
      <path
        d="M80 12C81.5823 12 83.129 12.4692 84.4446 13.3482C85.7602 14.2273 86.7855 15.4767 87.391 16.9385C87.9965 18.4003 88.155 20.0089 87.8463 21.5607C87.5376 23.1126 86.7757 24.538 85.6569 25.6569C84.538 26.7757 83.1126 27.5376 81.5607 27.8463C80.0089 28.155 78.4004 27.9965 76.9385 27.391C75.4767 26.7855 74.2273 25.7601 73.3482 24.4445C72.4692 23.129 72 21.5823 72 20C72 17.8783 72.8429 15.8434 74.3431 14.3431C75.8434 12.8428 77.8783 12 80 12ZM80 8C77.6266 8 75.3066 8.70379 73.3332 10.0224C71.3598 11.3409 69.8217 13.2151 68.9135 15.4078C68.0052 17.6005 67.7676 20.0133 68.2306 22.3411C68.6936 24.6689 69.8365 26.8071 71.5147 28.4853C73.193 30.1635 75.3312 31.3064 77.6589 31.7694C79.9867 32.2324 82.3995 31.9948 84.5922 31.0865C86.7849 30.1783 88.6591 28.6402 89.9776 26.6668C91.2962 24.6934 92 22.3734 92 20C92 16.8174 90.7357 13.7651 88.4853 11.5147C86.2349 9.26427 83.1826 8 80 8Z"
        fill="black"
      />
      <path
        d="M106 12C107.582 12 109.129 12.4692 110.445 13.3482C111.76 14.2273 112.786 15.4767 113.391 16.9385C113.997 18.4003 114.155 20.0089 113.846 21.5607C113.538 23.1126 112.776 24.538 111.657 25.6569C110.538 26.7757 109.113 27.5376 107.561 27.8463C106.009 28.155 104.4 27.9965 102.939 27.391C101.477 26.7855 100.227 25.7601 99.3482 24.4445C98.4692 23.129 98 21.5823 98 20C98 17.8783 98.8428 15.8434 100.343 14.3431C101.843 12.8428 103.878 12 106 12ZM106 8C103.627 8 101.307 8.70379 99.3332 10.0224C97.3598 11.3409 95.8217 13.2151 94.9134 15.4078C94.0052 17.6005 93.7676 20.0133 94.2306 22.3411C94.6936 24.6689 95.8365 26.8071 97.5147 28.4853C99.1929 30.1635 101.331 31.3064 103.659 31.7694C105.987 32.2324 108.399 31.9948 110.592 31.0865C112.785 30.1783 114.659 28.6402 115.978 26.6668C117.296 24.6934 118 22.3734 118 20C118 16.8174 116.736 13.7651 114.485 11.5147C112.235 9.26427 109.183 8 106 8Z"
        fill="black"
      />
      <path
        d="M18.92 24C18.0395 25.5252 16.6803 26.7171 15.0533 27.391C13.4263 28.065 11.6223 28.1832 9.92128 27.7274C8.22021 27.2716 6.71708 26.2673 5.645 24.8701C4.57293 23.473 3.99182 21.7611 3.99182 20C3.99182 18.2389 4.57293 16.5271 5.645 15.1299C6.71708 13.7328 8.22021 12.7284 9.92128 12.2726C11.6223 11.8168 13.4263 11.9351 15.0533 12.609C16.6803 13.2829 18.0395 14.4749 18.92 16H23.3C22.3565 13.3312 20.4998 11.0818 18.0581 9.6495C15.6165 8.21719 12.7471 7.69416 9.95709 8.17284C7.16711 8.65153 4.63618 10.1011 2.81161 12.2654C0.987047 14.4296 -0.0136719 17.1693 -0.0136719 20C-0.0136719 22.8308 0.987047 25.5704 2.81161 27.7347C4.63618 29.8989 7.16711 31.3485 9.95709 31.8272C12.7471 32.3059 15.6165 31.7829 18.0581 30.3505C20.4998 28.9182 22.3565 26.6689 23.3 24H18.92Z"
        fill="black"
      />
      <path
        d="M44.24 25.0001C43.0581 26.4732 41.3932 27.4807 39.5398 27.8442C37.6865 28.2078 35.7643 27.9039 34.1135 26.9863C32.4627 26.0688 31.1898 24.5968 30.52 22.8309C29.8502 21.065 29.8268 19.1191 30.4539 17.3376C31.081 15.5561 32.3181 14.0539 33.9464 13.0969C35.5746 12.1399 37.4889 11.7898 39.3505 12.1086C41.212 12.4274 42.9007 13.3946 44.1177 14.8389C45.3347 16.2831 46.0015 18.1114 46 20.0001C45.9956 20.6749 45.9083 21.3466 45.74 22.0001H49.82C50.3247 18.9957 49.6679 15.9117 47.9826 13.3738C46.2973 10.8359 43.7098 9.03396 40.7449 8.33349C37.7801 7.63302 34.6597 8.08646 32.0169 9.60184C29.374 11.1172 27.4064 13.5811 26.5132 16.4937C25.62 19.4063 25.868 22.5496 27.207 25.2861C28.546 28.0226 30.8757 30.1474 33.7235 31.2295C36.5714 32.3116 39.7242 32.27 42.5425 31.1132C45.3608 29.9564 47.6337 27.7709 48.9 25.0001H44.24Z"
        fill="black"
      />
      <path d="M49 19H27V22H49V19Z" fill="black" />
      <path d="M135 8L124 20.37V26L140 8H135Z" fill="black" />
      <path d="M135 32L127.51 22.05L130.22 19L140 32H135Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_13179_20564">
        <rect width="140" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({})
</script>
