import Vue from 'vue'
import { setSocialAccessToken, clearSocialAccessToken } from '@/plugins/axios'
import { PostSigninAndUp } from '@/api/accountAPI/SocialAPI'
import { closeSubWebView } from './util/appInterface'

export class Naver {
  naverLogin: any
  init() {
    this.naverLogin = new window.naver.LoginWithNaverId({
      clientId: process.env.VUE_APP_CLIENT_ID,
      callbackUrl: `${process.env.VUE_APP_CELLOOK_URL}/loginLandingPage`,
      isPopup: false,
      loginButton: { color: 'green', type: 3, height: 62 },
    })

    this.naverLogin.init()
  }
  init2() {
    this.naverLogin = new window.naver.LoginWithNaverId({
      clientId: process.env.VUE_APP_CLIENT_ID,
      callbackUrl: `${process.env.VUE_APP_CELLOOK_URL}/loginLandingPage`,
      isPopup: false,
      callbackHandle: true,
    })
    this.naverLogin.init()
  }
  click() {
    this.init()
    const loginBtnEl = document.getElementById('naverIdLogin') as HTMLDivElement
    const loginBtn = loginBtnEl.firstChild as HTMLDivElement
    loginBtn && loginBtn.click()
  }
  signIn() {
    this.init2()
    this.naverLogin.getLoginStatus((status: boolean) => {
      if (!status) return
      const { email, gender, birthyear, mobile } = this.naverLogin.user
      if (!email || !gender || !birthyear || !mobile) {
        if (confirm('필수 제공 항목에 모두 체크를 하셔야 가입이 완료됩니다.')) {
          this.naverLogin.reprompt()
          return
        }
      }

      const currentYear = new Date().getFullYear()
      if (birthyear >= currentYear - 15) {
        alert(
          '14세 미만 회원은 보호자의 동의 후 가입이 가능합니다. 자세한 문의는 고객센터로 연락주세요.'
        )
        return
      }

      //네이버 access token axios headers에 setting
      setSocialAccessToken(this.naverLogin.accessToken.accessToken)

      PostSigninAndUp({ userType: 1003 })
        .then((result) => {
          localStorage.setItem('socialLoginPlattform', 'naver')
          localStorage.setItem('accessToken', result.data.accessToken) //3개월
          localStorage.setItem('refreshToken', result.data.refreshToken) //6개월
          if (result.data.isRegister) {
            localStorage.setItem('isRegister', 'true')
          }
        })
        .catch(() => {
          alert(
            '잠시 문제가 있어 페이지를 표시하지 못했습니다.조금 뒤에 다시 시도해보세요.'
          )
        })
        .finally(() => {
          window.close()
          closeSubWebView()
          //소셜토큰 삭제
          clearSocialAccessToken()
        })
    })
  }
}

export const naver = new Naver()
Vue.prototype.$naver = naver
